import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { contentUrlWithId } from '../../../utils/urls';
import { DELETE_CONTENT_REQUEST } from '../content-constants';
import {
  deleteContentSuccess,
  deleteContentFailure
} from './delete-content-action';

const { deleteRequest } = new HttpHelper();

export function* deleteContent({ data }) {
  try {
    const response = yield call(deleteRequest, {
      url: contentUrlWithId(data.id)
    });

    if (response.error) {
      yield put(deleteContentFailure());
    } else {
      data.deleteSuccess();
      yield put(deleteContentSuccess());
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchDeleteContent() {
  yield takeLatest(DELETE_CONTENT_REQUEST, deleteContent);
}
