import * as Toastr from 'toastr';

import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { contentUrlWithId } from '../../../utils/urls';
import { UPDATE_CONTENT_REQUEST } from '../content-constants';
import {
  updateContentSuccess,
  updateContentFailure
} from './update-content-action';

const { patchRequest } = new HttpHelper();

export function* updateContent({ data }) {
  try {
    const response = yield call(patchRequest, {
      url: contentUrlWithId(data.id),
      data: data.formData
    });

    if (response.error) {
      yield put(updateContentFailure());
    } else if (response.data && response.data.status) {
      yield put(updateContentSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.successCallback();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchUpdateContent() {
  yield takeLatest(UPDATE_CONTENT_REQUEST, updateContent);
}
