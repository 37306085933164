import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../../components/layout';
import DirectorVideoForm from '../director-video-form';
import { addDirectorVideo } from './create-director-video-action';

const CreateDirectorVideo = ({ history, match }) => {
  const [addDirectorVideoDetails, setAddDirectorVideoDetails] = useState({
    thumbnailImage: null,
    thumbnailVideo: null,
    video: '',
    filmName: '',
    brandName: '',
    videoType: '',
    agency: '',
    awards: []
  });
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.addDirectorVideo);

  const directorVideoSuccessCallback = () => {
    history.push(`/director/${match.params.id}/list`);
  };

  const handleCreateDirectorVideo = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const {
      thumbnailImage,
      thumbnailVideo,
      video,
      filmName,
      brandName,
      videoType,
      agency,
      awards
    } = addDirectorVideoDetails;

    if (thumbnailImage && thumbnailVideo && video && filmName && videoType) {
      let directorVideoFormData = new FormData();
      directorVideoFormData.append('thumbnail_image', thumbnailImage);
      directorVideoFormData.append('thumbnail_video', thumbnailVideo);
      directorVideoFormData.append('video', video);
      directorVideoFormData.append('video_type', videoType);
      directorVideoFormData.append('film_name', filmName);
      directorVideoFormData.append('brand_name', brandName);
      directorVideoFormData.append('director_id', match.params.id);
      directorVideoFormData.append('agency', agency);
      directorVideoFormData.append('awards', awards);

      addDirectorVideo(
        {
          formData: directorVideoFormData,
          directorVideoSuccessCallback
        },
        dispatch
      );
    }
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <DirectorVideoForm
            directorVideoDetails={addDirectorVideoDetails}
            setDirectorVideoDetails={setAddDirectorVideoDetails}
            validated={validated}
            handleCancel={() =>
              history.push(`/director/${match.params.id}/list`)
            }
            handleSubmitDirectorVideo={handleCreateDirectorVideo}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default CreateDirectorVideo;
