import {
  ADD_PORTFOLIO_REQUEST,
  ADD_PORTFOLIO_FAILURE,
  ADD_PORTFOLIO_SUCCESS
} from '../portfolio-constants';

const addPortfolioRequest = (data) => ({
  data,
  type: ADD_PORTFOLIO_REQUEST
});

export const addPortfolioSuccess = () => ({
  type: ADD_PORTFOLIO_SUCCESS
});

export const addPortfolioFailure = () => ({
  type: ADD_PORTFOLIO_FAILURE
});

export const addPortfolio = async (data, dispatch) => {
  dispatch(addPortfolioRequest(data));
};
