import React, { Fragment } from 'react';
import { Form, Button } from 'react-bootstrap';

import TextInput from '../../components/form-fields/text-input';
import FileInput from '../../components/form-fields/file-input';
import ButtonLoader from '../../assets/images/button-loader.svg';
import arrow from '../../assets/images/arrow.png';

const DirectorForm = ({
  directorDetails,
  setDirectorDetails,
  validated,
  handleCancel,
  handleSubmitDirector,
  isLoading,
  noData,
  isEditDirectorLoading
}) => {
  const formFileds = [
    {
      type: 'text',
      label: 'Name',
      placeholder: 'Enter director name',
      value: directorDetails.name,
      changeText: (e) =>
        setDirectorDetails({
          ...directorDetails,
          name: e.target.value
        }),
      feedback: 'Please enter director name.'
    },
    {
      type: 'textarea',
      label: 'Bio',
      placeholder: 'Enter bio',
      value: directorDetails.bio,
      changeText: (e) =>
        setDirectorDetails({
          ...directorDetails,
          bio: e.target.value
        }),
      feedback: 'Please enter bio.'
    },
    {
      type: 'file',
      label: 'Upload Thumbnail Image',
      heading: 'Thumbnail Image',
      value: directorDetails.profilePic,
      onClickFile: () =>
        setDirectorDetails({
          ...directorDetails,
          profilePic: null
        }),
      onChangeFile: (e) =>
        setDirectorDetails({
          ...directorDetails,
          profilePic: e.target.files[0]
        }),
      feedback: 'Please upload thumbnail image.',
      validated: validated
    },
    {
      type: 'fileVideo',
      label: 'Upload Reel Video',
      heading: 'Reel Video',
      value: directorDetails.reelVideo,
      onClickFile: () =>
        setDirectorDetails({
          ...directorDetails,
          reelVideo: null
        }),
      onChangeFile: (e) =>
        setDirectorDetails({
          ...directorDetails,
          reelVideo: e.target.files[0]
        }),
      feedback: 'Please upload reel video.',
      validated: validated
    }
  ];

  const renderFormFields = (input) => {
    switch (input.type) {
      case 'file':
        return (
          <FileInput
            label={input.label}
            heading={input.heading}
            value={input.value}
            onClickFile={input.onClickFile}
            onChangeFile={input.onChangeFile}
            feedback={input.feedback}
            validated={input.validated}
            type={input.type}
          />
        );
      case 'fileVideo':
        return (
          <FileInput
            label={input.label}
            heading={input.heading}
            value={input.value}
            onClickFile={input.onClickFile}
            onChangeFile={input.onChangeFile}
            feedback={input.feedback}
            validated={input.validated}
            type={input.type}
          />
        );
      case 'text':
        return (
          <TextInput
            label={input.label}
            placeholder={input.placeholder}
            value={input.value}
            changeText={input.changeText}
            feedback={input.feedback}
          />
        );
      case 'textarea':
        return (
          <TextInput
            multiline={true}
            label={input.label}
            placeholder={input.placeholder}
            value={input.value}
            changeText={input.changeText}
            feedback={input.feedback}
          />
        );
      default:
        return '';
    }
  };

  return !isEditDirectorLoading ? (
    <div className="form-container">
      <div className="back-arrow" onClick={handleCancel}>
        <img src={arrow} alt="arrow" />
      </div>
      <div className="head">
        <h4>Director Form</h4>
        <p>Enter your details below</p>
      </div>
      {!noData ? (
        <>
          <Form noValidate validated={validated}>
            {formFileds.map((input, index) => (
              <Fragment key={index}>{renderFormFields(input)}</Fragment>
            ))}

            <div className="bottom-btn">
              <Button className="black-btn white width" onClick={handleCancel}>
                Cancel
              </Button>

              <Button
                className="black-btn width"
                onClick={handleSubmitDirector}
              >
                {!isLoading ? (
                  'Submit'
                ) : (
                  <>
                    <img src={ButtonLoader} alt="button-loader" />
                    <span className="laoder-span">Loading....</span>
                  </>
                )}
              </Button>
            </div>
          </Form>
        </>
      ) : (
        <Form>No data found</Form>
      )}
    </div>
  ) : (
    <p style={{ color: '#000' }}>Loading...</p>
  );
};

export default DirectorForm;
