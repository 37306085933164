import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'react-bootstrap';

import { getPortfolio } from './portfolio-action';
import { deletePortfolio } from '../delete/delete-portfolio-action';

import Layout from '../../../../components/layout';
import VideoPlayer from '../../../../components/video-player';
import PaginationList from '../../../../components/pagination';
import DeletePopup from '../../../../components/modal/delete-popup';

import plus from '../../../../assets/images/plus.png';

const Portfolio = ({ history }) => {
  const [portfolioId, setPortfolioId] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();

  const getPortfolioDetails = (currentPageNo, pageSize) => {
    getPortfolio({ pageNumber: currentPageNo, pageSize: pageSize }, dispatch);
  };

  useEffect(() => {
    getPortfolioDetails(currentPageNo, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { portfolioList, isLoading, totalRecords } = useSelector(
    (state) => state.getPortfolio
  );

  const deletePortfolioSuccess = () => {
    setShowDeletePopup(false);
    getPortfolioDetails(currentPageNo, pageSize);
  };

  const handelDelete = () => {
    deletePortfolio(
      { id: portfolioId, deleteSuccess: deletePortfolioSuccess },
      dispatch
    );
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          {!isLoading ? (
            <div className="video-list-container">
              <div className="video-list-head">
                <div>
                  <h2>Portfolio List</h2>
                </div>
                <div>
                  <Button
                    className="black-btn halfwidth"
                    onClick={() => history.push('/portfolio/sort')}
                  >
                    Reorder
                  </Button>
                  <Button
                    className="black-btn halfwidth m-l-20"
                    onClick={() => history.push('/portfolio/create')}
                  >
                    Add New
                    <span>
                      <img src={plus} alt="plus" />
                    </span>
                  </Button>
                </div>
              </div>
              {portfolioList ? (
                portfolioList.map((portfolio) => (
                  <div className="video-card customborder" key={portfolio.id}>
                    <div className="video-card-btn">
                      <div className="btn-section">
                        <Button
                          className="black-btn transparent"
                          onClick={() =>
                            history.push(`/portfolio/${portfolio.id}/edit`)
                          }
                        >
                          Edit
                        </Button>
                        <span>|</span>
                        <Button
                          className="black-btn transparent red"
                          onClick={() => {
                            setShowDeletePopup(true);
                            setPortfolioId(portfolio.id);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                    <div className="video-card-section">
                      <div className="video-list-img">
                        <label>Thumbnail Image</label>
                        <img src={portfolio.thumbnail_image} alt="img" />
                      </div>
                      <div className="video">
                        <label>Thumbnail Video</label>
                        <VideoPlayer url={portfolio.thumbnail_video} />
                      </div>
                      <div className="video">
                        <label>Full Video</label>
                        <VideoPlayer url={portfolio.video} />
                      </div>
                    </div>
                    <div className="film">
                      <div className="video-card-text">
                        <div className="video-card-text-list">
                          <h2>Name of Film</h2>
                          <h3>{portfolio.film_name}</h3>
                        </div>
                        <div className="video-card-text-list">
                          <h2>Category Name</h2>
                          <h3>{portfolio.category}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="content">
                      <div className="video-card-text">
                        {portfolio.category === 'Ad Film' && (
                          <div className="video-card-text-list">
                            <h2>Brand Name</h2>
                            <h3>{portfolio.brand_name}</h3>
                          </div>
                        )}
                        {portfolio.category === 'Content Film' && (
                          <>
                            <div className="video-card-text-list">
                              <h2>Director Name</h2>
                              <h3>{portfolio.director_name}</h3>
                            </div>
                            <div className="video-card-text-list">
                              <h2>Type of Content</h2>
                              <h3>{portfolio.type_of_content}</h3>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-state">
                  <p>Please add information</p>
                </div>
              )}
            </div>
          ) : (
            <p style={{ color: '#000' }}>Loading...</p>
          )}
        </div>
      </div>
      <PaginationList
        currentPageNo={currentPageNo}
        setCurrentPageNo={setCurrentPageNo}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalRecords={totalRecords}
        getCurentPageData={getPortfolioDetails}
      />
      <DeletePopup
        show={showDeletePopup}
        handleClose={() => setShowDeletePopup(false)}
        handelDelete={handelDelete}
      />
    </Layout>
  );
};

export default Portfolio;
