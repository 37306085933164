import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { showReelUrl } from '../../../../utils/urls';
import { GET_SHOW_REEL_REQUEST } from '../showreel-constants';
import { getShowReelSuccess, getShowReelFailure } from './showreel-action';

const { getRequest } = new HttpHelper();

export function* getShowReel({ data }) {
  try {
    const response = yield call(getRequest, {
      url: showReelUrl(data)
    });

    if (response.error) {
      yield put(getShowReelFailure());
    } else {
      yield put(getShowReelSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetShowReel() {
  yield takeLatest(GET_SHOW_REEL_REQUEST, getShowReel);
}
