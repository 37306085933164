import * as Toastr from 'toastr';

import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { contentVideoUrlByVideoId } from '../../../utils/urls';
import { UPDATE_CONTENT_VIDEO_REQUEST } from '../content-constants';
import {
  updateContentVideoSuccess,
  updateContentVideoFailure
} from './update-content-video-action';

const { patchRequest } = new HttpHelper();

export function* updateContentVideo({ data }) {
  try {
    const response = yield call(patchRequest, {
      url: contentVideoUrlByVideoId(data.id),
      data: data.formData
    });

    if (response.error) {
      yield put(updateContentVideoFailure());
    } else if (response.data && response.data.status) {
      yield put(updateContentVideoSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.updateSuccess();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchUpdateContentVideo() {
  yield takeLatest(UPDATE_CONTENT_VIDEO_REQUEST, updateContentVideo);
}
