import React from 'react';

import CreatableSelect from 'react-select/creatable';

const CreatableSelector = ({
  selectedOption,
  setSelectedOption,
  label,
  validated,
  options
}) => {
  let optionArr =
    options &&
    options.map((el) => {
      return { label: el, value: el };
    });

  const handleChange = (newValue) => {
    if (newValue) {
      setSelectedOption({
        ...selectedOption,
        [label]: newValue.value
      });
    } else {
      setSelectedOption({
        ...selectedOption,
        [label]: ''
      });
    }
  };
  const handleInputChange = (inputValue) => {
    if (inputValue) {
      setSelectedOption({
        ...selectedOption,
        [label]: inputValue.value
      });
    }
  };

  return (
    <>
      <CreatableSelect
        isClearable
        defaultValue={
          selectedOption[label] && {
            label: selectedOption[label],
            value: selectedOption[label]
          }
        }
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={optionArr}
        className={`${
          validated && selectedOption[label] === '' && 'custom-class'
        }`}
      />
      {validated && selectedOption[label] === '' && (
        <span className="required-file-feedback">Please select/create</span>
      )}
    </>
  );
};

export default CreatableSelector;
