import {
  GET_SHOW_REEL_BY_ID_REQUEST,
  GET_SHOW_REEL_BY_ID_FAILURE,
  GET_SHOW_REEL_BY_ID_SUCCESS
} from '../showreel-constants';

const initialState = {
  isEditShowReelLoading: false,
  showReelData: {}
};

const getShowReelById = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHOW_REEL_BY_ID_REQUEST:
      return {
        ...state,
        isEditShowReelLoading: true,
        showReelData: {}
      };
    case GET_SHOW_REEL_BY_ID_FAILURE:
      return {
        ...state,
        isEditShowReelLoading: false,
        showReelData: {}
      };
    case GET_SHOW_REEL_BY_ID_SUCCESS:
      return {
        ...state,
        isEditShowReelLoading: false,
        showReelData: action.data
      };
    default:
      return state;
  }
};

export default getShowReelById;
