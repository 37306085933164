import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'react-bootstrap';

import { Player } from 'video-react';

import { getShowReel } from './showreel-action';
import { deleteShowReel } from '../delete/delete-showreel-action';

import Layout from '../../../../components/layout';
// import PaginationList from '../../../../components/pagination';
import DeletePopup from '../../../../components/modal/delete-popup';
// import AlertPopup from '../../../../components/modal/alert-popup';

import plus from '../../../../assets/images/plus.png';

const ShowreelList = ({ history }) => {
  const [showReelsId, setShowReelsId] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  // const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [play] = useState(false);
  const [currentPageNo] = useState(1);
  const [pageSize] = useState(10);

  const dispatch = useDispatch();

  const getShowReelDetails = (currentPageNo, pageSize) => {
    getShowReel({ pageNumber: currentPageNo, pageSize: pageSize }, dispatch);
  };

  useEffect(() => {
    getShowReelDetails(currentPageNo, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { showReelList, isLoading } = useSelector(
    (state) => state.getShowReel
  );

  const deleteShowReelSuccess = () => {
    setShowDeletePopup(false);
    getShowReelDetails(currentPageNo, pageSize);
  };

  const addNewShowreel = () => {
    history.push('/showreel/create');
  }

  const handelDelete = () => {
    deleteShowReel(
      { id: showReelsId, deleteSuccess: deleteShowReelSuccess },
      dispatch
    );
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          {!isLoading ? (
            <div className="video-list-container">
              <div className="video-list-head">
                <div>
                  <h2>ShowReel List</h2>
                </div>
                { !showReelList || showReelList.length === 0 ? (
                  <div>
                    <Button
                      className="black-btn halfwidth"
                      onClick={() => { addNewShowreel(); }}
                    >
                      Add New
                      <span>
                        <img src={plus} alt="plus" />
                      </span>
                    </Button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {showReelList && showReelList.length > 0 ? (
                showReelList.map((reelDetails) => (
                  <div className="video-card customborder" key={reelDetails.id}>
                    <div className="video-card-btn">
                      <div className="btn-section">
                        <Button
                          className="black-btn transparent"
                          onClick={() =>
                            history.push(`/showreel/${reelDetails.id}/edit`)
                          }
                        >
                          Edit
                        </Button>
                        <span>|</span>
                        <Button
                          className="black-btn transparent red"
                          onClick={() => {
                            setShowDeletePopup(true);
                            setShowReelsId(reelDetails.id);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                    <div className="video-card-section">
                      <div className="video-list-img">
                        <label>Thumbnail Image</label>
                        <img src={reelDetails.thumbnail_image} alt="img" />
                      </div>
                      <div className="video">
                        <label>Thumbnail Video</label>
                        <Player>
                          <source src={reelDetails.video} />
                          <div className={`play ${play && 'hide'}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              viewBox="0 0 42.7 42.7"
                            >
                              <path
                                className="a"
                                d="M21.741-50A21.369,21.369,0,0,0,.391-28.65,21.369,21.369,0,0,0,21.741-7.3a21.369,21.369,0,0,0,21.35-21.35A21.369,21.369,0,0,0,21.741-50Zm6.877,22.429-10.068,6.7a1.3,1.3,0,0,1-2.023-1.079V-35.347a1.3,1.3,0,0,1,2.023-1.079l10.068,6.7A1.3,1.3,0,0,1,28.618-27.571Z"
                                transform="translate(-0.391 50)"
                              ></path>
                            </svg>
                          </div>
                        </Player>
                      </div>
                      <div className="video"></div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-state">
                  <p>Please add information</p>
                </div>
              )}
            </div>
          ) : (
            <p style={{ color: '#000' }}>Loading...</p>
          )}
        </div>
      </div>
      {/* <PaginationList
        currentPageNo={currentPageNo}
        setCurrentPageNo={setCurrentPageNo}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalRecords={totalRecords}
        getCurentPageData={getShowReelDetails}
      /> */}
      <DeletePopup
        show={showDeletePopup}
        handleClose={() => setShowDeletePopup(false)}
        handelDelete={handelDelete}
      />
      {/* <AlertPopup
        show={showAlertPopup}
        message="Only 1 showreel is allowed. To change, please delete the existing showreel and create new"
        handleClose={() => setShowAlertPopup(false)}
      /> */}
    </Layout>
  );
};

export default ShowreelList;
