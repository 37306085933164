import {
  GET_FILM_NAMES_REQUEST,
  GET_FILM_NAMES_FAILURE,
  GET_FILM_NAMES_SUCCESS
} from './admin-helper-constants';

const getFilmNamesRequest = () => ({
  type: GET_FILM_NAMES_REQUEST
});

export const getFilmNamesSuccess = (data) => ({
  data,
  type: GET_FILM_NAMES_SUCCESS
});

export const getFilmNamesFailure = () => ({
  type: GET_FILM_NAMES_FAILURE
});

export const getFilmNames = async (dispatch) => {
  dispatch(getFilmNamesRequest());
};
