import React, { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { Form, Button } from 'react-bootstrap';

import TextInput from '../../components/form-fields/text-input';
import FileInput from '../../components/form-fields/file-input';
import CreatableSelectInput from '../../components/form-fields/creatable-select-input';
import ButtonLoader from '../../assets/images/button-loader.svg';
import arrow from '../../assets/images/arrow.png';
import TagInput from '../../components/form-fields/tag-input';

const DirectorVideoForm = ({
  directorVideoDetails,
  setDirectorVideoDetails,
  validated,
  handleCancel,
  handleSubmitDirectorVideo,
  isLoading,
  noData,
  isEditDirectorVideoLoading
}) => {
  const videoType = ['small', 'large'];
  const dispatch = useDispatch();

  useEffect(() => {
  }, [dispatch]);

  const handleKeyDown = (e) => {
    // if (e.keyCode === 13) {
    //   e.preventDefault();
    //   handleSubmitDirectorVideo(e);
    // }
  };

  const formFileds = [
    {
      type: 'file',
      label: 'Upload Thumbnail Image',
      heading: 'Thumbnail Image',
      value: directorVideoDetails.thumbnailImage,
      onClickFile: () =>
        setDirectorVideoDetails({
          ...directorVideoDetails,
          thumbnailImage: null
        }),
      onChangeFile: (e) =>
        setDirectorVideoDetails({
          ...directorVideoDetails,
          thumbnailImage: e.target.files[0]
        }),
      feedback: 'Please upload thumbnail image.',
      validated: validated
    },
    {
      type: 'fileVideo',
      label: 'Upload Thumbnail Video',
      heading: 'Thumbnail Video',
      value: directorVideoDetails.thumbnailVideo,
      onClickFile: () =>
        setDirectorVideoDetails({
          ...directorVideoDetails,
          thumbnailVideo: null
        }),
      onChangeFile: (e) =>
        setDirectorVideoDetails({
          ...directorVideoDetails,
          thumbnailVideo: e.target.files[0]
        }),
      feedback: 'Please upload thumbnail video.',
      validated: validated
    },
    {
      type: 'text',
      label: 'Video URL',
      placeholder: 'Enter video url',
      value: directorVideoDetails.video,
      changeText: (e) =>
        setDirectorVideoDetails({
          ...directorVideoDetails,
          video: e.target.value
        }),
      feedback: 'Please enter video url.'
    },
    {
      type: 'text',
      label: 'Film Name',
      placeholder: 'Enter film name',
      value: directorVideoDetails.filmName,
      changeText: (e) =>
        setDirectorVideoDetails({
          ...directorVideoDetails,
          filmName: e.target.value
        }),
      feedback: 'Please enter film name.'
    },
    {
      type: 'text',
      label: 'Brand Name',
      placeholder: 'Enter brand name',
      value: directorVideoDetails.brandName,
      changeText: (e) =>
        setDirectorVideoDetails({
          ...directorVideoDetails,
          brandName: e.target.value
        }),
      feedback: 'Please enter brand name.',
      required: false
    },
    {
      type: 'creatable-select',
      heading: 'Video Type',
      options: videoType,
      value: directorVideoDetails.videoType ? directorVideoDetails.videoType : videoType[0],
      selectedOption: directorVideoDetails,
      setSelectedOption: setDirectorVideoDetails,
      label: 'videoType',
      validated: validated
    },
    {
      type: 'text',
      label: 'Agency',
      placeholder: 'Agency name',
      value: directorVideoDetails.agency,
      changeText: (e) =>
        setDirectorVideoDetails({
          ...directorVideoDetails,
          agency: e.target.value
        }),
      feedback: 'Please enter agency name.',
      required: false
    },
    {
      type: 'tagInput',
      label: 'Awards',
      placeholder: 'Type and press enter',
      tags: directorVideoDetails.awards || [],
      changeTags: (value) =>
        setDirectorVideoDetails({
          ...directorVideoDetails,
          awards: value
        }),
      feedback: 'Please enter the awards.'
    }
  ];

  const renderFormFields = (input) => {
    switch (input.type) {
      case 'file':
        case 'fileVideo':
        return (
          <FileInput
            label={input.label}
            heading={input.heading}
            value={input.value}
            onClickFile={input.onClickFile}
            onChangeFile={input.onChangeFile}
            feedback={input.feedback}
            validated={input.validated}
            type={input.type}
          />
        );
      case 'text':
        return (
          <TextInput
            label={input.label}
            placeholder={input.placeholder}
            value={input.value}
            changeText={input.changeText}
            feedback={input.feedback}
            required={input.required}
          />
        );
      case 'tagInput':
        return (
          <TagInput
            label={input.label}
            placeholder={input.placeholder}
            tags={input.tags}
            changeTags={input.changeTags}
            feedback={input.feedback}
          />
        );
      case 'creatable-select':
        return (
          <CreatableSelectInput
            heading={input.heading}
            options={input.options}
            selectedOption={input.selectedOption}
            setSelectedOption={input.setSelectedOption}
            label={input.label}
            validated={input.validated}
          />
        );
      default:
        return '';
    }
  };

  return !isEditDirectorVideoLoading ? (
    <div className="common-container">
      <div className="form-container">
        <div className="back-arrow" onClick={handleCancel}>
          <img src={arrow} alt="arrow" />
        </div>
        <div className="head">
          <h4>Director Video Form</h4>
          <p>Enter your details below</p>
        </div>
        {!noData ? (
          <>
            <Form onKeyDown={handleKeyDown} noValidate validated={validated}>
              {formFileds.map((input, index) => (
                <Fragment key={index}>{renderFormFields(input)}</Fragment>
              ))}

              <div className="bottom-btn">
                <Button
                  className="black-btn white width"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="black-btn width"
                  onClick={handleSubmitDirectorVideo}
                >
                  {!isLoading ? (
                    'Submit'
                  ) : (
                    <>
                      <img src={ButtonLoader} alt="button-loader" />
                      <span className="laoder-span">Loading....</span>
                    </>
                  )}
                </Button>
              </div>
            </Form>
          </>
        ) : (
          <Form>No data found</Form>
        )}
      </div>
    </div>
  ) : (
    <p style={{ color: '#000' }}>Loading...</p>
  );
};

export default DirectorVideoForm;
