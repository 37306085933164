import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'react-bootstrap';

import { getContentVideo } from '../list/content-video-action';
import { getContentById } from '../list/get-content-action';
import { deleteContentVideo } from '../delete/delete-content-video-action';

import Layout from '../../../components/layout';
import VideoPlayer from '../../../components/video-player';
import PaginationList from '../../../components/pagination';
import DeletePopup from '../../../components/modal/delete-popup';

import plus from '../../../assets/images/plus.png';

const ContentList = ({ history, match }) => {
  const [contentVideoId, setContentVideoId] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();

  const getContentVideoDetails = (currentPageNo, pageSize) => {
    getContentVideo(
      { id: match.params.id, pageNumber: currentPageNo, pageSize: pageSize },
      dispatch
    );
  };

  useEffect(() => {
    if (match.params.id) {
      getContentVideoDetails(currentPageNo, pageSize);
      getContentById({id: match.params.id }, dispatch);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params, dispatch]);

  const { contentVideoList, totalRecords } = useSelector(
    (state) => state.getContentVideo
  );

  const { contentData } = useSelector((state) => state.getContentById);

  const deleteContentVideoSuccess = () => {
    setShowDeletePopup(false);
    getContentVideoDetails(currentPageNo, pageSize);
  };

  const handelDelete = () => {
    deleteContentVideo(
      { id: contentVideoId, deleteSuccess: deleteContentVideoSuccess },
      dispatch
    );
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <div className="video-list-container">
            <div className="video-list-head">
              <div>
                <h2>Content Details - ({contentData.name})</h2>
              </div>
              <div>
                <Button
                  className="black-btn fullwidth"
                  onClick={() =>
                    history.push(`/content/${match.params.id}/video/create`)
                  }
                >
                  Add Content Details
                  <span>
                    <img src={plus} alt="plus" />
                  </span>
                </Button>
              </div>
            </div>
            {contentVideoList &&
              contentVideoList.map((contentList) => (
                <div className="video-card customborder" key={contentList.id}>
                  <div className="video-card-btn">
                    <div className="btn-section">
                      <Button
                        className="black-btn transparent"
                        onClick={() =>
                          history.push(`/content/${contentList.id}/video/edit`)
                        }
                      >
                        Edit
                      </Button>
                      <span>|</span>
                      <Button
                        className="black-btn transparent red"
                        onClick={() => {
                          setShowDeletePopup(true);
                          setContentVideoId(contentList.id);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                  <div className="video-card-section">
                    <div className="video-list-img">
                      <label>Thumbnail Image</label>
                      <img src={contentList.thumbnail_image} alt="img" />
                    </div>
                    <div className="video">
                      <label>Thumbnail Video</label>
                      <VideoPlayer url={contentList.thumbnail_video} />
                    </div>
                    <div className="video">
                      <label>Full Video</label>
                      <VideoPlayer url={contentList.video} />
                    </div>
                  </div>
                  <div className="film">
                    <div className="video-card-text">
                      <div className="video-card-text-list">
                        <h2>Name of Film</h2>
                        <h3>{contentList.film_name}</h3>
                      </div>
                      <div className="video-card-text-list">
                        <h2>Name of Director</h2>
                        <h3>{contentList.director_name}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <PaginationList
        currentPageNo={currentPageNo}
        setCurrentPageNo={setCurrentPageNo}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalRecords={totalRecords}
        getCurentPageData={getContentVideoDetails}
      />
      <DeletePopup
        show={showDeletePopup}
        handleClose={() => setShowDeletePopup(false)}
        handelDelete={handelDelete}
      />
    </Layout>
  );
};

export default ContentList;
