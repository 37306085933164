import {
  GET_PRODUCTION_BY_ID_REQUEST,
  GET_PRODUCTION_BY_ID_FAILURE,
  GET_PRODUCTION_BY_ID_SUCCESS
} from '../production-constants';

const getProductionByIdRequest = (data) => ({
  data,
  type: GET_PRODUCTION_BY_ID_REQUEST
});

export const getProductionByIdSuccess = (data) => ({
  data,
  type: GET_PRODUCTION_BY_ID_SUCCESS
});

export const getProductionByIdFailure = () => ({
  type: GET_PRODUCTION_BY_ID_FAILURE
});

export const getProductionById = async (data, dispatch) => {
  dispatch(getProductionByIdRequest(data));
};
