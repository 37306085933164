import {
  GET_CONTENT_VIDEO_REQUEST,
  GET_CONTENT_VIDEO_FAILURE,
  GET_CONTENT_VIDEO_SUCCESS
} from '../content-constants';

const initialState = {
  isLoading: false,
  contentVideoList: []
};

const getContentVideo = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTENT_VIDEO_REQUEST:
      return {
        ...state,
        isLoading: true,
        contentVideoList: []
      };
    case GET_CONTENT_VIDEO_FAILURE:
      return {
        ...state,
        isLoading: false,
        contentVideoList: []
      };
    case GET_CONTENT_VIDEO_SUCCESS:
      const { data, totalRecords } = action.data;
      return {
        ...state,
        isLoading: false,
        contentVideoList: data,
        totalRecords
      };
    default:
      return state;
  }
};

export default getContentVideo;
