import React from 'react';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { Form } from 'react-bootstrap';

const TagInput = ({ label, placeholder, tags, changeTags, feedback }) => {
  return (
    <div className="custom-input">
      <label>
        {label}
        {/* <span className="start-required">*</span> */}
      </label>
      <ReactTagInput
        tags={tags}
        placeholder={placeholder}
        onChange={(newTags) => changeTags(newTags)}
      />
      <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
    </div>
  );
};

export default TagInput;
