import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../../../components/layout';
import ShowReelForm from '../showreel-form';
import { getShowReelById } from '../list/get-showreel-action';
import { updateShowReel } from './update-showreel-action';

const UpdateShowReel = ({ history, match }) => {
  const [updateShowReelDetails, setUpdateShowReelDetails] = useState({
    thumbnailImage: null,
    videoUrl: null
  });
  const [noData, setnoData] = useState(false);
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.updateShowReel);

  useEffect(() => {
    if (match.params.id) {
      getShowReelById({ id: match.params.id }, dispatch);
    }
  }, [match.params, dispatch]);

  const { showReelData, isEditShowReelLoading } = useSelector(
    (state) => state.getShowReelById
  );

  useEffect(() => {
    if (Object.keys(showReelData).length) {
      setUpdateShowReelDetails({
        thumbnailImage: showReelData.thumbnail_image || null,
        videoUrl: showReelData.video || null
      });
      setnoData(false);
    } else {
      setnoData(true);
    }
  }, [showReelData]);

  const showReelSuccess = () => {
    history.push('/showreel');
  };

  const handleUpdateShowReel = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const { thumbnailImage, videoUrl } = updateShowReelDetails;

    if (thumbnailImage && videoUrl) {
      let showReelFormData = new FormData();

      if (thumbnailImage && typeof thumbnailImage === 'object') {
        showReelFormData.append('thumbnail_image', thumbnailImage);
      }

      if (videoUrl && typeof videoUrl === 'object') {        
        showReelFormData.append('video', videoUrl);
      }

      updateShowReel(
        {
          formData: showReelFormData,
          id: match.params.id,
          updateSuccess: showReelSuccess
        },
        dispatch
      );
    }
  };

  const handleCancel = () => {
    history.push('/showreel');
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <ShowReelForm
            showReelDetails={updateShowReelDetails}
            setShowReelDetails={setUpdateShowReelDetails}
            validated={validated}
            handleCancel={handleCancel}
            handleSubmitShowReel={handleUpdateShowReel}
            noData={noData}
            isLoading={isLoading}
            isEditShowReelLoading={isEditShowReelLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default UpdateShowReel;
