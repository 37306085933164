import {
  ADD_PRODUCTION_REQUEST,
  ADD_PRODUCTION_FAILURE,
  ADD_PRODUCTION_SUCCESS
} from '../production-constants';

const addProductionRequest = (data) => ({
  data,
  type: ADD_PRODUCTION_REQUEST
});

export const addProductionSuccess = () => ({
  type: ADD_PRODUCTION_SUCCESS
});

export const addProductionFailure = () => ({
  type: ADD_PRODUCTION_FAILURE
});

export const addProduction = async (data, dispatch) => {
  dispatch(addProductionRequest(data));
};
