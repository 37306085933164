import {
  ADD_CONTENT_REQUEST,
  ADD_CONTENT_FAILURE,
  ADD_CONTENT_SUCCESS
} from '../content-constants';

const initialState = {
  isLoading: false
};

const addContent = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONTENT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ADD_CONTENT_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case ADD_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default addContent;
