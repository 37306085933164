import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { portfolioUrlWithId } from '../../../../utils/urls';
import { GET_PORTFOLIO_BY_ID_REQUEST } from '../portfolio-constants';
import {
  getPortfolioByIdSuccess,
  getPortfolioByIdFailure
} from './get-portfolio-action';

const { getRequest } = new HttpHelper();

export function* getPortfolioById({ data }) {
  try {
    const response = yield call(getRequest, {
      url: portfolioUrlWithId(data.id)
    });

    if (response.error) {
      yield put(getPortfolioByIdFailure());
    } else {
      yield put(getPortfolioByIdSuccess(response.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetPortfolioById() {
  yield takeLatest(GET_PORTFOLIO_BY_ID_REQUEST, getPortfolioById);
}
