import React from 'react';

import Select from 'react-select';

const SelectInput = ({ label, value, validated, options, handleChange }) => {
  return (
    <div className="custom-input">
      <label>
        {label}
        <span className="start-required">*</span>
      </label>
      <Select
        className={`${validated && value === '' && 'custom-class'}`}
        isClearable={true}
        isSearchable={true}
        name="color"
        options={options}
        onChange={handleChange}
        defaultValue={
          value && {
            label: value,
            value: value
          }
        }
      />
    </div>
  );
};

export default SelectInput;
