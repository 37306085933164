export const GET_CONTENT_REQUEST = 'GET_CONTENT_REQUEST';
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';
export const GET_CONTENT_FAILURE = 'GET_CONTENT_FAILURE';

export const GET_CONTENT_BY_ID_REQUEST = 'GET_CONTENT_BY_ID_REQUEST';
export const GET_CONTENT_BY_ID_SUCCESS = 'GET_CONTENT_BY_ID_SUCCESS';
export const GET_CONTENT_BY_ID_FAILURE = 'GET_CONTENT_BY_ID_FAILURE';

export const ADD_CONTENT_REQUEST = 'ADD_CONTENT_REQUEST';
export const ADD_CONTENT_SUCCESS = 'ADD_CONTENT_SUCCESS';
export const ADD_CONTENT_FAILURE = 'ADD_CONTENT_FAILURE';

export const UPDATE_CONTENT_REQUEST = 'UPDATE_CONTENT_REQUEST';
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS';
export const UPDATE_CONTENT_FAILURE = 'UPDATE_CONTENT_FAILURE';

export const DELETE_CONTENT_REQUEST = 'DELETE_CONTENT_REQUEST';
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_FAILURE = 'DELETE_CONTENT_FAILURE';

export const GET_CONTENT_VIDEO_REQUEST = 'GET_CONTENT_VIDEO_REQUEST';
export const GET_CONTENT_VIDEO_SUCCESS = 'GET_CONTENT_VIDEO_SUCCESS';
export const GET_CONTENT_VIDEO_FAILURE = 'GET_CONTENT_VIDEO_FAILURE';

export const GET_CONTENT_VIDEO_BY_ID_REQUEST =
  'GET_CONTENT_VIDEO_BY_ID_REQUEST';
export const GET_CONTENT_VIDEO_BY_ID_SUCCESS =
  'GET_CONTENT_VIDEO_BY_ID_SUCCESS';
export const GET_CONTENT_VIDEO_BY_ID_FAILURE =
  'GET_CONTENT_VIDEO_BY_ID_FAILURE';

export const ADD_CONTENT_VIDEO_REQUEST = 'ADD_CONTENT_VIDEO_REQUEST';
export const ADD_CONTENT_VIDEO_SUCCESS = 'ADD_CONTENT_VIDEO_SUCCESS';
export const ADD_CONTENT_VIDEO_FAILURE = 'ADD_CONTENT_VIDEO_FAILURE';

export const UPDATE_CONTENT_VIDEO_REQUEST = 'UPDATE_CONTENT_VIDEO_REQUEST';
export const UPDATE_CONTENT_VIDEO_SUCCESS = 'UPDATE_CONTENT_VIDEO_SUCCESS';
export const UPDATE_CONTENT_VIDEO_FAILURE = 'UPDATE_CONTENT_VIDEO_FAILURE';

export const DELETE_CONTENT_VIDEO_REQUEST = 'DELETE_CONTENT_VIDEO_REQUEST';
export const DELETE_CONTENT_VIDEO_SUCCESS = 'DELETE_CONTENT_VIDEO_SUCCESS';
export const DELETE_CONTENT_VIDEO_FAILURE = 'DELETE_CONTENT_VIDEO_FAILURE';

export const SORT_CONTENT_REQUEST = 'SORT_CONTENT_REQUEST';
export const SORT_CONTENT_SUCCESS = 'SORT_CONTENT_SUCCESS';
export const SORT_CONTENT_FAILURE = 'SORT_CONTENT_FAILURE';
