import {
  DELETE_PRODUCTION_REQUEST,
  DELETE_PRODUCTION_FAILURE,
  DELETE_PRODUCTION_SUCCESS
} from '../production-constants';

const deleteProductionRequest = (data) => ({
  data,
  type: DELETE_PRODUCTION_REQUEST
});

export const deleteProductionSuccess = () => ({
  type: DELETE_PRODUCTION_SUCCESS
});

export const deleteProductionFailure = () => ({
  type: DELETE_PRODUCTION_FAILURE
});

export const deleteProduction = async (data, dispatch) => {
  dispatch(deleteProductionRequest(data));
};
