import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'react-bootstrap';

import { getDirectorById } from '../list/get-director-action';
import { getDirectorVideo } from '../list/director-video-action';
import { deleteDirectorVideo } from '../delete/delete-director-video-action';

import Layout from '../../../components/layout';
import PaginationList from '../../../components/pagination';
import DeletePopup from '../../../components/modal/delete-popup';
import VideoPlayer from '../../../components/video-player';
import plus from '../../../assets/images/plus.png';

const DirectorList = ({ match, history }) => {
  const [directorVideoId, setDirectorVideoId] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showPagination, setShowPagination] = useState(0);

  const dispatch = useDispatch();

  const getDirectorVideoDetails = (currentPageNo, pageSize) => {
    getDirectorVideo(
      { id: match.params.id, pageNumber: currentPageNo, pageSize: pageSize },
      dispatch
    );
  };

  useEffect(() => {
    if (match.params.id) {
      getDirectorById({ id: match.params.id }, dispatch);
      getDirectorVideoDetails(currentPageNo, pageSize);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params, dispatch]);

  const { directorData } = useSelector((state) => state.getDirectorById);
  const { directorVideoList, totalRecords } = useSelector(
    (state) => state.getDirectorVideo
  );

  const deleteDirectorVideoSuccess = () => {
    setShowDeletePopup(false);
    getDirectorVideoDetails(currentPageNo, pageSize);
  };

  const handelDelete = () => {
    deleteDirectorVideo(
      { id: directorVideoId, deleteSuccess: deleteDirectorVideoSuccess },
      dispatch
    );
  };

  const handleScroll = (e) => {
    setShowPagination(e.target.scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
  }, []);

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <div className="video-list-container">
            <div className="showreal-text">
              <h2>Director Details</h2>
              {!Object.keys(directorData).length && (
                <div>
                  <Button
                    className="black-btn fullwidth"
                    onClick={() => history.push('/director/create')}
                  >
                    Add Director Details
                    <span>
                      <img src={plus} alt="plus" />
                    </span>
                  </Button>
                </div>
              )}
            </div>

            <div className="video-card customborder" key={directorData.id}>
              <div className="video-card-btn director-head">
                <h2>{directorData.name}</h2>
              </div>

              <div className="film">
                <div className="video-card-text">
                  <div className="video-card-text-list">
                    <h2>Director Image</h2>
                    <div className="brand-img">
                      <img src={directorData.profile_pic} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="video-card-text">
                <div className="video-card-text-list width">
                  <h2>Director Writeup</h2>
                  <h3>{directorData.bio}</h3>
                </div>
              </div>
              <div className="video-card-text video">
                <div>
                  <label>Showreel Video</label>
                  <VideoPlayer url={directorData.reel_video} />
                </div>
              </div>
            </div>

            <div className="showreal-text">
              <h2>Portfolio Video</h2>
              <div>
                <Button
                  className="black-btn halfwidth"
                  onClick={() => history.push(`/director/${directorData.id}/sort`)}
                >
                  Reorder
                </Button>
                <Button
                  className="black-btn fullwidth m-l-20"
                  onClick={() =>
                    history.push(`/director/${directorData.id}/video/create`)
                  }
                >
                  Add Portfolio Video
                  <span>
                    <img src={plus} alt="plus" />
                  </span>
                </Button>
              </div>
            </div>
            {directorVideoList &&
              directorVideoList.map((videoList) => (
                <div className="video-card customborder" key={videoList.id}>
                  <div className="video-card-btn">
                    <div className="btn-section">
                      <Button
                        className="black-btn transparent"
                        onClick={() =>
                          history.push(`/director/${videoList.id}/video/edit`)
                        }
                      >
                        Edit
                      </Button>
                      <span>|</span>
                      <Button
                        className="black-btn transparent red"
                        onClick={() => {
                          setShowDeletePopup(true);
                          setDirectorVideoId(videoList.id);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                  <div className="video-card-section">
                    <div className="video-list-img">
                      <label>Portfolio Thumbnail Image</label>
                      <img src={videoList.thumbnail_image} alt="img" />
                    </div>
                    <div className="video">
                      <label>Portfolio Short Video</label>
                      <VideoPlayer url={videoList.thumbnail_video} />
                    </div>
                    <div className="video">
                      <label>Portfolio Full Video</label>
                      <VideoPlayer url={videoList.video} />
                    </div>
                  </div>
                  <div className="film">
                    <div className="video-card-text">
                      <div className="video-card-text-list">
                        <h2>Name of Film</h2>
                        <h3>{videoList.film_name}</h3>
                      </div>
                      <div className="video-card-text-list">
                        <h2>Brand Name</h2>
                        <h3>{videoList.brand_name}</h3>
                      </div>
                      <div className="video-card-text-list">
                        <h2>Video Type</h2>
                        <h3 style={{color: 'red'}}>{videoList.video_type}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {showPagination > 300 && (
        <PaginationList
          currentPageNo={currentPageNo}
          setCurrentPageNo={setCurrentPageNo}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalRecords={totalRecords}
          getCurentPageData={getDirectorVideoDetails}
        />
      )}

      <DeletePopup
        show={showDeletePopup}
        handleClose={() => setShowDeletePopup(false)}
        handelDelete={handelDelete}
      />
    </Layout>
  );
};

export default DirectorList;
