import React from 'react';

import { Button, Modal } from 'react-bootstrap';

import close from '../../assets/images/close1.png';

const DeletePopup = ({ show, handleClose, handelDelete }) => {
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <div className="close-icon" onClick={handleClose}>
        <img src={close} alt="text"></img>
      </div>
      <Modal.Body>
        <div className="add-director">
          <div className="add-director-content">
            <h2>Are you sure you want to delete ?</h2>
            <div className="del-button">
              <Button className="red-btn" onClick={handelDelete}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeletePopup;
