export const GET_DIRECTOR_REQUEST = 'GET_DIRECTOR_REQUEST';
export const GET_DIRECTOR_SUCCESS = 'GET_DIRECTOR_SUCCESS';
export const GET_DIRECTOR_FAILURE = 'GET_DIRECTOR_FAILURE';

export const GET_DIRECTOR_BY_ID_REQUEST = 'GET_DIRECTOR_BY_ID_REQUEST';
export const GET_DIRECTOR_BY_ID_SUCCESS = 'GET_DIRECTOR_BY_ID_SUCCESS';
export const GET_DIRECTOR_BY_ID_FAILURE = 'GET_DIRECTOR_BY_ID_FAILURE';

export const ADD_DIRECTOR_REQUEST = 'ADD_DIRECTOR_REQUEST';
export const ADD_DIRECTOR_SUCCESS = 'ADD_DIRECTOR_SUCCESS';
export const ADD_DIRECTOR_FAILURE = 'ADD_DIRECTOR_FAILURE';

export const UPDATE_DIRECTOR_REQUEST = 'UPDATE_DIRECTOR_REQUEST';
export const UPDATE_DIRECTOR_SUCCESS = 'UPDATE_DIRECTOR_SUCCESS';
export const UPDATE_DIRECTOR_FAILURE = 'UPDATE_DIRECTOR_FAILURE';

export const DELETE_DIRECTOR_REQUEST = 'DELETE_DIRECTOR_REQUEST';
export const DELETE_DIRECTOR_SUCCESS = 'DELETE_DIRECTOR_SUCCESS';
export const DELETE_DIRECTOR_FAILURE = 'DELETE_DIRECTOR_FAILURE';

export const GET_DIRECTOR_VIDEO_REQUEST = 'GET_DIRECTOR_VIDEO_REQUEST';
export const GET_DIRECTOR_VIDEO_SUCCESS = 'GET_DIRECTOR_VIDEO_SUCCESS';
export const GET_DIRECTOR_VIDEO_FAILURE = 'GET_DIRECTOR_VIDEO_FAILURE';

export const GET_DIRECTOR_VIDEO_BY_ID_REQUEST =
  'GET_DIRECTOR_VIDEO_BY_ID_REQUEST';
export const GET_DIRECTOR_VIDEO_BY_ID_SUCCESS =
  'GET_DIRECTOR_VIDEO_BY_ID_SUCCESS';
export const GET_DIRECTOR_VIDEO_BY_ID_FAILURE =
  'GET_DIRECTOR_VIDEO_BY_ID_FAILURE';

export const ADD_DIRECTOR_VIDEO_REQUEST = 'ADD_DIRECTOR_VIDEO_REQUEST';
export const ADD_DIRECTOR_VIDEO_SUCCESS = 'ADD_DIRECTOR_VIDEO_SUCCESS';
export const ADD_DIRECTOR_VIDEO_FAILURE = 'ADD_DIRECTOR_VIDEO_FAILURE';

export const UPDATE_DIRECTOR_VIDEO_REQUEST = 'UPDATE_DIRECTOR_VIDEO_REQUEST';
export const UPDATE_DIRECTOR_VIDEO_SUCCESS = 'UPDATE_DIRECTOR_VIDEO_SUCCESS';
export const UPDATE_DIRECTOR_VIDEO_FAILURE = 'UPDATE_DIRECTOR_VIDEO_FAILURE';

export const DELETE_DIRECTOR_VIDEO_REQUEST = 'DELETE_DIRECTOR_VIDEO_REQUEST';
export const DELETE_DIRECTOR_VIDEO_SUCCESS = 'DELETE_DIRECTOR_VIDEO_SUCCESS';
export const DELETE_DIRECTOR_VIDEO_FAILURE = 'DELETE_DIRECTOR_VIDEO_FAILURE';

export const SORT_DIRECTOR_REQUEST = 'SORT_DIRECTOR_REQUEST';
export const SORT_DIRECTOR_SUCCESS = 'SORT_DIRECTOR_SUCCESS';
export const SORT_DIRECTOR_FAILURE = 'SORT_DIRECTOR_FAILURE';

export const SORT_DIRECTOR_VIDEO_REQUEST = 'SORT_DIRECTOR_VIDEO_REQUEST';
export const SORT_DIRECTOR_VIDEO_SUCCESS = 'SORT_DIRECTOR_VIDEO_SUCCESS';
export const SORT_DIRECTOR_VIDEO_FAILURE = 'SORT_DIRECTOR_VIDEO_FAILURE';
