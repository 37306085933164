import React, { Fragment } from 'react';

import { Form, Button } from 'react-bootstrap';

import TextInput from '../../../components/form-fields/text-input';
import FileInput from '../../../components/form-fields/file-input';
import CreatableSelectInput from '../../../components/form-fields/creatable-select-input';
import ButtonLoader from '../../../assets/images/button-loader.svg';
import arrow from '../../../assets/images/arrow.png';

const ProductionForm = ({
  productionDetails,
  setProductionDetails,
  validated,
  handleCancel,
  handleSubmitProduction,
  isLoading,
  noData,
  isEditproductionLoading
}) => {  
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSubmitProduction(e);
    }
  };

  const formFileds = [
    {
      type: 'file',
      label: 'Upload Thumbnail Image',
      heading: 'Thumbnail Image',
      value: productionDetails.thumbnailImage,
      onClickFile: () =>
        setProductionDetails({
          ...productionDetails,
          thumbnailImage: null
        }),
      onChangeFile: (e) =>
        setProductionDetails({
          ...productionDetails,
          thumbnailImage: e.target.files[0]
        }),
      feedback: 'Please upload thumbnail image.',
      validated: validated
    },
    {
      type: 'fileVideo',
      label: 'Upload Thumbnail Video',
      heading: 'Thumbnail Video',
      value: productionDetails.thumbnailVideo,
      onClickFile: () =>
        setProductionDetails({
          ...productionDetails,
          thumbnailVideo: null
        }),
      onChangeFile: (e) =>
        setProductionDetails({
          ...productionDetails,
          thumbnailVideo: e.target.files[0]
        }),
      feedback: 'Please upload thumbnail video.',
      validated: validated
    },   
    {
      type: 'text',
      label: 'Video URL',
      placeholder: 'Enter video url',
      value: productionDetails.video,
      changeText: (e) =>
        setProductionDetails({
          ...productionDetails,
          video: e.target.value
        }),
      feedback: 'Please enter video url.'
    },
    {
      type: 'text',
      label: 'Production Name',
      placeholder: 'Enter production name',
      value: productionDetails.name,
      changeText: (e) =>
        setProductionDetails({
          ...productionDetails,
          name: e.target.value
        }),
      feedback: 'Please enter production name.'
    },
    {
      type: 'text',
      label: 'Website',
      placeholder: 'Enter website',
      value: productionDetails.website,
      changeText: (e) =>
        setProductionDetails({
          ...productionDetails,
          website: e.target.value
        }),
      feedback: 'Please enter website.'
    },
    {
      type: 'text',
      label: 'Film Name',
      placeholder: 'Enter film name',
      value: productionDetails.filmName,
      changeText: (e) =>
        setProductionDetails({
          ...productionDetails,
          filmName: e.target.value
        }),
      feedback: 'Please enter film name.'
    },
    {
      type: 'text',
      label: 'Director Name',
      placeholder: 'Enter director name',
      value: productionDetails.directorName,
      changeText: (e) =>
        setProductionDetails({
          ...productionDetails,
          directorName: e.target.value
        }),
      feedback: 'Please enter director name.'
    },
    {
      type: 'text',
      label: 'Brand Name',
      placeholder: 'Enter brand name',
      value: productionDetails.brandName,
      changeText: (e) =>
        setProductionDetails({
          ...productionDetails,
          brandName: e.target.value
        }),
      feedback: 'Please enter brand name.',
      required: false
    }
  ];

  const renderFormFields = (input) => {
    switch (input.type) {
      case 'file':
        case 'fileVideo':
        return (
          <FileInput
            label={input.label}
            heading={input.heading}
            value={input.value}
            onClickFile={input.onClickFile}
            onChangeFile={input.onChangeFile}
            feedback={input.feedback}
            validated={input.validated}
            type={input.type}
          />
        );
      case 'text':
        return (
          <TextInput
            label={input.label}
            placeholder={input.placeholder}
            value={input.value}
            changeText={input.changeText}
            feedback={input.feedback}
            required={input.required}
          />
        );
      case 'creatable-select':
        return (
          <CreatableSelectInput
            heading={input.heading}
            options={input.options}
            selectedOption={input.selectedOption}
            setSelectedOption={input.setSelectedOption}
            label={input.label}
            validated={validated}
          />
        );
      default:
        return '';
    }
  };

  return !isEditproductionLoading ? (
    <div className="common-container">
      <div className="form-container">
        <div className="back-arrow" onClick={handleCancel}>
          <img src={arrow} alt="arrow" />
        </div>
        <div className="head">
          <h4>Production Form</h4>
          <p>Enter your details below</p>
        </div>
        {!noData ? (
          <>
            <Form onKeyDown={handleKeyDown} noValidate validated={validated}>
              {formFileds.map((input, index) => (
                <Fragment key={index}>{renderFormFields(input)}</Fragment>
              ))}
            </Form>
            <div className="bottom-btn">
              <Button className="black-btn white width" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                className="black-btn width"
                onClick={handleSubmitProduction}
              >
                {!isLoading ? (
                  'Submit'
                ) : (
                  <>
                    <img src={ButtonLoader} alt="button-loader" />
                    <span className="laoder-span">Loading....</span>
                  </>
                )}
              </Button>
            </div>
          </>
        ) : (
          <Form>No data found</Form>
        )}
      </div>
    </div>
  ) : (
    <p style={{ color: '#000' }}>Loading...</p>
  );
};

export default ProductionForm;
