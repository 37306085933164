import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSortable } from "react-sortablejs";

import { Button } from 'react-bootstrap';

import { getContent, sortContent } from './content-action';
import { getContentById } from './get-content-action';
import { addContent } from '../create/create-content-action';
import { updateContent } from '../update/update-content-action';
import { deleteContent } from '../delete/delete-content-action';

import Layout from '../../../components/layout';
import DeletePopup from '../../../components/modal/delete-popup';
import AddContentPopup from '../../../components/modal/add-content-popup';

import plus from '../../../assets/images/plus.png';

const Content = ({ history }) => {
  const [contentId, setContentId] = useState('');
  const [contentName, setContentName] = useState('');
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [currentPageNo] = useState(1);
  const [pageSize] = useState(10000);
  const [validated, setValidated] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();

  const getContentDetails = (currentPageNo, pageSize) => {
    getContent({ pageNumber: currentPageNo, pageSize: pageSize }, dispatch);
  };

  useEffect(() => {
    getContentDetails(currentPageNo, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { contentList, isLoading } = useSelector(
    (state) => state.getContent
  );
  const { contentData } = useSelector((state) => state.getContentById);

  useEffect(() => {
    if (Object.keys(contentData).length) {
      setContentName(contentData.name || '');
    }
  }, [contentData]);

  const handleEditContent = (contentId) => {
    setShowAddPopup(true);
    setIsEdit(true);
    setContentId(contentId);
    getContentById({ id: contentId }, dispatch);
  };

  const deleteContentSuccess = () => {
    setShowDeletePopup(false);
    getContentDetails(currentPageNo, pageSize);
  };

  const handelDelete = () => {
    deleteContent(
      { id: contentId, deleteSuccess: deleteContentSuccess },
      dispatch
    );
  };

  const contentSuccessCallback = () => {
    getContentDetails(currentPageNo, pageSize);
    setShowAddPopup(false);
  };

  const handleCreateContent = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);

    if (contentName) {
      let contentFormData = new FormData();
      contentFormData.append('name', contentName);

      addContent(
        {
          formData: contentFormData,
          contentSuccessCallback
        },
        dispatch
      );
    }
  };

  const contentUpdateSuccessCallback = () => {
    setShowAddPopup(false);
    setIsEdit(false);
    getContentDetails(currentPageNo, pageSize);
  };

  const handleUpdateContent = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);

    if (contentName) {
      let contentFormData = new FormData();
      contentFormData.append('name', contentName);

      updateContent(
        {
          id: contentId,
          formData: contentFormData,
          successCallback: contentUpdateSuccessCallback
        },
        dispatch
      );
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleCreateContent(e);
    }
  };

  const updateSuccess = () => { };

  const getOrder = (list) => {
    const ids = [];
    for (let i = 0; i < list.length; i++) {
      const elem = list[i];
      ids.push(elem.id);
    }
    return ids;
  }

  const updateList = (list) => {
    let data = new FormData();
    data.append('order', getOrder(list));

    sortContent({
      data: list,
      formData: data,
      updateSuccess: updateSuccess
    }, dispatch);
  }

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          {!isLoading ? (
            <div className="video-list-container list-container">
              <div className="video-list-head">
                <div>
                  <h2>Content Name</h2>
                </div>
                <div>
                  <Button
                    className="black-btn width"
                    onClick={() => {
                      setShowAddPopup(true);
                      setContentName('');
                    }}
                  >
                    Add Content
                    <span>
                      <img src={plus} alt="plus" />
                    </span>
                  </Button>
                </div>
              </div>
              <ReactSortable list={contentList} setList={(sortedList) => updateList(sortedList)}>
              {contentList &&
                contentList.map((content) => (
                  <div className="director-list-section" key={content.id}>
                    <div className="director-list">
                      <p>{content.name}</p>
                      <Button
                        className="black-btn halfwidth transparent"
                        onClick={() =>
                          history.push(`/content/${content.id}/list`)
                        }
                      >
                        View Details
                      </Button>
                      <span>|</span>
                      <Button
                        className="black-btn halfwidth transparent"
                        onClick={() => handleEditContent(content.id)}
                      >
                        Edit
                      </Button>
                      <span>|</span>
                      <Button
                        className="black-btn transparent red"
                        onClick={() => {
                          setShowDeletePopup(true);
                          setContentId(content.id);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                ))}
              </ReactSortable>
            </div>
          ) : (
            <p style={{ color: '#000' }}>Loading...</p>
          )}
        </div>
      </div>
      <DeletePopup
        show={showDeletePopup}
        handleClose={() => setShowDeletePopup(false)}
        handelDelete={handelDelete}
      />
      <AddContentPopup
        show={showAddPopup}
        handleClose={() => {
          setShowAddPopup(false);
          setIsEdit(false);
        }}
        handleKeyDown={handleKeyDown}
        validated={validated}
        isEdit={isEdit}
        contentName={contentName}
        setContentName={setContentName}
        handleCreateContent={handleCreateContent}
        handleUpdateContent={handleUpdateContent}
      />
    </Layout>
  );
};

export default Content;
