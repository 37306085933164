import React from 'react';

import CreatableSelector from '../../creatable-select';

const CreatableSelectInput = ({
  heading,
  options,
  selectedOption,
  setSelectedOption,
  label,
  validated
}) => {
  return (
    <div className="custom-input">
      <label>
        {heading}
        <span className="start-required">*</span>
      </label>
      <CreatableSelector
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        label={label}
        validated={validated}
      />
    </div>
  );
};

export default CreatableSelectInput;
