import {
  DELETE_SHOW_REEL_REQUEST,
  DELETE_SHOW_REEL_FAILURE,
  DELETE_SHOW_REEL_SUCCESS
} from '../showreel-constants';

const deleteShowReelRequest = (data) => ({
  data,
  type: DELETE_SHOW_REEL_REQUEST
});

export const deleteShowReelSuccess = () => ({
  type: DELETE_SHOW_REEL_SUCCESS
});

export const deleteShowReelFailure = () => ({
  type: DELETE_SHOW_REEL_FAILURE
});

export const deleteShowReel = async (data, dispatch) => {
  dispatch(deleteShowReelRequest(data));
};
