export const GET_SHOW_REEL_REQUEST = 'GET_SHOW_REEL_REQUEST';
export const GET_SHOW_REEL_SUCCESS = 'GET_SHOW_REEL_SUCCESS';
export const GET_SHOW_REEL_FAILURE = 'GET_SHOW_REEL_FAILURE';

export const GET_SHOW_REEL_BY_ID_REQUEST = 'GET_SHOW_REEL_BY_ID_REQUEST';
export const GET_SHOW_REEL_BY_ID_SUCCESS = 'GET_SHOW_REEL_BY_ID_SUCCESS';
export const GET_SHOW_REEL_BY_ID_FAILURE = 'GET_SHOW_REEL_BY_ID_FAILURE';

export const ADD_SHOW_REEL_REQUEST = 'ADD_SHOW_REEL_REQUEST';
export const ADD_SHOW_REEL_SUCCESS = 'ADD_SHOW_REEL_SUCCESS';
export const ADD_SHOW_REEL_FAILURE = 'ADD_SHOW_REEL_FAILURE';

export const UPDATE_SHOW_REEL_REQUEST = 'UPDATE_SHOW_REEL_REQUEST';
export const UPDATE_SHOW_REEL_SUCCESS = 'UPDATE_SHOW_REEL_SUCCESS';
export const UPDATE_SHOW_REEL_FAILURE = 'UPDATE_SHOW_REEL_FAILURE';

export const DELETE_SHOW_REEL_REQUEST = 'DELETE_SHOW_REEL_REQUEST';
export const DELETE_SHOW_REEL_SUCCESS = 'DELETE_SHOW_REEL_SUCCESS';
export const DELETE_SHOW_REEL_FAILURE = 'DELETE_SHOW_REEL_FAILURE';
