import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../../../components/layout';
import ShowReelForm from '../showreel-form';
import { addShowReel } from './create-showreel-action';

const CreateShowReel = ({ history }) => {
  const [addShowReelDetails, setAddShowReelDetails] = useState({
    thumbnailImage: null,
    videoUrl: null
  });
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.addShowReel);

  const showReelSuccessCallback = () => {
    history.push('/showreel');
  };

  const handleCreateShowReel = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const { thumbnailImage, videoUrl } = addShowReelDetails;
    if (thumbnailImage && videoUrl) {
      let reelFormData = new FormData();
      reelFormData.append('thumbnail_image', thumbnailImage);
      reelFormData.append('video', videoUrl);

      addShowReel(
        {
          formData: reelFormData,
          showReelSuccessCallback
        },
        dispatch
      );
    }
  };

  const handleCancel = () => {
    history.push('/showreel');
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <ShowReelForm
            showReelDetails={addShowReelDetails}
            setShowReelDetails={setAddShowReelDetails}
            validated={validated}
            handleCancel={handleCancel}
            handleSubmitShowReel={handleCreateShowReel}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default CreateShowReel;
