import {
  GET_TYPE_OF_CONTENTS_REQUEST,
  GET_TYPE_OF_CONTENTS_FAILURE,
  GET_TYPE_OF_CONTENTS_SUCCESS
} from './admin-helper-constants';

const getTypeOfContentsRequest = () => ({
  type: GET_TYPE_OF_CONTENTS_REQUEST
});

export const getTypeOfContentsSuccess = (data) => ({
  data,
  type: GET_TYPE_OF_CONTENTS_SUCCESS
});

export const getTypeOfContentsFailure = () => ({
  type: GET_TYPE_OF_CONTENTS_FAILURE
});

export const getTypeOfContents = async (dispatch) => {
  dispatch(getTypeOfContentsRequest());
};
