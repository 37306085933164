import * as Toastr from 'toastr';

import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { productionUrlWithId } from '../../../../utils/urls';
import { UPDATE_PRODUCTION_REQUEST } from '../production-constants';
import {
  updateProductionSuccess,
  updateProductionFailure
} from './update-production-action';

const { patchRequest } = new HttpHelper();

export function* updateProduction({ data }) {
  try {
    const response = yield call(patchRequest, {
      url: productionUrlWithId(data.id),
      data: data.formData
    });

    if (response.error) {
      yield put(updateProductionFailure());
    } else if (response.data && response.data.status) {
      yield put(updateProductionSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.updateSuccess();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchUpdateProduction() {
  yield takeLatest(UPDATE_PRODUCTION_REQUEST, updateProduction);
}
