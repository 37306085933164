import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { showReelUrlWithId } from '../../../../utils/urls';
import { GET_SHOW_REEL_BY_ID_REQUEST } from '../showreel-constants';
import {
  getShowReelByIdSuccess,
  getShowReelByIdFailure
} from './get-showreel-action';

const { getRequest } = new HttpHelper();

export function* getShowReelById({ data }) {
  try {
    const response = yield call(getRequest, {
      url: showReelUrlWithId(data.id)
    });

    if (response.error) {
      yield put(getShowReelByIdFailure());
    } else {
      yield put(getShowReelByIdSuccess(response.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetShowReelById() {
  yield takeLatest(GET_SHOW_REEL_BY_ID_REQUEST, getShowReelById);
}
