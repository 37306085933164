import {
  ADD_DIRECTOR_REQUEST,
  ADD_DIRECTOR_FAILURE,
  ADD_DIRECTOR_SUCCESS
} from '../director-constants';

const addDirectorRequest = (data) => ({
  data,
  type: ADD_DIRECTOR_REQUEST
});

export const addDirectorSuccess = () => ({
  type: ADD_DIRECTOR_SUCCESS
});

export const addDirectorFailure = () => ({
  type: ADD_DIRECTOR_FAILURE
});

export const addDirector = async (data, dispatch) => {
  dispatch(addDirectorRequest(data));
};
