import {
  UPDATE_PRESS_REQUEST,
  UPDATE_PRESS_FAILURE,
  UPDATE_PRESS_SUCCESS
} from '../press-constants';

const updatePressRequest = (data) => ({
  data,
  type: UPDATE_PRESS_REQUEST
});

export const updatePressSuccess = () => ({
  type: UPDATE_PRESS_SUCCESS
});

export const updatePressFailure = () => ({
  type: UPDATE_PRESS_FAILURE
});

export const updatePress = async (data, dispatch) => {
  dispatch(updatePressRequest(data));
};
