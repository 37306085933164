import {
  DELETE_PRESS_REQUEST,
  DELETE_PRESS_FAILURE,
  DELETE_PRESS_SUCCESS
} from '../press-constants';

const deletePressRequest = (data) => ({
  data,
  type: DELETE_PRESS_REQUEST
});

export const deletePressSuccess = () => ({
  type: DELETE_PRESS_SUCCESS
});

export const deletePressFailure = () => ({
  type: DELETE_PRESS_FAILURE
});

export const deletePress = async (data, dispatch) => {
  dispatch(deletePressRequest(data));
};
