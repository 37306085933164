import {
  GET_DIRECTOR_BY_ID_REQUEST,
  GET_DIRECTOR_BY_ID_FAILURE,
  GET_DIRECTOR_BY_ID_SUCCESS
} from '../director-constants';

const getDirectorByIdRequest = (data) => ({
  data,
  type: GET_DIRECTOR_BY_ID_REQUEST
});

export const getDirectorByIdSuccess = (data) => ({
  data,
  type: GET_DIRECTOR_BY_ID_SUCCESS
});

export const getDirectorByIdFailure = () => ({
  type: GET_DIRECTOR_BY_ID_FAILURE
});

export const getDirectorById = async (data, dispatch) => {
  dispatch(getDirectorByIdRequest(data));
};
