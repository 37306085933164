import * as Toastr from 'toastr';

import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { videoUrlByVideoId } from '../../../utils/urls';
import { UPDATE_DIRECTOR_VIDEO_REQUEST } from '../director-constants';
import {
  updateDirectorVideoSuccess,
  updateDirectorVideoFailure
} from './update-director-video-action';

const { patchRequest } = new HttpHelper();

export function* updateDirectorVideo({ data }) {
  try {
    const response = yield call(patchRequest, {
      url: videoUrlByVideoId(data.id),
      data: data.formData
    });

    if (response.error) {
      yield put(updateDirectorVideoFailure());
    } else if (response.data && response.data.status) {
      yield put(updateDirectorVideoSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.updateSuccess();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchUpdateDirectorVideo() {
  yield takeLatest(UPDATE_DIRECTOR_VIDEO_REQUEST, updateDirectorVideo);
}
