import { call, put, takeLatest } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../../../utils/http-helper';
import { productionUrl, productionSortUrl } from '../../../../utils/urls';
import { GET_PRODUCTION_REQUEST, SORT_PRODUCTION_REQUEST } from '../production-constants';
import {
  getProductionSuccess,
  getProductionFailure,
  sortProductionSuccess,
  sortProductionFailure
} from './production-action';

const { getRequest, postRequest } = new HttpHelper();

export function* getProduction({ data }) {
  try {
    const response = yield call(getRequest, {
      url: productionUrl(data)
    });

    if (response.error) {
      yield put(getProductionFailure());
    } else {
      yield put(getProductionSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* sortProduction({ data }) {
  try {
    const response = yield call(postRequest, {
      url: productionSortUrl(),
      data: data.formData
    });

    if (response.error) {
      yield put(sortProductionFailure());
    } else if (response.data && response.data.status) {
      yield put(sortProductionSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.updateSuccess();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetProduction() {
  yield takeLatest(GET_PRODUCTION_REQUEST, getProduction);
}

export function* watchSortProduction() {
  yield takeLatest(SORT_PRODUCTION_REQUEST, sortProduction);
}
