import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Form, Button } from 'react-bootstrap';

import { getDirectorNames } from '../admin-helpers/director-names-action';

import TextInput from '../../components/form-fields/text-input';
import FileInput from '../../components/form-fields/file-input';
import CreatableSelectInput from '../../components/form-fields/creatable-select-input';
import ButtonLoader from '../../assets/images/button-loader.svg';
import arrow from '../../assets/images/arrow.png';
import TagInput from '../../components/form-fields/tag-input';

const ContentVideoForm = ({
  contentVideoDetails,
  setContentVideoDetails,
  validated,
  handleCancel,
  handleSubmitContentVideo,
  isLoading,
  noData,
  isEditContentVideoLoading
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getDirectorNames(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { directorNamesList } = useSelector((state) => state.getDirectorNames);

  const handleKeyDown = (e) => {
    // if (e.keyCode === 13) {
    //   e.preventDefault();
    //   handleSubmitContentVideo(e);
    // }
  };

  const formFileds = [
    {
      type: 'file',
      label: 'Upload Thumbnail Image',
      heading: 'Thumbnail Image',
      value: contentVideoDetails.thumbnailImage,
      onClickFile: () =>
        setContentVideoDetails({
          ...contentVideoDetails,
          thumbnailImage: null
        }),
      onChangeFile: (e) =>
        setContentVideoDetails({
          ...contentVideoDetails,
          thumbnailImage: e.target.files[0]
        }),
      feedback: 'Please upload thumbnail image.',
      validated: validated
    },
    {
      type: 'fileVideo',
      label: 'Upload Thumbnail Video',
      heading: 'Thumbnail Video',
      value: contentVideoDetails.thumbnailVideo,
      onClickFile: () =>
        setContentVideoDetails({
          ...contentVideoDetails,
          thumbnailVideo: null
        }),
      onChangeFile: (e) =>
        setContentVideoDetails({
          ...contentVideoDetails,
          thumbnailVideo: e.target.files[0]
        }),
      feedback: 'Please upload thumbnail video.',
      validated: validated
    },
    {
      type: 'text',
      label: 'Video URL',
      placeholder: 'Enter video url',
      value: contentVideoDetails.video,
      changeText: (e) =>
        setContentVideoDetails({
          ...contentVideoDetails,
          video: e.target.value
        }),
      feedback: 'Please enter video url.'
    },
    {
      type: 'text',
      label: 'Film Name',
      placeholder: 'Enter film name',
      value: contentVideoDetails.filmName,
      changeText: (e) =>
        setContentVideoDetails({
          ...contentVideoDetails,
          filmName: e.target.value
        }),
      feedback: 'Please enter film name.'
    },
    {
      type: 'creatable-select',
      heading: 'Director Name',
      options: directorNamesList,
      selectedOption: contentVideoDetails,
      setSelectedOption: setContentVideoDetails,
      label: 'directorName',
      validated: validated
    },
    {
      type: 'text',
      label: 'Co-Producer Name',
      placeholder: 'Enter Co-Producer name',
      value: contentVideoDetails.coProducerName,
      changeText: (e) =>
        setContentVideoDetails({
          ...contentVideoDetails,
          coProducerName: e.target.value
        }),
      feedback: 'Please enter co-producer name.',
      required: false
    },
    {
      type: 'tagInput',
      label: 'Awards',
      placeholder: 'Type and press enter',
      tags: contentVideoDetails.awards || [],
      changeTags: (value) =>
        setContentVideoDetails({
          ...contentVideoDetails,
          awards: value
        }),
      feedback: 'Please enter the awards.'
    }
  ];

  const renderFormFields = (input) => {
    switch (input.type) {
      case 'file':
        case 'fileVideo':
        return (
          <FileInput
            label={input.label}
            heading={input.heading}
            value={input.value}
            onClickFile={input.onClickFile}
            onChangeFile={input.onChangeFile}
            feedback={input.feedback}
            validated={input.validated}
            type={input.type}
          />
        );
      case 'text':
        return (
          <TextInput
            label={input.label}
            placeholder={input.placeholder}
            value={input.value}
            changeText={input.changeText}
            feedback={input.feedback}
            required={input.required}
          />
        );
      case 'tagInput':
        return (
          <TagInput
            label={input.label}
            placeholder={input.placeholder}
            tags={input.tags}
            changeTags={input.changeTags}
            feedback={input.feedback}
          />
        );  
      case 'creatable-select':
        return (
          <CreatableSelectInput
            heading={input.heading}
            options={input.options}
            selectedOption={input.selectedOption}
            setSelectedOption={input.setSelectedOption}
            label={input.label}
            validated={validated}
          />
        );
      default:
        return '';
    }
  };

  return !isEditContentVideoLoading ? (
    <div className="common-container">
      <div className="form-container">
        <div className="back-arrow" onClick={handleCancel}>
          <img src={arrow} alt="arrow" />
        </div>
        <div className="head">
          <h4>Content Details Form</h4>
          <p>Enter your details below</p>
        </div>
        {!noData ? (
          <>
            <Form onKeyDown={handleKeyDown} noValidate validated={validated}>
              {formFileds.map((input, index) => (
                <Fragment key={index}>{renderFormFields(input)}</Fragment>
              ))}

              <div className="bottom-btn">
                <Button
                  className="black-btn white width"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="black-btn width"
                  onClick={handleSubmitContentVideo}
                >
                  {!isLoading ? (
                    'Submit'
                  ) : (
                    <>
                      <img src={ButtonLoader} alt="button-loader" />
                      <span className="laoder-span">Loading....</span>
                    </>
                  )}
                </Button>
              </div>
            </Form>
          </>
        ) : (
          <Form>No data found</Form>
        )}
      </div>
    </div>
  ) : (
    <p style={{ color: '#000' }}>Loading...</p>
  );
};

export default ContentVideoForm;
