import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../../../components/layout';
import PortfolioForm from '../portfolio-form';
import { getPortfolioById } from '../list/get-portfolio-action';
import { updatePortfolio } from './update-portfolio-action';

const UpdatePortfolio = ({ history, match }) => {
  const [updatePortfolioDetails, setUpdatePortfolioDetails] = useState({
    thumbnailImage: null,
    thumbnailVideo: null,
    videoUrl: '',
    category: '',
    filmName: '',
    directorName: '',
    brandName: '',
    typeOfContent: '',
    agencyName: '',
    coProducerName: '',
    awards: ''
  });
  const [noData, setnoData] = useState(false);
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.updatePortfolio);

  useEffect(() => {
    if (match.params.id) {
      getPortfolioById({ id: match.params.id }, dispatch);
    }
  }, [match.params, dispatch]);

  const { portfolioData, isEditPortfolioLoading } = useSelector(
    (state) => state.getPortfolioById
  );

  useEffect(() => {
    if (Object.keys(portfolioData).length) {
      setUpdatePortfolioDetails({
        thumbnailImage: portfolioData.thumbnail_image || null,
        thumbnailVideo: portfolioData.thumbnail_video || null,
        videoUrl: portfolioData.video || '',
        category: portfolioData.category || '',
        filmName: portfolioData.film_name || '',
        directorName: portfolioData.director_name || '',
        brandName: portfolioData.brand_name || '',
        typeOfContent: portfolioData.type_of_content || '',
        agencyName: portfolioData.agency || '',
        coProducerName: portfolioData.co_producer || '',
        awards: portfolioData.awards ? portfolioData.awards.split(',') : []
      });
      setnoData(false);
    } else {
      setnoData(true);
    }
  }, [portfolioData]);

  const portfolioSuccess = () => {
    history.push('/portfolio');
  };

  const handleUpdatePortfolio = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const {
      thumbnailImage,
      thumbnailVideo,
      videoUrl,
      category,
      filmName,
      directorName,
      brandName,
      typeOfContent,
      agencyName,
      coProducerName,
      awards
    } = updatePortfolioDetails;

    if (thumbnailImage && thumbnailVideo && videoUrl && category && filmName && directorName) {
      let portfolioFormData = new FormData();

      portfolioFormData.append('video', videoUrl);
      portfolioFormData.append('category', category);
      portfolioFormData.append('film_name', filmName);
      portfolioFormData.append('agency', agencyName);
      portfolioFormData.append('co_producer', coProducerName);
      portfolioFormData.append('awards', awards);
      portfolioFormData.append('director_name', directorName);

      if (thumbnailImage && typeof thumbnailImage === 'object') {
        portfolioFormData.append('thumbnail_image', thumbnailImage);
      }

      if (thumbnailVideo && typeof thumbnailVideo === 'object') {
        portfolioFormData.append('thumbnail_video', thumbnailVideo);
      }

      if (category === 'Ad Film' && brandName) {
        portfolioFormData.append('brand_name', brandName);
        updatePortfolio(
          {
            formData: portfolioFormData,
            id: match.params.id,
            updateSuccess: portfolioSuccess
          },
          dispatch
        );
      } else if (category === 'Content Film' && typeOfContent) {
        portfolioFormData.append('type_of_content', typeOfContent);
        updatePortfolio(
          {
            formData: portfolioFormData,
            id: match.params.id,
            updateSuccess: portfolioSuccess
          },
          dispatch
        );
      }
    }
  };

  const handleCancel = () => {
    history.push('/portfolio');
  };
  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <PortfolioForm
            portfolioDetails={updatePortfolioDetails}
            setPortfolioDetails={setUpdatePortfolioDetails}
            validated={validated}
            handleCancel={handleCancel}
            handleSubmitPortfolio={handleUpdatePortfolio}
            noData={noData}
            isLoading={isLoading}
            isEditPortfolioLoading={isEditPortfolioLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default UpdatePortfolio;
