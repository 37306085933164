import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { directorUrlWithId } from '../../../utils/urls';
import { GET_DIRECTOR_BY_ID_REQUEST } from '../director-constants';
import {
  getDirectorByIdSuccess,
  getDirectorByIdFailure
} from './get-director-action';

const { getRequest } = new HttpHelper();

export function* getDirectorById({ data }) {
  try {
    const response = yield call(getRequest, {
      url: directorUrlWithId(data.id)
    });

    if (response.error) {
      yield put(getDirectorByIdFailure());
    } else {
      yield put(getDirectorByIdSuccess(response.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetDirectorById() {
  yield takeLatest(GET_DIRECTOR_BY_ID_REQUEST, getDirectorById);
}
