import React, {useState} from 'react';

import { Form } from 'react-bootstrap';
import { Player } from 'video-react';

import close from '../../../assets/images/close1.png';

const FileInput = ({
  label,
  heading,
  value,
  onClickFile,
  onChangeFile,
  feedback,
  validated,
  type
}) => {
  const [play, setPlay] = useState(false);

  const handlePlay = () => {
    setPlay(true);
  };

  return (
    <div className="custom-input">
      <label>
        {label}
        <span>
          (1 MB)<span className="start-required">*</span>
        </span>
      </label>
      {typeof value === 'string' ? (
        <div className="image-thumbnail">
          <label>{heading}</label>
          {type === 'file' && <img src={value} alt="images" />}
          {type === 'fileVideo' && <div onClick={handlePlay}>
            <Player>
              <source src={value} />
              <div className={`play ${play && 'hide'}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 42.7 42.7"
                >
                  <path
                    className="a"
                    d="M21.741-50A21.369,21.369,0,0,0,.391-28.65,21.369,21.369,0,0,0,21.741-7.3a21.369,21.369,0,0,0,21.35-21.35A21.369,21.369,0,0,0,21.741-50Zm6.877,22.429-10.068,6.7a1.3,1.3,0,0,1-2.023-1.079V-35.347a1.3,1.3,0,0,1,2.023-1.079l10.068,6.7A1.3,1.3,0,0,1,28.618-27.571Z"
                    transform="translate(-0.391 50)"
                  ></path>
                </svg>
              </div>
            </Player>
          </div>    }

          
          <span className="thumbnail-close">
            <img src={close} alt="imageclose" onClick={(e) => onClickFile(e)} />
          </span>
        </div>
      ) : (
        <>
          <Form.File
            className={`${validated && value === null && 'required-file'}`}
            onChange={(e) => onChangeFile(e)}
          />
          {validated && value === null && (
            <span className="required-file-feedback">{feedback}</span>
          )}
        </>
      )}
    </div>
  );
};

export default FileInput;
