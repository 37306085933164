import {
  GET_CONTENT_BY_ID_REQUEST,
  GET_CONTENT_BY_ID_FAILURE,
  GET_CONTENT_BY_ID_SUCCESS
} from '../content-constants';

const getContentByIdRequest = (data) => ({
  data,
  type: GET_CONTENT_BY_ID_REQUEST
});

export const getContentByIdSuccess = (data) => ({
  data,
  type: GET_CONTENT_BY_ID_SUCCESS
});

export const getContentByIdFailure = () => ({
  type: GET_CONTENT_BY_ID_FAILURE
});

export const getContentById = async (data, dispatch) => {
  dispatch(getContentByIdRequest(data));
};
