import {
  GET_CONTENT_VIDEO_BY_ID_REQUEST,
  GET_CONTENT_VIDEO_BY_ID_FAILURE,
  GET_CONTENT_VIDEO_BY_ID_SUCCESS
} from '../content-constants';

const getContentVideoByIdRequest = (data) => ({
  data,
  type: GET_CONTENT_VIDEO_BY_ID_REQUEST
});

export const getContentVideoByIdSuccess = (data) => ({
  data,
  type: GET_CONTENT_VIDEO_BY_ID_SUCCESS
});

export const getContentVideoByIdFailure = () => ({
  type: GET_CONTENT_VIDEO_BY_ID_FAILURE
});

export const getContentVideoById = async (data, dispatch) => {
  dispatch(getContentVideoByIdRequest(data));
};
