import {
  UPDATE_SHOW_REEL_REQUEST,
  UPDATE_SHOW_REEL_FAILURE,
  UPDATE_SHOW_REEL_SUCCESS
} from '../showreel-constants';

const updateShowReelRequest = (data) => ({
  data,
  type: UPDATE_SHOW_REEL_REQUEST
});

export const updateShowReelSuccess = () => ({
  type: UPDATE_SHOW_REEL_SUCCESS
});

export const updateShowReelFailure = () => ({
  type: UPDATE_SHOW_REEL_FAILURE
});

export const updateShowReel = async (data, dispatch) => {
  dispatch(updateShowReelRequest(data));
};
