import {
  DELETE_CONTENT_VIDEO_REQUEST,
  DELETE_CONTENT_VIDEO_FAILURE,
  DELETE_CONTENT_VIDEO_SUCCESS
} from '../content-constants';

const deleteContentVideoRequest = (data) => ({
  data,
  type: DELETE_CONTENT_VIDEO_REQUEST
});

export const deleteContentVideoSuccess = () => ({
  type: DELETE_CONTENT_VIDEO_SUCCESS
});

export const deleteContentVideoFailure = () => ({
  type: DELETE_CONTENT_VIDEO_FAILURE
});

export const deleteContentVideo = async (data, dispatch) => {
  dispatch(deleteContentVideoRequest(data));
};
