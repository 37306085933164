import * as Toastr from 'toastr';

import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { awardUrl } from '../../../../utils/urls';
import { ADD_AWARD_REQUEST } from '../award-constants';
import { addAwardSuccess, addAwardFailure } from './create-award-action';

const { postRequest } = new HttpHelper();

export function* addAward({ data }) {
  try {
    const response = yield call(postRequest, {
      url: awardUrl(),
      data: data.formData
    });

    if (response.error) {
      yield put(addAwardFailure());
    } else if (response.data && response.data.status) {
      yield put(addAwardSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.awardSuccessCallback();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchAddAward() {
  yield takeLatest(ADD_AWARD_REQUEST, addAward);
}
