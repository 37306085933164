import React from 'react';

import { Player } from 'video-react';

const VideoPlayer = ({ url }) => {
  return (
    <Player>
      <source src={url} />
      <div className="play">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 42.7 42.7"
        >
          <path
            className="a"
            d="M21.741-50A21.369,21.369,0,0,0,.391-28.65,21.369,21.369,0,0,0,21.741-7.3a21.369,21.369,0,0,0,21.35-21.35A21.369,21.369,0,0,0,21.741-50Zm6.877,22.429-10.068,6.7a1.3,1.3,0,0,1-2.023-1.079V-35.347a1.3,1.3,0,0,1,2.023-1.079l10.068,6.7A1.3,1.3,0,0,1,28.618-27.571Z"
            transform="translate(-0.391 50)"
          ></path>
        </svg>
      </div>
    </Player>
  );
};

export default VideoPlayer;
