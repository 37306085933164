import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { addAward } from './create-award-action';

import Layout from '../../../../components/layout';
import FileInput from '../../../../components/form-fields/file-input';
import ButtonLoader from '../../../../assets/images/button-loader.svg';
import arrow from '../../../../assets/images/arrow.png';

const CreateAward = ({ history }) => {
  const [awardImage, setAwardImage] = useState(null);
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.addDirector);

  const awardSuccessCallback = () => {
    history.push('/awards');
  };

  const handleSubmitAward = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);

    if (awardImage) {
      let awardFormData = new FormData();
      awardFormData.append('image', awardImage);

      addAward(
        {
          formData: awardFormData,
          awardSuccessCallback
        },
        dispatch
      );
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSubmitAward(e);
    }
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <div className="form-container">
            <div className="back-arrow" onClick={() => history.push('/awards')}>
              <img src={arrow} alt="arrow" />
            </div>
            <div className="head">
              <h4>About Award Form </h4>
              <p>Enter your details below</p>
            </div>
            <Form onKeyDown={handleKeyDown} noValidate validated={validated}>
              <FileInput
                label="Upload Award Image"
                heading=""
                value={awardImage}
                onClickFile={() => {}}
                onChangeFile={(e) => setAwardImage(e.target.files[0])}
                feedback="Please upload award image."
                validated={validated}
              />
            </Form>
            <div className="bottom-btn">
              <Button
                className="black-btn white width"
                onClick={() => history.push('/awards')}
              >
                Cancel
              </Button>
              <Button className="black-btn width" onClick={handleSubmitAward}>
                {!isLoading ? (
                  'Submit'
                ) : (
                  <>
                    <img src={ButtonLoader} alt="button-loader" />
                    <span className="laoder-span">Loading....</span>
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateAward;
