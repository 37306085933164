import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { videoUrlByVideoId } from '../../../utils/urls';
import { DELETE_DIRECTOR_VIDEO_REQUEST } from '../director-constants';
import {
  deleteDirectorVideoSuccess,
  deleteDirectorVideoFailure
} from './delete-director-video-action';

const { deleteRequest } = new HttpHelper();

export function* deleteDirectorVideo({ data }) {
  try {
    const response = yield call(deleteRequest, {
      url: videoUrlByVideoId(data.id)
    });

    if (response.error) {
      yield put(deleteDirectorVideoFailure());
    } else {
      data.deleteSuccess();
      yield put(deleteDirectorVideoSuccess());
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchDeleteDirectorVideo() {
  yield takeLatest(DELETE_DIRECTOR_VIDEO_REQUEST, deleteDirectorVideo);
}
