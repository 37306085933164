import {
  GET_PORTFOLIO_REQUEST,
  GET_PORTFOLIO_FAILURE,
  GET_PORTFOLIO_SUCCESS,
  SORT_PORTFOLIO_REQUEST,
  SORT_PORTFOLIO_SUCCESS,
  SORT_PORTFOLIO_FAILURE
} from '../portfolio-constants';

const getPortfolioRequest = (data) => ({
  data,
  type: GET_PORTFOLIO_REQUEST
});

export const getPortfolioSuccess = (data) => ({
  data,
  type: GET_PORTFOLIO_SUCCESS
});

export const getPortfolioFailure = () => ({
  type: GET_PORTFOLIO_FAILURE
});

export const getPortfolio = async (data, dispatch) => {
  dispatch(getPortfolioRequest(data));
};


const sortPortfolioRequest = (data) => ({
  data,
  type: SORT_PORTFOLIO_REQUEST
});

export const sortPortfolioSuccess = (data) => ({
  data,
  type: SORT_PORTFOLIO_SUCCESS
});

export const sortPortfolioFailure = () => ({
  type: SORT_PORTFOLIO_FAILURE
});

export const sortPortfolio = async (data, dispatch) => {
  dispatch(sortPortfolioRequest(data));
};
