import {
  GET_DIRECTOR_VIDEO_BY_ID_REQUEST,
  GET_DIRECTOR_VIDEO_BY_ID_FAILURE,
  GET_DIRECTOR_VIDEO_BY_ID_SUCCESS
} from '../director-constants';

const getDirectorVideoByIdRequest = (data) => ({
  data,
  type: GET_DIRECTOR_VIDEO_BY_ID_REQUEST
});

export const getDirectorVideoByIdSuccess = (data) => ({
  data,
  type: GET_DIRECTOR_VIDEO_BY_ID_SUCCESS
});

export const getDirectorVideoByIdFailure = () => ({
  type: GET_DIRECTOR_VIDEO_BY_ID_FAILURE
});

export const getDirectorVideoById = async (data, dispatch) => {
  dispatch(getDirectorVideoByIdRequest(data));
};
