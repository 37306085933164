import {
  UPDATE_CONTENT_VIDEO_REQUEST,
  UPDATE_CONTENT_VIDEO_FAILURE,
  UPDATE_CONTENT_VIDEO_SUCCESS
} from '../content-constants';

const updateContentVideoRequest = (data) => ({
  data,
  type: UPDATE_CONTENT_VIDEO_REQUEST
});

export const updateContentVideoSuccess = () => ({
  type: UPDATE_CONTENT_VIDEO_SUCCESS
});

export const updateContentVideoFailure = () => ({
  type: UPDATE_CONTENT_VIDEO_FAILURE
});

export const updateContentVideo = async (data, dispatch) => {
  dispatch(updateContentVideoRequest(data));
};
