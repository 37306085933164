import {
  GET_TYPE_OF_CONTENTS_REQUEST,
  GET_TYPE_OF_CONTENTS_FAILURE,
  GET_TYPE_OF_CONTENTS_SUCCESS
} from './admin-helper-constants';

const initialState = {
  isLoading: false,
  typeOfContentsList: []
};

const getTypeOfContents = (state = initialState, action) => {
  switch (action.type) {
    case GET_TYPE_OF_CONTENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        typeOfContentsList: []
      };
    case GET_TYPE_OF_CONTENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        typeOfContentsList: []
      };
    case GET_TYPE_OF_CONTENTS_SUCCESS:
      const { data } = action.data;
      return {
        ...state,
        isLoading: false,
        typeOfContentsList: data
      };
    default:
      return state;
  }
};

export default getTypeOfContents;
