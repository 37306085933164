export const GET_AWARD_REQUEST = 'GET_AWARD_REQUEST';
export const GET_AWARD_SUCCESS = 'GET_AWARD_SUCCESS';
export const GET_AWARD_FAILURE = 'GET_AWARD_FAILURE';

export const ADD_AWARD_REQUEST = 'ADD_AWARD_REQUEST';
export const ADD_AWARD_SUCCESS = 'ADD_AWARD_SUCCESS';
export const ADD_AWARD_FAILURE = 'ADD_AWARD_FAILURE';

export const DELETE_AWARD_REQUEST = 'DELETE_AWARD_REQUEST';
export const DELETE_AWARD_SUCCESS = 'DELETE_AWARD_SUCCESS';
export const DELETE_AWARD_FAILURE = 'DELETE_AWARD_FAILURE';

export const SORT_AWARD_REQUEST = 'SORT_AWARD_REQUEST';
export const SORT_AWARD_SUCCESS = 'SORT_AWARD_SUCCESS';
export const SORT_AWARD_FAILURE = 'SORT_AWARD_FAILURE';
