import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRoutes from './private-routes';
import Login from '../containers/login';
import Dashboard from '../containers/dashboard';

import ShowreelList from '../containers/home/showreel/list';
import CreateShowReel from '../containers/home/showreel/create/create-showreel';
import UpdateShowReel from '../containers/home/showreel/update/update-showreel';

import Portfolio from '../containers/home/portfolio/list';
import SortPortfolio from '../containers/home/portfolio/list/sort';
import CreatePortfolio from '../containers/home/portfolio/create/create-portfolio';
import UpdatePortfolio from '../containers/home/portfolio/update/update-portfolio';

import Director from '../containers/director/list';
import CreateDirector from '../containers/director/create/create-director';
import UpdateDirector from '../containers/director/update/update-director';

import DirectorList from '../containers/director/list/director-list';
import CreateDirectorVideo from '../containers/director/create/create-director-video';
import UpdateDirectorVideo from '../containers/director/update/update-director-video';

import Content from '../containers/content/list';

import ContentList from '../containers/content/list/content-list';
import CreateContentVideo from '../containers/content/create/create-content-video';
import UpdateContentVideo from '../containers/content/update/update-content-video';

import Award from '../containers/about/award/list';
import CreateAward from '../containers/about/award/create/create-award';

import Press from '../containers/about/press/list';
import CreatePress from '../containers/about/press/create/create-press';
import UpdatePress from '../containers/about/press/update/update-press';

import Production from '../containers/about/production/list';
import CreateProduction from '../containers/about/production/create/create-production';
import UpdateProduction from '../containers/about/production/update/update-production';
import SortProduction from '../containers/about/production/list/sort';
import SortDirectorVideo from '../containers/director/list/director-video-sort';

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/signin" component={(props) => <Login {...props} />} />

      <PrivateRoutes
        exact
        path="/"
        component={(props) => <Dashboard {...props} />}
      />

      <PrivateRoutes
        exact
        path="/showreel"
        component={(props) => <ShowreelList {...props} />}
      />
      <PrivateRoutes
        exact
        path="/showreel/create"
        component={(props) => <CreateShowReel {...props} />}
      />
      <PrivateRoutes
        exact
        path="/showreel/:id/edit"
        component={(props) => <UpdateShowReel {...props} />}
      />

      <PrivateRoutes
        exact
        path="/portfolio"
        component={(props) => <Portfolio {...props} />}
      />
      <PrivateRoutes
        exact
        path="/portfolio/sort"
        component={(props) => <SortPortfolio {...props} />}
      />
      <PrivateRoutes
        exact
        path="/portfolio/create"
        component={(props) => <CreatePortfolio {...props} />}
      />
      <PrivateRoutes
        exact
        path="/portfolio/:id/edit"
        component={(props) => <UpdatePortfolio {...props} />}
      />

      {/* Director */}
      <PrivateRoutes
        exact
        path="/director"
        component={(props) => <Director {...props} />}
      />
      <PrivateRoutes
        exact
        path="/director/create"
        component={(props) => <CreateDirector {...props} />}
      />
      <PrivateRoutes
        exact
        path="/director/:id/edit"
        component={(props) => <UpdateDirector {...props} />}
      />
      <PrivateRoutes
        exact
        path="/director/:id/list"
        component={(props) => <DirectorList {...props} />}
      />
      <PrivateRoutes
        exact
        path="/director/:id/sort"
        component={(props) => <SortDirectorVideo {...props} />}
      />
      <PrivateRoutes
        exact
        path="/director/:id/video/create"
        component={(props) => <CreateDirectorVideo {...props} />}
      />
      <PrivateRoutes
        exact
        path="/director/:id/video/edit"
        component={(props) => <UpdateDirectorVideo {...props} />}
      />

      {/* Content */}
      <PrivateRoutes
        exact
        path="/content"
        component={(props) => <Content {...props} />}
      />

      <PrivateRoutes
        exact
        path="/content/:id/list"
        component={(props) => <ContentList {...props} />}
      />

      <PrivateRoutes
        exact
        path="/content/:id/video/create"
        component={(props) => <CreateContentVideo {...props} />}
      />
      <PrivateRoutes
        exact
        path="/content/:id/video/edit"
        component={(props) => <UpdateContentVideo {...props} />}
      />

      {/* About */}
      <PrivateRoutes
        exact
        path="/awards"
        component={(props) => <Award {...props} />}
      />
      <PrivateRoutes
        exact
        path="/award/create"
        component={(props) => <CreateAward {...props} />}
      />

      <PrivateRoutes
        exact
        path="/press"
        component={(props) => <Press {...props} />}
      />
      <PrivateRoutes
        exact
        path="/press/create"
        component={(props) => <CreatePress {...props} />}
      />
      <PrivateRoutes
        exact
        path="/press/:id/edit"
        component={(props) => <UpdatePress {...props} />}
      />

      <PrivateRoutes
        exact
        path="/production"
        component={(props) => <Production {...props} />}
      />
      <PrivateRoutes
        exact
        path="/production/create"
        component={(props) => <CreateProduction {...props} />}
      />
      <PrivateRoutes
        exact
        path="/production/:id/edit"
        component={(props) => <UpdateProduction {...props} />}
      />
      <PrivateRoutes
        exact
        path="/production/sort"
        component={(props) => <SortProduction {...props} />}
      />
    </Switch>
  </Router>
);

export default Routes;
