import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { FILM_NAMES_URL } from '../../utils/urls';
import { GET_FILM_NAMES_REQUEST } from './admin-helper-constants';
import { getFilmNamesSuccess, getFilmNamesFailure } from './film-names-action';

const { getRequest } = new HttpHelper();

export function* getFilmNames() {
  try {
    const response = yield call(getRequest, {
      url: FILM_NAMES_URL
    });

    if (response.error) {
      yield put(getFilmNamesFailure());
    } else {
      yield put(getFilmNamesSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetFilmNames() {
  yield takeLatest(GET_FILM_NAMES_REQUEST, getFilmNames);
}
