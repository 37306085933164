import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'react-bootstrap';

import { getProduction } from './production-action';
import { deleteProduction } from '../delete/delete-production-action';

import Layout from '../../../../components/layout';
import PaginationList from '../../../../components/pagination';
import DeletePopup from '../../../../components/modal/delete-popup';
import VideoPlayer from '../../../../components/video-player';

import plus from '../../../../assets/images/plus.png';

const Production = ({ history }) => {
  const [productionId, setProductionId] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();

  const getProductionDetails = (currentPageNo, pageSize) => {
    getProduction({ pageNumber: currentPageNo, pageSize: pageSize }, dispatch);
  };

  useEffect(() => {
    getProductionDetails(currentPageNo, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { productionList, isLoading, totalRecords } = useSelector(
    (state) => state.getProduction
  );

  const deleteProductionSuccess = () => {
    setShowDeletePopup(false);
    getProductionDetails(currentPageNo, pageSize);
  };

  const handelDelete = () => {
    deleteProduction(
      { id: productionId, deleteSuccess: deleteProductionSuccess },
      dispatch
    );
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          {!isLoading ? (
            <div className="video-list-container">
              <div className="video-list-head">
                <div>
                  <h2>Production List</h2>
                </div>
                <div>
                  <Button
                    className="black-btn halfwidth"
                    onClick={() => history.push('/production/sort')}
                  >
                    Reorder
                  </Button>
                  <Button
                    className="black-btn halfwidth m-l-20"
                    onClick={() => history.push('/production/create')}
                  >
                    Add New
                    <span>
                      <img src={plus} alt="plus" />
                    </span>
                  </Button>
                </div>
              </div>
              {productionList &&
                productionList.map((production) => (
                  <div className="video-card customborder" key={production.id}>
                    <div className="video-card-btn director-head">
                      <h2>{production.name}</h2>
                      <div className="btn-section">
                        <Button
                          className="black-btn transparent"
                          onClick={() =>
                            history.push(`/production/${production.id}/edit`)
                          }
                        >
                          Edit
                        </Button>
                        <span>|</span>

                        <Button
                          className="black-btn transparent red"
                          onClick={() => {
                            setShowDeletePopup(true);
                            setProductionId(production.id);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                    <div className="video-card-section">
                      <div className="video-list-img">
                        <label>Thumbnail Image</label>
                        <img src={production.thumbnail_image} alt="img" />
                      </div>
                      <div className="video">
                        <label>Thumbnail Video</label>
                        <VideoPlayer url={production.thumbnail_video} />
                      </div>
                      <div className="video">
                        <label>Full Video</label>
                        <VideoPlayer url={production.video} />
                      </div>
                    </div>
                    <div className="film">
                      <div className="video-card-text">
                        <div className="video-card-text-list">
                          <h2>Film Name</h2>
                          <h3>{production.type_of_content}</h3>
                        </div>
                        <div className="video-card-text-list">
                          <h2>Production House Name</h2>
                          <h3>{production.name}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <p style={{ color: '#000' }}>Loading...</p>
          )}
        </div>
      </div>
      <PaginationList
        currentPageNo={currentPageNo}
        setCurrentPageNo={setCurrentPageNo}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalRecords={totalRecords}
        getCurentPageData={getProductionDetails}
      />
      <DeletePopup
        show={showDeletePopup}
        handleClose={() => setShowDeletePopup(false)}
        handelDelete={handelDelete}
      />
    </Layout>
  );
};

export default Production;
