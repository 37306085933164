import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../../../components/layout';
import PortfolioForm from '../portfolio-form';
import { addPortfolio } from './create-portfolio-action';

const CreatePortfolio = ({ history }) => {
  const [addPortfolioDetails, setAddPortfolioDetails] = useState({
    thumbnailImage: null,
    thumbnailVideo: null,
    videoUrl: '',
    category: '',
    filmName: '',
    directorName: '',
    brandName: '',
    typeOfContent: '',
    agencyName: '',
    coProducerName: '',
    awards: ''
  });
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.addPortfolio);

  const portfolioSuccessCallback = () => {
    history.push('/portfolio');
  };

  const handleCreatePortfolio = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const {
      thumbnailImage,
      thumbnailVideo,
      videoUrl,
      category,
      filmName,
      directorName,
      brandName,
      typeOfContent,
      agencyName,
      coProducerName,
      awards
    } = addPortfolioDetails;
    if (thumbnailImage && thumbnailVideo && videoUrl && category && filmName && directorName) {
      let portfolioFormData = new FormData();
      portfolioFormData.append('thumbnail_image', thumbnailImage);
      portfolioFormData.append('thumbnail_video', thumbnailVideo);
      portfolioFormData.append('video', videoUrl);
      portfolioFormData.append('category', category);
      portfolioFormData.append('film_name', filmName);
      portfolioFormData.append('agency', agencyName);
      portfolioFormData.append('co_producer', coProducerName);
      portfolioFormData.append('awards', awards);
      portfolioFormData.append('director_name', directorName);

      if (category === 'Ad Film' && brandName) {
        portfolioFormData.append('brand_name', brandName);
        addPortfolio(
          {
            formData: portfolioFormData,
            portfolioSuccessCallback
          },
          dispatch
        );
      } else if (category === 'Content Film' && typeOfContent) {
        portfolioFormData.append('type_of_content', typeOfContent);
        addPortfolio(
          {
            formData: portfolioFormData,
            portfolioSuccessCallback
          },
          dispatch
        );
      }
    }
  };

  const handleCancel = () => {
    history.push('/portfolio');
  };
  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <PortfolioForm
            portfolioDetails={addPortfolioDetails}
            setPortfolioDetails={setAddPortfolioDetails}
            validated={validated}
            handleCancel={handleCancel}
            handleSubmitPortfolio={handleCreatePortfolio}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default CreatePortfolio;
