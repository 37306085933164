import {
  GET_CONTENT_VIDEO_REQUEST,
  GET_CONTENT_VIDEO_FAILURE,
  GET_CONTENT_VIDEO_SUCCESS
} from '../content-constants';

const getContentVideoRequest = (data) => ({
  data,
  type: GET_CONTENT_VIDEO_REQUEST
});

export const getContentVideoSuccess = (data) => ({
  data,
  type: GET_CONTENT_VIDEO_SUCCESS
});

export const getContentVideoFailure = () => ({
  type: GET_CONTENT_VIDEO_FAILURE
});

export const getContentVideo = async (data, dispatch) => {
  dispatch(getContentVideoRequest(data));
};
