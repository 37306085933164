import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { contentVideoUrlByVideoId } from '../../../utils/urls';
import { GET_CONTENT_VIDEO_BY_ID_REQUEST } from '../content-constants';
import {
  getContentVideoByIdSuccess,
  getContentVideoByIdFailure
} from './get-content-video-action';

const { getRequest } = new HttpHelper();

export function* getContentVideoById({ data }) {
  try {
    const response = yield call(getRequest, {
      url: contentVideoUrlByVideoId(data.id)
    });

    if (response.error) {
      yield put(getContentVideoByIdFailure());
    } else {
      yield put(getContentVideoByIdSuccess(response.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetContentVideoById() {
  yield takeLatest(GET_CONTENT_VIDEO_BY_ID_REQUEST, getContentVideoById);
}
