import {
  UPDATE_DIRECTOR_VIDEO_REQUEST,
  UPDATE_DIRECTOR_VIDEO_FAILURE,
  UPDATE_DIRECTOR_VIDEO_SUCCESS
} from '../director-constants';

const updateDirectorVideoRequest = (data) => ({
  data,
  type: UPDATE_DIRECTOR_VIDEO_REQUEST
});

export const updateDirectorVideoSuccess = () => ({
  type: UPDATE_DIRECTOR_VIDEO_SUCCESS
});

export const updateDirectorVideoFailure = () => ({
  type: UPDATE_DIRECTOR_VIDEO_FAILURE
});

export const updateDirectorVideo = async (data, dispatch) => {
  dispatch(updateDirectorVideoRequest(data));
};
