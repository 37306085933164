import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CountUp from 'react-countup';

import Layout from '../../components/layout';
import { getDashboard } from './dashboard-action';

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getDashboard(dispatch);
  }, [dispatch]);

  const { dashboardList, isLoading } = useSelector(
    (state) => state.getDashboard
  );

  const getResourceUrl = (resourceName) => {
    switch (resourceName) {
      case 'Director':
        return '/director';
      case 'Content':
        return '/content';
      case 'Awards':
        return '/awards';
      case 'Production House':
        return '/production';
      default:
        return '/';
    }
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <div className="video-list-container">
            <div className="video-list-head">
              {!isLoading ? (
                <div className="dashboard-container">
                  <h4 style={{ textAlign: 'center' }}>
                    Welcome To Early Man Admin Portal
                  </h4>
                  <div className="container pt-5">
                    <div className="row">
                      {dashboardList &&
                        dashboardList.map((el, index) => (
                          <div className="c-dashboardInfo col-md-4" key={index}>
                            <Link to={getResourceUrl(el.title)}>
                              <div className="wrap">
                                <h4 className="c-dashboardInfo__title">
                                  {el.title}
                                </h4>
                                <span className="c-dashboardInfo__count">
                                  <CountUp end={el.count} />
                                </span>
                              </div>
                            </Link>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ) : (
                <p style={{ color: '#000' }}>Loading...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
