import {
  DELETE_PORTFOLIO_REQUEST,
  DELETE_PORTFOLIO_FAILURE,
  DELETE_PORTFOLIO_SUCCESS
} from '../portfolio-constants';

const deletePortfolioRequest = (data) => ({
  data,
  type: DELETE_PORTFOLIO_REQUEST
});

export const deletePortfolioSuccess = () => ({
  type: DELETE_PORTFOLIO_SUCCESS
});

export const deletePortfolioFailure = () => ({
  type: DELETE_PORTFOLIO_FAILURE
});

export const deletePortfolio = async (data, dispatch) => {
  dispatch(deletePortfolioRequest(data));
};
