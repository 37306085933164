import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../../../components/layout';
import ProductionForm from '../production-form';
import { updateProduction } from './update-production-action';
import { getProductionById } from '../list/get-production-action';

const UpdateProduction = ({ match, history }) => {
  const [updateProductionDetails, setUpdateProductionDetails] = useState({
    thumbnailImage: null,
    thumbnailVideo: null,
    video: '',
    name: '',
    filmName: '',
    website: '',
    brandName: '',
    directorName: ''
  });
  const [noData, setnoData] = useState(false);
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.updateProduction);

  useEffect(() => {
    if (match.params.id) {
      getProductionById({ id: match.params.id }, dispatch);
    }
  }, [match.params, dispatch]);

  const { productionData, isEditProductionLoading } = useSelector(
    (state) => state.getProductionById
  );

  useEffect(() => {
    if (Object.keys(productionData).length) {
      setUpdateProductionDetails({
        thumbnailImage: productionData.thumbnail_image || null,
        thumbnailVideo: productionData.thumbnail_video || null,
        video: productionData.video || '',
        name: productionData.name || '',
        website: productionData.website || '',
        filmName: productionData.type_of_content || '',
        brandName: productionData.brand_name || '',
        directorName: productionData.director_name || ''
      });
      setnoData(false);
    } else {
      setnoData(true);
    }
  }, [productionData]);

  const productionSuccess = () => {
    history.push('/production');
  };

  const handleUpdateProduction = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const {
      thumbnailImage,
      thumbnailVideo,
      video,
      name,
      website,
      filmName,
      brandName,
      directorName
    } = updateProductionDetails;

    if (
      thumbnailImage &&
      thumbnailVideo &&
      video &&
      name &&
      website &&
      filmName &&
      directorName
    ) {
      let productionFormData = new FormData();

      productionFormData.append('video', video);
      productionFormData.append('name', name);
      productionFormData.append('website', website);
      productionFormData.append('type_of_content', filmName);
      productionFormData.append('brand_name', brandName);
      productionFormData.append('director_name', directorName);

      if (thumbnailImage && typeof thumbnailImage === 'object') {
        productionFormData.append('thumbnail_image', thumbnailImage);
      }

      if (thumbnailVideo && typeof thumbnailVideo === 'object') {
        productionFormData.append('thumbnail_video', thumbnailVideo);
      }

      updateProduction(
        {
          formData: productionFormData,
          id: match.params.id,
          updateSuccess: productionSuccess
        },
        dispatch
      );
    }
  };

  const handleCancel = () => {
    history.push('/production');
  };
  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <ProductionForm
            productionDetails={updateProductionDetails}
            setProductionDetails={setUpdateProductionDetails}
            validated={validated}
            handleCancel={handleCancel}
            handleSubmitProduction={handleUpdateProduction}
            noData={noData}
            isLoading={isLoading}
            isEditProductionLoading={isEditProductionLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default UpdateProduction;
