import {
  GET_PORTFOLIO_BY_ID_REQUEST,
  GET_PORTFOLIO_BY_ID_FAILURE,
  GET_PORTFOLIO_BY_ID_SUCCESS
} from '../portfolio-constants';

const initialState = {
  isEditPortfolioLoading: false,
  portfolioData: {}
};

const getPortfolioById = (state = initialState, action) => {
  switch (action.type) {
    case GET_PORTFOLIO_BY_ID_REQUEST:
      return {
        ...state,
        isEditPortfolioLoading: true,
        portfolioData: {}
      };
    case GET_PORTFOLIO_BY_ID_FAILURE:
      return {
        ...state,
        isEditPortfolioLoading: false,
        portfolioData: {}
      };
    case GET_PORTFOLIO_BY_ID_SUCCESS:
      return {
        ...state,
        isEditPortfolioLoading: false,
        portfolioData: action.data
      };
    default:
      return state;
  }
};

export default getPortfolioById;
