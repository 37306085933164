import {
  GET_DIRECTOR_VIDEO_REQUEST,
  GET_DIRECTOR_VIDEO_FAILURE,
  GET_DIRECTOR_VIDEO_SUCCESS,
  SORT_DIRECTOR_VIDEO_REQUEST,
  SORT_DIRECTOR_VIDEO_FAILURE,
  SORT_DIRECTOR_VIDEO_SUCCESS
} from '../director-constants';

const initialState = {
  isLoading: false,
  directorVideoList: []
};

const getDirectorVideo = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIRECTOR_VIDEO_REQUEST:
      return {
        ...state,
        isLoading: true,
        directorVideoList: []
      };
    case GET_DIRECTOR_VIDEO_FAILURE:
      return {
        ...state,
        isLoading: false,
        directorVideoList: []
      };
    case GET_DIRECTOR_VIDEO_SUCCESS:
      const { data, totalRecords } = action.data;
      return {
        ...state,
        isLoading: false,
        directorVideoList: data,
        totalRecords
      };
    case SORT_DIRECTOR_VIDEO_REQUEST:
      const sortedList = [...action.data.data];
      return {
        ...state,
        isSorting: true,
        directorVideoList: sortedList
      };
    case SORT_DIRECTOR_VIDEO_FAILURE:
    case SORT_DIRECTOR_VIDEO_SUCCESS:
      return {
        ...state,
        isSorting: false
      };  
    default:
      return state;
  }
};

export default getDirectorVideo;
