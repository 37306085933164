import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { contentUrlWithId } from '../../../utils/urls';
import { GET_CONTENT_BY_ID_REQUEST } from '../content-constants';
import {
  getContentByIdSuccess,
  getContentByIdFailure
} from './get-content-action';

const { getRequest } = new HttpHelper();

export function* getContentById({ data }) {
  try {
    const response = yield call(getRequest, {
      url: contentUrlWithId(data.id)
    });

    if (response.error) {
      yield put(getContentByIdFailure());
    } else {
      yield put(getContentByIdSuccess(response.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetContentById() {
  yield takeLatest(GET_CONTENT_BY_ID_REQUEST, getContentById);
}
