import {
  GET_SHOW_REEL_REQUEST,
  GET_SHOW_REEL_FAILURE,
  GET_SHOW_REEL_SUCCESS
} from '../showreel-constants';

const getShowReelRequest = (data) => ({
  data,
  type: GET_SHOW_REEL_REQUEST
});

export const getShowReelSuccess = (data) => ({
  data,
  type: GET_SHOW_REEL_SUCCESS
});

export const getShowReelFailure = () => ({
  type: GET_SHOW_REEL_FAILURE
});

export const getShowReel = async (data, dispatch) => {
  dispatch(getShowReelRequest(data));
};
