import {
  GET_PORTFOLIO_BY_ID_REQUEST,
  GET_PORTFOLIO_BY_ID_FAILURE,
  GET_PORTFOLIO_BY_ID_SUCCESS
} from '../portfolio-constants';

const getPortfolioByIdRequest = (data) => ({
  data,
  type: GET_PORTFOLIO_BY_ID_REQUEST
});

export const getPortfolioByIdSuccess = (data) => ({
  data,
  type: GET_PORTFOLIO_BY_ID_SUCCESS
});

export const getPortfolioByIdFailure = () => ({
  type: GET_PORTFOLIO_BY_ID_FAILURE
});

export const getPortfolioById = async (data, dispatch) => {
  dispatch(getPortfolioByIdRequest(data));
};
