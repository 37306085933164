import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { pressUrl } from '../../../../utils/urls';
import { GET_PRESS_REQUEST } from '../press-constants';
import { getPressSuccess, getPressFailure } from './press-action';

const { getRequest } = new HttpHelper();

export function* getPress({ data }) {
  try {
    const response = yield call(getRequest, {
      url: pressUrl(data)
    });

    if (response.error) {
      yield put(getPressFailure());
    } else {
      yield put(getPressSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetPress() {
  yield takeLatest(GET_PRESS_REQUEST, getPress);
}
