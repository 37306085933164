import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { TYPE_OF_CONTENTS_URL } from '../../utils/urls';
import { GET_TYPE_OF_CONTENTS_REQUEST } from './admin-helper-constants';
import {
  getTypeOfContentsSuccess,
  getTypeOfContentsFailure
} from './type-of-contents-action';

const { getRequest } = new HttpHelper();

export function* getTypeOfContents() {
  try {
    const response = yield call(getRequest, {
      url: TYPE_OF_CONTENTS_URL
    });

    if (response.error) {
      yield put(getTypeOfContentsFailure());
    } else {
      yield put(getTypeOfContentsSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetTypeOfContents() {
  yield takeLatest(GET_TYPE_OF_CONTENTS_REQUEST, getTypeOfContents);
}
