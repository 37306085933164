import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { directorUrlWithId } from '../../../utils/urls';
import { DELETE_DIRECTOR_REQUEST } from '../director-constants';
import {
  deleteDirectorSuccess,
  deleteDirectorFailure
} from './delete-director-action';

const { deleteRequest } = new HttpHelper();

export function* deleteDirector({ data }) {
  try {
    const response = yield call(deleteRequest, {
      url: directorUrlWithId(data.id)
    });

    if (response.error) {
      yield put(deleteDirectorFailure());
    } else {
      data.deleteSuccess();
      yield put(deleteDirectorSuccess());
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchDeleteDirector() {
  yield takeLatest(DELETE_DIRECTOR_REQUEST, deleteDirector);
}
