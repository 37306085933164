import * as Toastr from 'toastr';

import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { portfolioUrlWithId } from '../../../../utils/urls';
import { UPDATE_PORTFOLIO_REQUEST } from '../portfolio-constants';
import {
  updatePortfolioSuccess,
  updatePortfolioFailure
} from './update-portfolio-action';

const { patchRequest } = new HttpHelper();

export function* updatePortfolio({ data }) {
  try {
    const response = yield call(patchRequest, {
      url: portfolioUrlWithId(data.id),
      data: data.formData
    });

    if (response.error) {
      yield put(updatePortfolioFailure());
    } else if (response.data && response.data.status) {
      yield put(updatePortfolioSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.updateSuccess();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchUpdatePortfolio() {
  yield takeLatest(UPDATE_PORTFOLIO_REQUEST, updatePortfolio);
}
