export const GET_PORTFOLIO_REQUEST = 'GET_PORTFOLIO_REQUEST';
export const GET_PORTFOLIO_SUCCESS = 'GET_PORTFOLIO_SUCCESS';
export const GET_PORTFOLIO_FAILURE = 'GET_PORTFOLIO_FAILURE';

export const GET_PORTFOLIO_BY_ID_REQUEST = 'GET_PORTFOLIO_BY_ID_REQUEST';
export const GET_PORTFOLIO_BY_ID_SUCCESS = 'GET_PORTFOLIO_BY_ID_SUCCESS';
export const GET_PORTFOLIO_BY_ID_FAILURE = 'GET_PORTFOLIO_BY_ID_FAILURE';

export const ADD_PORTFOLIO_REQUEST = 'ADD_PORTFOLIO_REQUEST';
export const ADD_PORTFOLIO_SUCCESS = 'ADD_PORTFOLIO_SUCCESS';
export const ADD_PORTFOLIO_FAILURE = 'ADD_PORTFOLIO_FAILURE';

export const UPDATE_PORTFOLIO_REQUEST = 'UPDATE_PORTFOLIO_REQUEST';
export const UPDATE_PORTFOLIO_SUCCESS = 'UPDATE_PORTFOLIO_SUCCESS';
export const UPDATE_PORTFOLIO_FAILURE = 'UPDATE_PORTFOLIO_FAILURE';

export const DELETE_PORTFOLIO_REQUEST = 'DELETE_PORTFOLIO_REQUEST';
export const DELETE_PORTFOLIO_SUCCESS = 'DELETE_PORTFOLIO_SUCCESS';
export const DELETE_PORTFOLIO_FAILURE = 'DELETE_PORTFOLIO_FAILURE';

export const SORT_PORTFOLIO_REQUEST = 'SORT_PORTFOLIO_REQUEST';
export const SORT_PORTFOLIO_SUCCESS = 'SORT_PORTFOLIO_SUCCESS';
export const SORT_PORTFOLIO_FAILURE = 'SORT_PORTFOLIO_FAILURE';
