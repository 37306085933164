import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import arrow from '../../assets/images/arrow-right1.png';
import arrow1 from '../../assets/images/arrow-right2.png';

const Sidebar = () => {
  const [show, toggleShow] = React.useState(false);
  const [show3, toggleShow3] = React.useState(false);

  const [active, setActive] = useState('');

  useEffect(() => {
    setActive(window.location.pathname);
    if (window.location.pathname === '/' || window.location.pathname === '/') {
    }
  }, []);

  useEffect(() => {
    if (active.includes('/showreel') || active.includes('/portfolio')) {
      toggleShow(true);
    }

    if (
      active.includes('/awards') ||
      active.includes('/press') ||
      active.includes('/production')
    ) {
      toggleShow3(true);
    }
  }, [active]);

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={logo} alt="logo" />
      </div>
      <ul>
        <li>
          <div className="head" onClick={() => toggleShow(!show)}>
            <label>Home</label>
            {!show && (
              <span>
                <img src={arrow} alt="arrow" />
              </span>
            )}
            {show && (
              <span>
                <img src={arrow1} alt="arrow" />
              </span>
            )}
            <div className="hide">{active}</div>
          </div>
          {show && (
            <div className="head-list">
              <Link
                to="/showreel"
                className={`${active.includes('/showreel') && 'active'}`}
              >
                Showreel Video
              </Link>
              <Link
                to="/portfolio"
                className={`${active.includes('/portfolio') && 'active'}`}
              >
                Portfolio Video
              </Link>
            </div>
          )}
        </li>
        <li>
          <Link to="/director" style={{ display: 'block' }} className="head">
            <label>Director</label>
            <span>
              <img src={arrow} alt="arrow" />
            </span>
          </Link>
        </li>
        <li>
          <Link to="/content" className="head" style={{ display: 'block' }}>
            <label>Content</label>
            <span>
              <img src={arrow} alt="arrow" />
            </span>
          </Link>
        </li>
        <li>
          <div className="head" onClick={() => toggleShow3(!show3)}>
            <label>About</label>
            {!show3 && (
              <span>
                <img src={arrow} alt="arrow" />
              </span>
            )}
            {show3 && (
              <span>
                <img src={arrow1} alt="arrow" />
              </span>
            )}
          </div>
          {show3 && (
            <div className="head-list">
              <Link
                to="/awards"
                className={`${active.includes('/awards') && 'active'}`}
              >
                Awards
              </Link>
              <Link
                to="/press"
                className={`${active.includes('/press') && 'active'}`}
              >
                Press
              </Link>
              <Link
                to="/production"
                className={`${active.includes('/production') && 'active'}`}
              >
                Production
              </Link>
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
