import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { DIRECTOR_NAMES_URL } from '../../utils/urls';
import { GET_DIRECTOR_NAMES_REQUEST } from './admin-helper-constants';
import {
  getDirectorNamesSuccess,
  getDirectorNamesFailure
} from './director-names-action';

const { getRequest } = new HttpHelper();

export function* getDirectorNames() {
  try {
    const response = yield call(getRequest, {
      url: DIRECTOR_NAMES_URL
    });

    if (response.error) {
      yield put(getDirectorNamesFailure());
    } else {
      yield put(getDirectorNamesSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetDirectorNames() {
  yield takeLatest(GET_DIRECTOR_NAMES_REQUEST, getDirectorNames);
}
