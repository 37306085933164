import * as Toastr from 'toastr';

import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { portfolioUrl } from '../../../../utils/urls';
import { ADD_PORTFOLIO_REQUEST } from '../portfolio-constants';
import {
  addPortfolioSuccess,
  addPortfolioFailure
} from './create-portfolio-action';

const { postRequest } = new HttpHelper();

export function* addPortfolio({ data }) {
  try {
    const response = yield call(postRequest, {
      url: portfolioUrl(),
      data: data.formData
    });

    if (response.error) {
      yield put(addPortfolioFailure());
    } else if (response.data && response.data.status) {
      yield put(addPortfolioSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.portfolioSuccessCallback();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchAddPortfolio() {
  yield takeLatest(ADD_PORTFOLIO_REQUEST, addPortfolio);
}
