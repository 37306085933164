import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../../components/layout';
import DirectorForm from '../director-form';
import { getDirectorById } from '../list/get-director-action';
import { updateDirector } from './update-director-action';

const UpdateDirector = ({ history, match }) => {
  const [updateDirectorDetails, setUpdateDirectorDetails] = useState({
    name: '',
    bio: '',
    profilePic: null,
    reelVideo: ''
  });
  const [noData, setnoData] = useState(false);
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.updateDirector);

  useEffect(() => {
    if (match.params.id) {
      getDirectorById({ id: match.params.id }, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params, dispatch]);

  const { directorData, isEditDirectorLoading } = useSelector(
    (state) => state.getDirectorById
  );

  useEffect(() => {
    if (Object.keys(directorData).length) {
      setUpdateDirectorDetails({
        name: directorData.name || '',
        bio: directorData.bio || '',
        profilePic: directorData.profile_pic || null,
        reelVideo: directorData.reel_video || ''
      });
      setnoData(false);
    } else {
      setnoData(true);
    }
  }, [directorData]);

  const directorSuccessCallback = () => {
    history.push('/director');
  };

  const handleUpdateDirector = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const { name, bio, profilePic, reelVideo } = updateDirectorDetails;

    if (name && bio && profilePic && reelVideo) {
      let directorFormData = new FormData();
      directorFormData.append('name', name);
      directorFormData.append('bio', bio);
      directorFormData.append('reel_video', reelVideo);

      if (profilePic && typeof profilePic === 'object') {
        directorFormData.append('profile_pic', profilePic);
      }

      updateDirector(
        {
          id: match.params.id,
          formData: directorFormData,
          directorSuccessCallback
        },
        dispatch
      );
    }
  };

  const handleCancel = () => {
    history.push('/director');
  };
  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <DirectorForm
            directorDetails={updateDirectorDetails}
            setDirectorDetails={setUpdateDirectorDetails}
            validated={validated}
            handleCancel={handleCancel}
            handleSubmitDirector={handleUpdateDirector}
            noData={noData}
            isLoading={isLoading}
            isEditDirectorLoading={isEditDirectorLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default UpdateDirector;
