import {
  ADD_CONTENT_VIDEO_REQUEST,
  ADD_CONTENT_VIDEO_FAILURE,
  ADD_CONTENT_VIDEO_SUCCESS
} from '../content-constants';

const initialState = {
  isLoading: false
};

const addContentVideo = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONTENT_VIDEO_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ADD_CONTENT_VIDEO_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case ADD_CONTENT_VIDEO_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default addContentVideo;
