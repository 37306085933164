import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { getContentVideoById } from '../list/get-content-video-action';

import Layout from '../../../components/layout';
import ContentVideoForm from '../content-video-form';
import { updateContentVideo } from './update-content-video-action';

const UpdateContentVideo = ({ history, match }) => {
  const [updateContentVideoDetails, setUpdateContentVideoDetails] = useState({
    thumbnailImage: null,
    thumbnailVideo: null,
    video: '',
    filmName: '',
    directorName: '',
    coProducerName: '',
    awards: []
  });

  const [validated, setValidated] = useState(false);
  const [noData, setnoData] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.updateContentVideo);

  useEffect(() => {
    if (match.params.id) {
      getContentVideoById({ id: match.params.id }, dispatch);
    }
  }, [match.params, dispatch]);

  const { contentVideoData, isEditContentVideoLoading } = useSelector(
    (state) => state.getContentVideoById
  );

  useEffect(() => {
    if (Object.keys(contentVideoData).length) {
      setUpdateContentVideoDetails({
        thumbnailImage: contentVideoData.thumbnail_image || null,
        thumbnailVideo: contentVideoData.thumbnail_video || null,
        video: contentVideoData.video || '',
        filmName: contentVideoData.film_name || '',
        directorName: contentVideoData.director_name || '',
        coProducerName: contentVideoData.co_producer || '',
        awards: contentVideoData.awards ? contentVideoData.awards.split(',') : []
      });
      setnoData(false);
    } else {
      setnoData(true);
    }
  }, [contentVideoData]);

  const contentVideoSuccessCallback = () => {
    history.push(`/content/${contentVideoData.content_id}/list`);
  };

  const handleUpdateContentVideo = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const {
      thumbnailImage,
      thumbnailVideo,
      video,
      filmName,
      directorName,
      coProducerName,
      awards
    } = updateContentVideoDetails;

    if (thumbnailImage && thumbnailVideo && video && filmName && directorName) {
      let contentVideoFormData = new FormData();      
      contentVideoFormData.append('video', video);
      contentVideoFormData.append('film_name', filmName);
      contentVideoFormData.append('director_name', directorName);
      contentVideoFormData.append('co_producer', coProducerName);
      contentVideoFormData.append('awards', awards);

      if (thumbnailImage && typeof thumbnailImage === 'object') {
        contentVideoFormData.append('thumbnail_image', thumbnailImage);
      }

      if (thumbnailVideo && typeof thumbnailVideo === 'object') {
        contentVideoFormData.append('thumbnail_video', thumbnailVideo);
      }

      updateContentVideo(
        {
          id: match.params.id,
          formData: contentVideoFormData,
          updateSuccess: contentVideoSuccessCallback
        },
        dispatch
      );
    }
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <ContentVideoForm
            contentVideoDetails={updateContentVideoDetails}
            setContentVideoDetails={setUpdateContentVideoDetails}
            validated={validated}
            handleCancel={() =>
              history.push(`/content/${contentVideoData.content_id}/list`)
            }
            handleSubmitContentVideo={handleUpdateContentVideo}
            noData={noData}
            isLoading={isLoading}
            isEditContentVideoLoading={isEditContentVideoLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default UpdateContentVideo;
