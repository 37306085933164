import {
  GET_DIRECTOR_BY_ID_REQUEST,
  GET_DIRECTOR_BY_ID_FAILURE,
  GET_DIRECTOR_BY_ID_SUCCESS
} from '../director-constants';

const initialState = {
  isEditDirectorLoading: false,
  directorData: {}
};

const getDirectorById = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIRECTOR_BY_ID_REQUEST:
      return {
        ...state,
        isEditDirectorLoading: true,
        directorData: {}
      };
    case GET_DIRECTOR_BY_ID_FAILURE:
      return {
        ...state,
        isEditDirectorLoading: false,
        directorData: {}
      };
    case GET_DIRECTOR_BY_ID_SUCCESS:
      return {
        ...state,
        isEditDirectorLoading: false,
        directorData: action.data
      };
    default:
      return state;
  }
};

export default getDirectorById;
