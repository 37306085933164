import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { portfolioUrlWithId } from '../../../../utils/urls';
import { DELETE_PORTFOLIO_REQUEST } from '../portfolio-constants';
import {
  deletePortfolioSuccess,
  deletePortfolioFailure
} from './delete-portfolio-action';

const { deleteRequest } = new HttpHelper();

export function* deletePortfolio({ data }) {
  try {
    const response = yield call(deleteRequest, {
      url: portfolioUrlWithId(data.id)
    });

    if (response.error) {
      yield put(deletePortfolioFailure());
    } else {
      data.deleteSuccess();
      yield put(deletePortfolioSuccess());
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchDeletePortfolio() {
  yield takeLatest(DELETE_PORTFOLIO_REQUEST, deletePortfolio);
}
