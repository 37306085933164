import React from 'react';

import { Modal, Form, Button } from 'react-bootstrap';

import TextInput from '../../components/form-fields/text-input';
import close from '../../assets/images/close1.png';

const AddContentPopup = ({
  show,
  handleClose,
  handleKeyDown,
  validated,
  isEdit,
  contentName,
  setContentName,
  handleCreateContent,
  handleUpdateContent
}) => {
  return (
    <Modal show={show} animation={false}>
      <div className="close-icon" onClick={handleClose}>
        <img src={close} alt="text"></img>
      </div>
      <Modal.Body>
        <div className="add-director">
          <Form onKeyDown={handleKeyDown} noValidate validated={validated}>
            <div className="add-director-content">
              <h2>{!isEdit ? 'Add Content' : 'Update Content'}</h2>
              <TextInput
                label="Enter Name of Content"
                placeholder="Enter content name"
                value={contentName}
                changeText={(e) => setContentName(e.target.value)}
                feedback="Please enter content name."
              />
              {!isEdit ? (
                <div className="del-button">
                  <Button className="black-btn" onClick={handleCreateContent}>
                    Add
                  </Button>
                </div>
              ) : (
                <div className="del-button">
                  <Button className="black-btn" onClick={handleUpdateContent}>
                    Update
                  </Button>
                </div>
              )}
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddContentPopup;
