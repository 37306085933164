import {
  ADD_CONTENT_VIDEO_REQUEST,
  ADD_CONTENT_VIDEO_FAILURE,
  ADD_CONTENT_VIDEO_SUCCESS
} from '../content-constants';

const addContentVideoRequest = (data) => ({
  data,
  type: ADD_CONTENT_VIDEO_REQUEST
});

export const addContentVideoSuccess = () => ({
  type: ADD_CONTENT_VIDEO_SUCCESS
});

export const addContentVideoFailure = () => ({
  type: ADD_CONTENT_VIDEO_FAILURE
});

export const addContentVideo = async (data, dispatch) => {
  dispatch(addContentVideoRequest(data));
};
