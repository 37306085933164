import React from 'react';

import { Form } from 'react-bootstrap';

const TextInput = ({ label, placeholder, value, changeText, feedback, multiline=false, required=true }) => {
  return (
    <div className="custom-input">
      <label>
        {label}
        {required === true ? (<span className="start-required">*</span>) : null }
      </label>
      {
        multiline === true ? (
          <Form.Control
            required={required}
            rows={3}
            as="textarea"
            placeholder={placeholder}
            value={value}
            onChange={(e) => changeText(e)}
          />
        ) : (
          <Form.Control
            required={required}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(e) => changeText(e)}
          />
        )
      }
      <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
    </div>
  );
};

export default TextInput;
