import {
  GET_PRESS_BY_ID_REQUEST,
  GET_PRESS_BY_ID_FAILURE,
  GET_PRESS_BY_ID_SUCCESS
} from '../press-constants';

const getPressByIdRequest = (data) => ({
  data,
  type: GET_PRESS_BY_ID_REQUEST
});

export const getPressByIdSuccess = (data) => ({
  data,
  type: GET_PRESS_BY_ID_SUCCESS
});

export const getPressByIdFailure = () => ({
  type: GET_PRESS_BY_ID_FAILURE
});

export const getPressById = async (data, dispatch) => {
  dispatch(getPressByIdRequest(data));
};
