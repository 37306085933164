import {
  UPDATE_PORTFOLIO_REQUEST,
  UPDATE_PORTFOLIO_FAILURE,
  UPDATE_PORTFOLIO_SUCCESS
} from '../portfolio-constants';

const updatePortfolioRequest = (data) => ({
  data,
  type: UPDATE_PORTFOLIO_REQUEST
});

export const updatePortfolioSuccess = () => ({
  type: UPDATE_PORTFOLIO_SUCCESS
});

export const updatePortfolioFailure = () => ({
  type: UPDATE_PORTFOLIO_FAILURE
});

export const updatePortfolio = async (data, dispatch) => {
  dispatch(updatePortfolioRequest(data));
};
