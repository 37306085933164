import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { productionUrlWithId } from '../../../../utils/urls';
import { GET_PRODUCTION_BY_ID_REQUEST } from '../production-constants';
import {
  getProductionByIdSuccess,
  getProductionByIdFailure
} from './get-production-action';

const { getRequest } = new HttpHelper();

export function* getProductionById({ data }) {
  try {
    const response = yield call(getRequest, {
      url: productionUrlWithId(data.id)
    });

    if (response.error) {
      yield put(getProductionByIdFailure());
    } else {
      yield put(getProductionByIdSuccess(response.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetProductionById() {
  yield takeLatest(GET_PRODUCTION_BY_ID_REQUEST, getProductionById);
}
