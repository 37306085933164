import {
  GET_DIRECTOR_NAMES_REQUEST,
  GET_DIRECTOR_NAMES_FAILURE,
  GET_DIRECTOR_NAMES_SUCCESS
} from './admin-helper-constants';

const initialState = {
  isLoading: false,
  directorNamesList: []
};

const getDirectorNames = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIRECTOR_NAMES_REQUEST:
      return {
        ...state,
        isLoading: true,
        directorNamesList: []
      };
    case GET_DIRECTOR_NAMES_FAILURE:
      return {
        ...state,
        isLoading: false,
        directorNamesList: []
      };
    case GET_DIRECTOR_NAMES_SUCCESS:
      const { data } = action.data;
      return {
        ...state,
        isLoading: false,
        directorNamesList: data
      };
    default:
      return state;
  }
};

export default getDirectorNames;
