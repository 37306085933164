import {
  GET_CONTENT_BY_ID_REQUEST,
  GET_CONTENT_BY_ID_FAILURE,
  GET_CONTENT_BY_ID_SUCCESS
} from '../content-constants';

const initialState = {
  isEditContentLoading: false,
  contentData: {}
};

const getContentById = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTENT_BY_ID_REQUEST:
      return {
        ...state,
        isEditContentLoading: true,
        contentData: {}
      };
    case GET_CONTENT_BY_ID_FAILURE:
      return {
        ...state,
        isEditContentLoading: false,
        contentData: {}
      };
    case GET_CONTENT_BY_ID_SUCCESS:
      return {
        ...state,
        isEditContentLoading: false,
        contentData: action.data
      };
    default:
      return state;
  }
};

export default getContentById;
