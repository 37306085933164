import {
  GET_CONTENT_REQUEST,
  GET_CONTENT_FAILURE,
  GET_CONTENT_SUCCESS,
  SORT_CONTENT_REQUEST,
  SORT_CONTENT_FAILURE,
  SORT_CONTENT_SUCCESS
} from '../content-constants';

const getContentRequest = (data) => ({
  data,
  type: GET_CONTENT_REQUEST
});

export const getContentSuccess = (data) => ({
  data,
  type: GET_CONTENT_SUCCESS
});

export const getContentFailure = () => ({
  type: GET_CONTENT_FAILURE
});

export const getContent = async (data, dispatch) => {
  dispatch(getContentRequest(data));
};
const sortContentRequest = (data) => ({
  data,
  type: SORT_CONTENT_REQUEST
});

export const sortContentSuccess = (data) => ({
  data,
  type: SORT_CONTENT_SUCCESS
});

export const sortContentFailure = () => ({
  type: SORT_CONTENT_FAILURE
});

export const sortContent = async (data, dispatch) => {
  dispatch(sortContentRequest(data));
};
