// export const BASE_URL = `http://localhost:8080/v1/api`;
// export const BASE_URL = `http://15.207.254.49/v1/api`;
export const BASE_URL = `https://interface.earlymanfilm.com/v1/api`;

// URL parameter helper.
const attachParams = (baseUrl, params) => {
  var url = new URL(baseUrl);
  url.search = new URLSearchParams(params).toString();
  return url;
};

export const LOGIN_URL = `${BASE_URL}/login`;

export const DASHBOARD_URL = `${BASE_URL}/admin_helpers/dashboard`;

export const SHOW_REEL_URL = `${BASE_URL}/show_reel_videos`;
export const showReelUrl = (params = null) => {
  if (params) {
    return attachParams(SHOW_REEL_URL, params);
  } else {
    return SHOW_REEL_URL;
  }
};
export const showReelUrlWithId = (showReelId) => {
  return `${SHOW_REEL_URL}/${showReelId}`;
};

export const PORTFOLIO_URL = `${BASE_URL}/portfolio_videos`;
export const portfolioUrl = (params = null) => {
  if (params) {
    return attachParams(PORTFOLIO_URL, params);
  } else {
    return PORTFOLIO_URL;
  }
};
export const portfolioUrlWithId = (portfolioId) => {
  return `${PORTFOLIO_URL}/${portfolioId}`;
};

export const portfolioSortUrl = () => {
  return `${PORTFOLIO_URL}/sort`;
};

const DIRECTOR_URL = `${BASE_URL}/directors`;
export const directorUrl = (params = null) => {
  if (params) {
    return attachParams(DIRECTOR_URL, params);
  } else {
    return DIRECTOR_URL;
  }
};

export const directorSortUrl = () => {
  return `${DIRECTOR_URL}/sort`;
};

export const directorUrlWithId = (directorId) => {
  return `${DIRECTOR_URL}/${directorId}`;
};

export const DIRECTOR_VIDEO_URL = `${BASE_URL}/director_videos`;
export const directorVideoUrl = (params = null) => {
  if (params) {
    return attachParams(DIRECTOR_VIDEO_URL, params);
  } else {
    return DIRECTOR_VIDEO_URL;
  }
};
export const directorVideoUrlWithId = (directorVideoId, params = null) => {
  const videoUrl = `${DIRECTOR_VIDEO_URL}/director/${directorVideoId}`;
  if (params) {
    return attachParams(videoUrl, params);
  } else {
    return videoUrl;
  }
};

export const sortDirectorVideoUrlWithId = (directorVideoId) => {
  return `${DIRECTOR_VIDEO_URL}/director/${directorVideoId}/sort`;
};

export const videoUrlByVideoId = (directorId) => {
  return `${DIRECTOR_VIDEO_URL}/${directorId}`;
};

const CONTENT_URL = `${BASE_URL}/contents`;
export const contentUrl = (params = null) => {
  if (params) {
    return attachParams(CONTENT_URL, params);
  } else {
    return CONTENT_URL;
  }
};

export const contentSortUrl = () => {
  return `${CONTENT_URL}/sort`;
};

export const contentUrlWithId = (contentId) => {
  return `${CONTENT_URL}/${contentId}`;
};

export const CONTENT_VIDEO_URL = `${BASE_URL}/content_videos`;
export const contentVideoUrl = (params = null) => {
  if (params) {
    return attachParams(CONTENT_VIDEO_URL, params);
  } else {
    return CONTENT_VIDEO_URL;
  }
};
export const contentVideoUrlWithId = (contentVideoId, params = null) => {
  const videoUrl = `${CONTENT_VIDEO_URL}/content/${contentVideoId}`;
  if (params) {
    return attachParams(videoUrl, params);
  } else {
    return videoUrl;
  }
};

export const contentVideoUrlByVideoId = (contentId) => {
  return `${CONTENT_VIDEO_URL}/${contentId}`;
};

export const AWARD_URL = `${BASE_URL}/awards`;
export const awardUrl = (params = null) => {
  if (params) {
    return attachParams(AWARD_URL, params);
  } else {
    return AWARD_URL;
  }
};

export const awardSortUrl = () => {
  return `${AWARD_URL}/sort`;
};

export const awardUrlWithId = (awardId) => {
  return `${AWARD_URL}/${awardId}`;
};

export const PRESS_URL = `${BASE_URL}/press_releases`;
export const pressUrl = (params = null) => {
  if (params) {
    return attachParams(PRESS_URL, params);
  } else {
    return PRESS_URL;
  }
};
export const pressUrlWithId = (pressId) => {
  return `${PRESS_URL}/${pressId}`;
};

export const PRODUCTION_URL = `${BASE_URL}/production_services`;
export const productionUrl = (params = null) => {
  if (params) {
    return attachParams(PRODUCTION_URL, params);
  } else {
    return PRODUCTION_URL;
  }
};
export const productionUrlWithId = (productionId) => {
  return `${PRODUCTION_URL}/${productionId}`;
};

export const productionSortUrl = () => {
  return `${PRODUCTION_URL}/sort`;
};

export const DIRECTOR_NAMES_URL = `${BASE_URL}/admin_helpers/director_names`;

export const BRAND_NAMES_URL = `${BASE_URL}/admin_helpers/brand_names`;

export const FILM_NAMES_URL = `${BASE_URL}/admin_helpers/film_names`;

export const TYPE_OF_CONTENTS_URL = `${BASE_URL}/admin_helpers/type_of_contents`;
