import * as Toastr from 'toastr';

import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { contentUrl } from '../../../utils/urls';
import { ADD_CONTENT_REQUEST } from '../content-constants';
import { addContentSuccess, addContentFailure } from './create-content-action';

const { postRequest } = new HttpHelper();

export function* addContent({ data }) {
  try {
    const response = yield call(postRequest, {
      url: contentUrl(),
      data: data.formData
    });

    if (response.error) {
      yield put(addContentFailure());
    } else if (response.data && response.data.status) {
      yield put(addContentSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.contentSuccessCallback();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchAddContent() {
  yield takeLatest(ADD_CONTENT_REQUEST, addContent);
}
