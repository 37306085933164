import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { contentVideoUrlByVideoId } from '../../../utils/urls';
import { DELETE_CONTENT_VIDEO_REQUEST } from '../content-constants';
import {
  deleteContentVideoSuccess,
  deleteContentVideoFailure
} from './delete-content-video-action';

const { deleteRequest } = new HttpHelper();

export function* deleteContentVideo({ data }) {
  try {
    const response = yield call(deleteRequest, {
      url: contentVideoUrlByVideoId(data.id)
    });

    if (response.error) {
      yield put(deleteContentVideoFailure());
    } else {
      data.deleteSuccess();
      yield put(deleteContentVideoSuccess());
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchDeleteContentVideo() {
  yield takeLatest(DELETE_CONTENT_VIDEO_REQUEST, deleteContentVideo);
}
