import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../../components/layout';
import ContentVideoForm from '../content-video-form';
import { addContentVideo } from './create-content-video-action';

const CreateContentVideo = ({ history, match }) => {
  const [addContentVideoDetails, setAddContentVideoDetails] = useState({
    thumbnailImage: null,
    thumbnailVideo: null,
    video: '',
    filmName: '',
    directorName: '',
    coProducerName: '',
    awards: []
  });
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.addContentVideo);

  const contentVideoSuccessCallback = () => {
    history.push(`/content/${match.params.id}/list`);
  };

  const handleCreateContentVideo = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const {
      thumbnailImage,
      thumbnailVideo,
      video,
      filmName,
      directorName,
      coProducerName,
      awards
    } = addContentVideoDetails;

    if (thumbnailImage && thumbnailVideo && video && filmName && directorName) {
      let contentVideoFormData = new FormData();
      contentVideoFormData.append('thumbnail_image', thumbnailImage);
      contentVideoFormData.append('thumbnail_video', thumbnailVideo);
      contentVideoFormData.append('video', video);
      contentVideoFormData.append('film_name', filmName);
      contentVideoFormData.append('director_name', directorName);
      contentVideoFormData.append('content_id', match.params.id);
      contentVideoFormData.append('co_producer', coProducerName);
      contentVideoFormData.append('awards', awards);

      addContentVideo(
        {
          formData: contentVideoFormData,
          contentVideoSuccessCallback
        },
        dispatch
      );
    }
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <ContentVideoForm
            contentVideoDetails={addContentVideoDetails}
            setContentVideoDetails={setAddContentVideoDetails}
            validated={validated}
            handleCancel={() =>
              history.push(`/content/${match.params.id}/list`)
            }
            handleSubmitContentVideo={handleCreateContentVideo}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default CreateContentVideo;
