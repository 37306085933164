import React from 'react';

import Header from '../header';
import Sidebar from '../sidebar';

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Sidebar />
      <Header />
      {children}
    </div>
  );
};

export default Layout;
