import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../../components/layout';
import DirectorForm from '../director-form';
import { addDirector } from './create-director-action';

const CreateDirector = ({ history }) => {
  const [addDirectorDetails, setAddDirectorDetails] = useState({
    name: '',
    bio: '',
    profilePic: null,
    reelVideo: null
  });

  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.addDirector);

  const directorSuccessCallback = () => {
    history.push('/director');
  };

  const handleCreateDirector = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const { name, bio, profilePic, reelVideo } = addDirectorDetails;

    if (name && bio && profilePic && reelVideo) {
      let directorFormData = new FormData();
      directorFormData.append('name', name);
      directorFormData.append('bio', bio);
      directorFormData.append('profile_pic', profilePic);
      directorFormData.append('reel_video', reelVideo);

      addDirector(
        {
          formData: directorFormData,
          directorSuccessCallback
        },
        dispatch
      );
    }
  };

  const handleCancel = () => {
    history.push('/director');
  };
  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <DirectorForm
            directorDetails={addDirectorDetails}
            setDirectorDetails={setAddDirectorDetails}
            validated={validated}
            handleCancel={handleCancel}
            handleSubmitDirector={handleCreateDirector}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default CreateDirector;
