import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import { getPress } from './press-action';
import { deletePress } from '../delete/delete-press-action';

import Layout from '../../../../components/layout';
import PaginationList from '../../../../components/pagination';
import DeletePopup from '../../../../components/modal/delete-popup';

import img from '../../../../assets/images/video/videoimg.jpg';
import plus from '../../../../assets/images/plus.png';

const Press = ({ history }) => {
  const [pressId, setPressId] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();

  const getPressDetails = (currentPageNo, pageSize) => {
    getPress({ pageNumber: currentPageNo, pageSize: pageSize }, dispatch);
  };

  useEffect(() => {
    getPressDetails(currentPageNo, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pressList, isLoading, totalRecords } = useSelector(
    (state) => state.getPress
  );

  const deletePressSuccess = () => {
    setShowDeletePopup(false);
    getPressDetails(currentPageNo, pageSize);
  };

  const handelDelete = () => {
    deletePress({ id: pressId, deleteSuccess: deletePressSuccess }, dispatch);
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          {!isLoading ? (
            <div className="video-list-container list-container">
              <div className="video-list-head">
                <div>
                  <h2>Press List</h2>
                </div>
                <div>
                  <Button
                    className="black-btn width"
                    onClick={() => history.push(`/press/create`)}
                  >
                    Add Press
                    <span>
                      <img src={plus} alt="plus" />
                    </span>
                  </Button>
                </div>
              </div>
              {pressList &&
                pressList.map((press) => (
                  <div className="video-card" key={press.id}>
                    <div className="video-desc">
                      <label>
                        <a href={press.link}>
                          {press.title}
                          <span></span>
                          {press.media_name}
                        </a>
                      </label>
                      <div className="btn-section">
                        <Button
                          className="black-btn transparent"
                          onClick={() =>
                            history.push(`/press/${press.id}/edit`)
                          }
                        >
                          Edit
                        </Button>
                        <span>|</span>

                        <Button
                          className="black-btn transparent red"
                          onClick={() => {
                            setShowDeletePopup(true);
                            setPressId(press.id);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <p style={{ color: '#000' }}>Loading...</p>
          )}
        </div>
      </div>
      <PaginationList
        currentPageNo={currentPageNo}
        setCurrentPageNo={setCurrentPageNo}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalRecords={totalRecords}
        getCurentPageData={getPressDetails}
      />
      <DeletePopup
        show={showDeletePopup}
        handleClose={() => setShowDeletePopup(false)}
        handelDelete={handelDelete}
      />
    </Layout>
  );
};

export default Press;
