import {
  GET_PRODUCTION_REQUEST,
  GET_PRODUCTION_FAILURE,
  GET_PRODUCTION_SUCCESS,
  SORT_PRODUCTION_REQUEST,
  SORT_PRODUCTION_FAILURE,
  SORT_PRODUCTION_SUCCESS
} from '../production-constants';

const getProductionRequest = (data) => ({
  data,
  type: GET_PRODUCTION_REQUEST
});

export const getProductionSuccess = (data) => ({
  data,
  type: GET_PRODUCTION_SUCCESS
});

export const getProductionFailure = () => ({
  type: GET_PRODUCTION_FAILURE
});

export const getProduction = async (data, dispatch) => {
  dispatch(getProductionRequest(data));
};

const sortProductionRequest = (data) => ({
  data,
  type: SORT_PRODUCTION_REQUEST
});

export const sortProductionSuccess = (data) => ({
  data,
  type: SORT_PRODUCTION_SUCCESS
});

export const sortProductionFailure = () => ({
  type: SORT_PRODUCTION_FAILURE
});

export const sortProduction = async (data, dispatch) => {
  dispatch(sortProductionRequest(data));
};
