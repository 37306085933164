import { call, put, takeLatest } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../../../utils/http-helper';
import { awardUrl, awardSortUrl } from '../../../../utils/urls';
import { GET_AWARD_REQUEST, SORT_AWARD_REQUEST } from '../award-constants';
import { getAwardSuccess, getAwardFailure, sortAwardSuccess, sortAwardFailure } from './award-action';

const { getRequest, postRequest } = new HttpHelper();

export function* getAward({ data }) {
  try {
    const response = yield call(getRequest, {
      url: awardUrl(data)
    });

    if (response.error) {
      yield put(getAwardFailure());
    } else {
      yield put(getAwardSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* sortAward({ data }) {
  try {
    const response = yield call(postRequest, {
      url: awardSortUrl(),
      data: data.formData
    });

    if (response.error) {
      yield put(sortAwardFailure());
    } else if (response.data && response.data.status) {
      yield put(sortAwardSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.updateSuccess();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetAward() {
  yield takeLatest(GET_AWARD_REQUEST, getAward);
}

export function* watchSortAward() {
  yield takeLatest(SORT_AWARD_REQUEST, sortAward);
}
