import { call, put, takeLatest } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../../utils/http-helper';
import { contentUrl, contentSortUrl } from '../../../utils/urls';
import { GET_CONTENT_REQUEST, SORT_CONTENT_REQUEST } from '../content-constants';
import { getContentSuccess, getContentFailure, sortContentSuccess, sortContentFailure } from './content-action';

const { getRequest, postRequest } = new HttpHelper();

export function* getContent({ data }) {
  try {
    const response = yield call(getRequest, {
      url: contentUrl(data)
    });

    if (response.error) {
      yield put(getContentFailure());
    } else {
      yield put(getContentSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* sortContent({ data }) {
  try {
    const response = yield call(postRequest, {
      url: contentSortUrl(),
      data: data.formData
    });

    if (response.error) {
      yield put(sortContentFailure());
    } else if (response.data && response.data.status) {
      yield put(sortContentSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.updateSuccess();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}


export function* watchGetContent() {
  yield takeLatest(GET_CONTENT_REQUEST, getContent);
}

export function* watchSortContent() {
  yield takeLatest(SORT_CONTENT_REQUEST, sortContent);
}
