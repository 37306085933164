import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSortable } from "react-sortablejs";

import { Button } from 'react-bootstrap';
import plus from '../../../../assets/images/plus.png';

import { getProduction, sortProduction } from './../list/production-action';

import Layout from '../../../../components/layout';

const SortProduction = ({ history }) => {
  const [currentPageNo] = useState(1);
  const [pageSize] = useState(10000);

  const dispatch = useDispatch();

  const getProductionDetails = (currentPageNo, pageSize) => {
    getProduction({ pageNumber: currentPageNo, pageSize: pageSize }, dispatch);
  };

  useEffect(() => {
    getProductionDetails(currentPageNo, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { productionList, isLoading } = useSelector(
    (state) => state.getProduction
  );

  const updateSuccess = () => {
    history.push('/production/sort');
  };

  const getOrder = (list) => {
    const ids = [];
    for (let i = 0; i < list.length; i++) {
      const elem = list[i];
      ids.push(elem.id);
    }
    return ids;
  }

  const updateProductionList = (list) => {
    let data = new FormData();
    data.append('order', getOrder(list));

    sortProduction({
      data: list,
      formData: data,
      updateSuccess: updateSuccess
    }, dispatch);
  }

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          {!isLoading ? (
            <div className="video-list-container list-container">
              <div className="video-list-head">
                <div>
                  <h2>Production List Re-Order</h2>
                </div>
                <div>
                  <Button
                    className="black-btn halfwidth"
                    onClick={() => history.push('/production/create')}
                  >
                    Add New
                    <span>
                      <img src={plus} alt="plus" />
                    </span>
                  </Button>
                </div>
              </div>
              <div className="director-list-section">
                <div className="director-list">
                  <p>PRODUCTION HOUSE NAME</p>
                  <span>|</span>
                  <p class="m-l-20">FILM NAME</p>
                  {/* <span>|</span>
                  <p class="m-l-20">BRAND NAME</p> */}
                </div>
              </div>
              <hr />
              <ReactSortable list={productionList} setList={(newState) => updateProductionList(newState)}>
                {productionList &&
                  productionList.map((production) => (
                    <div className="director-list-section" key={production.id}>
                      <div className="director-list">
                        <p>{production.name}</p>
                        <span>|</span>
                        <p class="m-l-20">{production.type_of_content}</p>
                        {/* <span>|</span>
                        <p class="m-l-20">{production.brand_name}</p> */}
                      </div>
                    </div>
                  ))}
              </ReactSortable>
            </div>
          ) : (
            <p style={{ color: '#000' }}>Loading...</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SortProduction;
