import { call, put, takeLatest } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../../../utils/http-helper';
import { portfolioUrl, portfolioSortUrl } from '../../../../utils/urls';
import { GET_PORTFOLIO_REQUEST, SORT_PORTFOLIO_REQUEST } from '../portfolio-constants';
import { getPortfolioSuccess, getPortfolioFailure, sortPortfolioSuccess, sortPortfolioFailure } from './portfolio-action';

const { getRequest, postRequest } = new HttpHelper();

export function* getPortfolio({ data }) {
  try {
    const response = yield call(getRequest, {
      url: portfolioUrl(data)
    });

    if (response.error) {
      yield put(getPortfolioFailure());
    } else {
      yield put(getPortfolioSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* sortPortfolio({ data }) {
  try {
    const response = yield call(postRequest, {
      url: portfolioSortUrl(),
      data: data.formData
    });

    if (response.error) {
      yield put(sortPortfolioFailure());
    } else if (response.data && response.data.status) {
      yield put(sortPortfolioSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.updateSuccess();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetPortfolio() {
  yield takeLatest(GET_PORTFOLIO_REQUEST, getPortfolio);
}

export function* watchSortPortfolio() {
  yield takeLatest(SORT_PORTFOLIO_REQUEST, sortPortfolio);
}
