import {
  DELETE_CONTENT_REQUEST,
  DELETE_CONTENT_FAILURE,
  DELETE_CONTENT_SUCCESS
} from '../content-constants';

const initialState = {
  isLoading: false
};

const deleteContent = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_CONTENT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case DELETE_CONTENT_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default deleteContent;
