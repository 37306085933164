import {
  GET_PORTFOLIO_REQUEST,
  GET_PORTFOLIO_FAILURE,
  GET_PORTFOLIO_SUCCESS,
  SORT_PORTFOLIO_REQUEST,
  SORT_PORTFOLIO_SUCCESS,
  SORT_PORTFOLIO_FAILURE
} from '../portfolio-constants';

const initialState = {
  isLoading: false,
  isSorting: false,
  portfolioList: []
};

const getPortfolio = (state = initialState, action) => {
  switch (action.type) {
    case GET_PORTFOLIO_REQUEST:
      return {
        ...state,
        isLoading: true,
        portfolioList: []
      };
    case GET_PORTFOLIO_FAILURE:
      return {
        ...state,
        isLoading: false,
        portfolioList: []
      };
    case GET_PORTFOLIO_SUCCESS:
      const { data, totalRecords } = action.data;
      return {
        ...state,
        isLoading: false,
        portfolioList: data,
        totalRecords
      };
    case SORT_PORTFOLIO_REQUEST:
      const sortedList = [...action.data.data];
      return {
        ...state,
        isSorting: true,
        portfolioList: sortedList
      };
    case SORT_PORTFOLIO_FAILURE:
    case SORT_PORTFOLIO_SUCCESS:
      return {
        ...state,
        isSorting: false
      };
    default:
      return state;
  }
};

export default getPortfolio;
