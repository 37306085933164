import * as Toastr from 'toastr';

import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { directorUrl } from '../../../utils/urls';
import { ADD_DIRECTOR_REQUEST } from '../director-constants';
import {
  addDirectorSuccess,
  addDirectorFailure
} from './create-director-action';

const { postRequest } = new HttpHelper();

export function* addDirector({ data }) {
  try {
    const response = yield call(postRequest, {
      url: directorUrl(),
      data: data.formData
    });

    if (response.error) {
      yield put(addDirectorFailure());
    } else if (response.data && response.data.status) {
      yield put(addDirectorSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.directorSuccessCallback();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchAddDirector() {
  yield takeLatest(ADD_DIRECTOR_REQUEST, addDirector);
}
