import * as Toastr from 'toastr';

import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { productionUrl } from '../../../../utils/urls';
import { ADD_PRODUCTION_REQUEST } from '../production-constants';
import {
  addProductionSuccess,
  addProductionFailure
} from './create-production-action';

const { postRequest } = new HttpHelper();

export function* addProduction({ data }) {
  try {
    const response = yield call(postRequest, {
      url: productionUrl(),
      data: data.formData
    });

    if (response.error) {
      yield put(addProductionFailure());
    } else if (response.data && response.data.status) {
      yield put(addProductionSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.productionSuccessCallback();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchAddProduction() {
  yield takeLatest(ADD_PRODUCTION_REQUEST, addProduction);
}
