import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { contentVideoUrlWithId } from '../../../utils/urls';
import { GET_CONTENT_VIDEO_REQUEST } from '../content-constants';
import {
  getContentVideoSuccess,
  getContentVideoFailure
} from './content-video-action';

const { getRequest } = new HttpHelper();

export function* getContentVideo({ data }) {
  try {
    const response = yield call(getRequest, {
      url: contentVideoUrlWithId(data.id, {
        pageNumber: data.pageNumber,
        pageSize: data.pageSize
      })
    });

    if (response.error) {
      yield put(getContentVideoFailure());
    } else {
      yield put(getContentVideoSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetContentVideo() {
  yield takeLatest(GET_CONTENT_VIDEO_REQUEST, getContentVideo);
}
