import {
  GET_SHOW_REEL_BY_ID_REQUEST,
  GET_SHOW_REEL_BY_ID_FAILURE,
  GET_SHOW_REEL_BY_ID_SUCCESS
} from '../showreel-constants';

const getShowReelByIdRequest = (data) => ({
  data,
  type: GET_SHOW_REEL_BY_ID_REQUEST
});

export const getShowReelByIdSuccess = (data) => ({
  data,
  type: GET_SHOW_REEL_BY_ID_SUCCESS
});

export const getShowReelByIdFailure = () => ({
  type: GET_SHOW_REEL_BY_ID_FAILURE
});

export const getShowReelById = async (data, dispatch) => {
  dispatch(getShowReelByIdRequest(data));
};
