import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { pressUrlWithId } from '../../../../utils/urls';
import { DELETE_PRESS_REQUEST } from '../press-constants';
import { deletePressSuccess, deletePressFailure } from './delete-press-action';

const { deleteRequest } = new HttpHelper();

export function* deletePress({ data }) {
  try {
    const response = yield call(deleteRequest, {
      url: pressUrlWithId(data.id)
    });

    if (response.error) {
      yield put(deletePressFailure());
    } else {
      data.deleteSuccess();
      yield put(deletePressSuccess());
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchDeletePress() {
  yield takeLatest(DELETE_PRESS_REQUEST, deletePress);
}
