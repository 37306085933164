import {
  ADD_PRESS_REQUEST,
  ADD_PRESS_FAILURE,
  ADD_PRESS_SUCCESS
} from '../press-constants';

const addPressRequest = (data) => ({
  data,
  type: ADD_PRESS_REQUEST
});

export const addPressSuccess = () => ({
  type: ADD_PRESS_SUCCESS
});

export const addPressFailure = () => ({
  type: ADD_PRESS_FAILURE
});

export const addPress = async (data, dispatch) => {
  dispatch(addPressRequest(data));
};
