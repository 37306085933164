import {
  GET_DIRECTOR_REQUEST,
  GET_DIRECTOR_FAILURE,
  GET_DIRECTOR_SUCCESS,
  SORT_DIRECTOR_REQUEST,
  SORT_DIRECTOR_FAILURE,
  SORT_DIRECTOR_SUCCESS
} from '../director-constants';

const getDirectorRequest = (data) => ({
  data,
  type: GET_DIRECTOR_REQUEST
});

export const getDirectorSuccess = (data) => ({
  data,
  type: GET_DIRECTOR_SUCCESS
});

export const getDirectorFailure = () => ({
  type: GET_DIRECTOR_FAILURE
});

export const getDirector = async (data, dispatch) => {
  dispatch(getDirectorRequest(data));
};

const sortDirectorRequest = (data) => ({
  data,
  type: SORT_DIRECTOR_REQUEST
});

export const sortDirectorSuccess = (data) => ({
  data,
  type: SORT_DIRECTOR_SUCCESS
});

export const sortDirectorFailure = () => ({
  type: SORT_DIRECTOR_FAILURE
});

export const sortDirector = async (data, dispatch) => {
  dispatch(sortDirectorRequest(data));
};
