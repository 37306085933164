import {
  GET_PRODUCTION_BY_ID_REQUEST,
  GET_PRODUCTION_BY_ID_FAILURE,
  GET_PRODUCTION_BY_ID_SUCCESS
} from '../production-constants';

const initialState = {
  isEditProductionLoading: false,
  productionData: {}
};

const getProductionById = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTION_BY_ID_REQUEST:
      return {
        ...state,
        isEditProductionLoading: true,
        productionData: {}
      };
    case GET_PRODUCTION_BY_ID_FAILURE:
      return {
        ...state,
        isEditProductionLoading: false,
        productionData: {}
      };
    case GET_PRODUCTION_BY_ID_SUCCESS:
      return {
        ...state,
        isEditProductionLoading: false,
        productionData: action.data
      };
    default:
      return state;
  }
};

export default getProductionById;
