import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Form, Button } from 'react-bootstrap';

import { getDirectorNames } from '../../admin-helpers/director-names-action';
import { getBrandNames } from '../../admin-helpers/brand-names-action';
import { getTypeOfContents } from '../../admin-helpers/type-of-contents-action';

import CreatableSelector from '../../../components/creatable-select';
import TextInput from '../../../components/form-fields/text-input';
import FileInput from '../../../components/form-fields/file-input';
import SelectInput from '../../../components/form-fields/select-input';
import ButtonLoader from '../../../assets/images/button-loader.svg';
import arrow from '../../../assets/images/arrow.png';
import TagInput from '../../../components/form-fields/tag-input';

const PortfolioForm = ({
  portfolioDetails,
  setPortfolioDetails,
  validated,
  handleCancel,
  handleSubmitPortfolio,
  isLoading,
  noData,
  isEditPortfolioLoading
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getDirectorNames(dispatch);
    getBrandNames(dispatch);
    getTypeOfContents(dispatch);
  }, [dispatch]);

  const { directorNamesList } = useSelector((state) => state.getDirectorNames);
  const { brandNamesList } = useSelector((state) => state.getBrandNames);
  const { typeOfContentsList } = useSelector(
    (state) => state.getTypeOfContents
  );

  const handleKeyDown = (e) => {
    // if (e.keyCode === 13) {
    //   e.preventDefault();
    //   handleSubmitPortfolio(e);
    // }
  };

  const category = [
    { label: 'Ad Film', title: 'Ad Film' },
    { label: 'Content Film', title: 'Content Film' }
  ];

  useEffect(() => {
    if (portfolioDetails.category === '') {
      setPortfolioDetails({
        ...portfolioDetails,
        category: category[0]
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (newValue) => {
    if (newValue) {
      setPortfolioDetails({
        ...portfolioDetails,
        category: newValue.label
      });
    } else {
      setPortfolioDetails({
        ...portfolioDetails,
        category: ''
      });
    }
  };

  const formFileds = [
    {
      type: 'file',
      label: 'Upload Thumbnail Image',
      heading: 'Thumbnail Image',
      value: portfolioDetails.thumbnailImage,
      onClickFile: () =>
        setPortfolioDetails({
          ...portfolioDetails,
          thumbnailImage: null
        }),
      onChangeFile: (e) =>
        setPortfolioDetails({
          ...portfolioDetails,
          thumbnailImage: e.target.files[0]
        }),
      feedback: 'Please upload thumbnail image.',
      validated: validated
    },
    {
      type: 'fileVideo',
      label: 'Upload Thumbnail Video',
      heading: 'Thumbnail Video',
      value: portfolioDetails.thumbnailVideo,
      onClickFile: () =>
        setPortfolioDetails({
          ...portfolioDetails,
          thumbnailVideo: null
        }),
      onChangeFile: (e) =>
        setPortfolioDetails({
          ...portfolioDetails,
          thumbnailVideo: e.target.files[0]
        }),
      feedback: 'Please upload thumbnail video.',
      validated: validated
    },
    {
      type: 'text',
      label: 'Video URL',
      placeholder: 'Enter video url',
      value: portfolioDetails.videoUrl,
      changeText: (e) =>
        setPortfolioDetails({
          ...portfolioDetails,
          videoUrl: e.target.value
        }),
      feedback: 'Please enter video url.'
    },
    {
      type: 'text',
      label: 'Film Name',
      placeholder: 'Enter name of the film',
      value: portfolioDetails.filmName,
      changeText: (e) =>
        setPortfolioDetails({
          ...portfolioDetails,
          filmName: e.target.value
        }),
      feedback: 'Please enter film name.'
    },
    {
      type: 'text',
      label: 'Agency',
      placeholder: 'Enter name of the agency',
      value: portfolioDetails.agencyName,
      changeText: (e) =>
        setPortfolioDetails({
          ...portfolioDetails,
          agencyName: e.target.value
        }),
      feedback: 'Please enter agency name.',
      required: false
    },
    {
      type: 'text',
      label: 'Co-Producer Name',
      placeholder: 'Enter Co-Producer name',
      value: portfolioDetails.coProducerName,
      changeText: (e) =>
        setPortfolioDetails({
          ...portfolioDetails,
          coProducerName: e.target.value
        }),
      feedback: 'Please enter co-producer name.',
      required: false
    },
    {
      type: 'tagInput',
      label: 'Awards',
      placeholder: 'Type and press enter',
      tags: portfolioDetails.awards || [],
      changeTags: (value) =>
        setPortfolioDetails({
          ...portfolioDetails,
          awards: value
        }),
      feedback: 'Please enter the awards.'
    },
    {
      type: 'select',
      label: 'Category',
      value: portfolioDetails.category,
      options: category,
      handleChange: handleChange,
      validated: validated
    }
  ];

  const renderFormFields = (input) => {
    switch (input.type) {
      case 'file':
        case 'fileVideo':
        return (
          <FileInput
            label={input.label}
            heading={input.heading}
            value={input.value}
            onClickFile={input.onClickFile}
            onChangeFile={input.onChangeFile}
            feedback={input.feedback}
            validated={input.validated}
            type={input.type}
          />
        );
      case 'text':
        return (
          <TextInput
            label={input.label}
            placeholder={input.placeholder}
            value={input.value}
            changeText={input.changeText}
            feedback={input.feedback}
            required={input.required}
          />
        );
      case 'tagInput':
        return (
          <TagInput
            label={input.label}
            placeholder={input.placeholder}
            tags={input.tags}
            changeTags={input.changeTags}
            feedback={input.feedback}
          />
        );
      case 'select':
        return (
          <SelectInput
            label={input.label}
            value={input.value}
            options={input.options}
            handleChange={input.handleChange}
            validated={validated}
          />
        );
      default:
        return '';
    }
  };

  return !isEditPortfolioLoading ? (
    <div className="form-container">
      <div className="back-arrow" onClick={handleCancel}>
        <img src={arrow} alt="arrow" />
      </div>
      <div className="head">
        <h4>Portfolio Form</h4>
        <p>Enter your details below</p>
      </div>
      {!noData ? (
        <>
          <Form onKeyDown={handleKeyDown} noValidate validated={validated}>
            {formFileds.map((input, index) => (
              <Fragment key={index}>{renderFormFields(input)}</Fragment>
            ))}

            {portfolioDetails.category === 'Ad Film' && (
              <div className="custom-input">
                <label>
                  Brand Name<span className="start-required">*</span>
                </label>
                <CreatableSelector
                  options={brandNamesList}
                  selectedOption={portfolioDetails}
                  setSelectedOption={setPortfolioDetails}
                  label="brandName"
                  validated={validated}
                />
              </div>
            )}
            {portfolioDetails.category === 'Content Film' && (
              <>
                <div className="custom-input">
                  <label>
                    Content Type<span className="start-required">*</span>
                  </label>
                  <CreatableSelector
                    options={typeOfContentsList}
                    selectedOption={portfolioDetails}
                    setSelectedOption={setPortfolioDetails}
                    label="typeOfContent"
                    validated={validated}
                  />
                </div>
              </>
            )}

            <div className="custom-input">
              <label>
                Director Name<span className="start-required">*</span>
              </label>
              <CreatableSelector
                options={directorNamesList}
                selectedOption={portfolioDetails}
                setSelectedOption={setPortfolioDetails}
                label="directorName"
                validated={validated}
              />
            </div>

            <div className="bottom-btn">
              <Button className="black-btn white width" onClick={handleCancel}>
                Cancel
              </Button>

              <Button
                className="black-btn width"
                onClick={handleSubmitPortfolio}
              >
                {!isLoading ? (
                  'Submit'
                ) : (
                  <>
                    <img src={ButtonLoader} alt="button-loader" />
                    <span className="laoder-span">Loading....</span>
                  </>
                )}
              </Button>
            </div>
          </Form>
        </>
      ) : (
        <Form>No data found</Form>
      )}
    </div>
  ) : (
    <p style={{ color: '#000' }}>Loading...</p>
  );
};

export default PortfolioForm;
