import {
  DELETE_SHOW_REEL_REQUEST,
  DELETE_SHOW_REEL_FAILURE,
  DELETE_SHOW_REEL_SUCCESS
} from '../showreel-constants';

const initialState = {
  isLoading: false
};

const deleteShowReel = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_SHOW_REEL_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case DELETE_SHOW_REEL_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case DELETE_SHOW_REEL_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default deleteShowReel;
