import {
  GET_PRESS_BY_ID_REQUEST,
  GET_PRESS_BY_ID_FAILURE,
  GET_PRESS_BY_ID_SUCCESS
} from '../press-constants';

const initialState = {
  isEditPressLoading: false,
  pressData: {}
};

const getPressById = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRESS_BY_ID_REQUEST:
      return {
        ...state,
        isEditPressLoading: true,
        pressData: {}
      };
    case GET_PRESS_BY_ID_FAILURE:
      return {
        ...state,
        isEditPressLoading: false,
        pressData: {}
      };
    case GET_PRESS_BY_ID_SUCCESS:
      return {
        ...state,
        isEditPressLoading: false,
        pressData: action.data
      };
    default:
      return state;
  }
};

export default getPressById;
