import {
  GET_AWARD_REQUEST,
  GET_AWARD_FAILURE,
  GET_AWARD_SUCCESS,
  SORT_AWARD_REQUEST,
  SORT_AWARD_SUCCESS,
  SORT_AWARD_FAILURE
} from '../award-constants';

const getAwardRequest = (data) => ({
  data,
  type: GET_AWARD_REQUEST
});

export const getAwardSuccess = (data) => ({
  data,
  type: GET_AWARD_SUCCESS
});

export const getAwardFailure = () => ({
  type: GET_AWARD_FAILURE
});

export const getAward = async (data, dispatch) => {
  dispatch(getAwardRequest(data));
};

const sortAwardRequest = (data) => ({
  data,
  type: SORT_AWARD_REQUEST
});

export const sortAwardSuccess = (data) => ({
  data,
  type: SORT_AWARD_SUCCESS
});

export const sortAwardFailure = () => ({
  type: SORT_AWARD_FAILURE
});

export const sortAward = async (data, dispatch) => {
  dispatch(sortAwardRequest(data));
};
