import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../../../components/layout';
import PressForm from '../press-form';
import { updatePress } from './update-press-action';
import { getPressById } from '../list/get-press-action';

const UpdatePress = ({ match, history }) => {
  const [updatePressDetails, setUpdatePressDetails] = useState({
    title: '',
    mediaName: '',
    link: ''
  });
  const [noData, setnoData] = useState(false);
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.updatePress);

  useEffect(() => {
    if (match.params.id) {
      getPressById({ id: match.params.id }, dispatch);
    }
  }, [match.params, dispatch]);

  const { pressData, isEditPressLoading } = useSelector(
    (state) => state.getPressById
  );

  useEffect(() => {
    if (Object.keys(pressData).length) {
      setUpdatePressDetails({
        title: pressData.title || '',
        mediaName: pressData.media_name || '',
        link: pressData.link || ''
      });
      setnoData(false);
    } else {
      setnoData(true);
    }
  }, [pressData]);

  const pressSuccess = () => {
    history.push('/press');
  };

  const handleUpdatePress = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const { title, mediaName, link } = updatePressDetails;

    if (title && mediaName && link) {
      let pressFormData = new FormData();

      pressFormData.append('title', title);
      pressFormData.append('media_name', mediaName);
      pressFormData.append('link', link);

      updatePress(
        {
          formData: pressFormData,
          id: match.params.id,
          updateSuccess: pressSuccess
        },
        dispatch
      );
    }
  };

  const handleCancel = () => {
    history.push('/press');
  };
  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <PressForm
            pressDetails={updatePressDetails}
            setPressDetails={setUpdatePressDetails}
            validated={validated}
            handleCancel={handleCancel}
            handleSubmitPress={handleUpdatePress}
            noData={noData}
            isLoading={isLoading}
            isEditPressLoading={isEditPressLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default UpdatePress;
