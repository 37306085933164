// @flow
import { combineReducers } from 'redux';

// Authentication Reducers.
import login from '../containers/login/login-reducer';
import getDashboard from '../containers/dashboard/dashboard-reducer';
import getShowReel from '../containers/home/showreel/list/showreel-reducer';
import getShowReelById from '../containers/home/showreel/list/get-showreel-reducer';
import addShowReel from '../containers/home/showreel/create/create-showreel-reducer';
import updateShowReel from '../containers/home/showreel/update/update-showreel-reducer';
import deleteShowReel from '../containers/home/showreel/delete/delete-showreel-reducer';
import getPortfolio from '../containers/home/portfolio/list/portfolio-reducer';
import getPortfolioById from '../containers/home/portfolio/list/get-portfolio-reducer';
import addPortfolio from '../containers/home/portfolio/create/create-portfolio-reducer';
import updatePortfolio from '../containers/home/portfolio/update/update-portfolio-reducer';
import deletePortfolio from '../containers/home/portfolio/delete/delete-portfolio-reducer';
import getDirector from '../containers/director/list/director-reducer';
import getDirectorById from '../containers/director/list/get-director-reducer';
import addDirector from '../containers/director/create/create-director-reducer';
import updateDirector from '../containers/director/update/update-director-reducer';
import deleteDirector from '../containers/director/delete/delete-director-reducer';
import getDirectorVideo from '../containers/director/list/director-video-reducer';
import getDirectorVideoById from '../containers/director/list/get-director-video-reducer';
import addDirectorVideo from '../containers/director/create/create-director-video-reducer';
import updateDirectorVideo from '../containers/director/update/update-director-video-reducer';
import deleteDirectorVideo from '../containers/director/delete/delete-director-video-reducer';
import getContent from '../containers/content/list/content-reducer';
import getContentVideo from '../containers/content/list/content-video-reducer';
import getContentById from '../containers/content/list/get-content-reducer';
import getContentVideoById from '../containers/content/list/get-content-video-reducer';
import addContent from '../containers/content/create/create-content-reducer';
import addContentVideo from '../containers/content/create/create-content-video-reducer';
import updateContent from '../containers/content/update/update-content-reducer';
import updateContentVideo from '../containers/content/update/update-content-video-reducer';
import deleteContent from '../containers/content/delete/delete-content-reducer';
import deleteContentVideo from '../containers/content/delete/delete-content-video-reducer';
import getAward from '../containers/about/award/list/award-reducer';
import addAward from '../containers/about/award/create/create-award-reducer';
import deleteAward from '../containers/about/award/delete/delete-award-reducer';
import getPress from '../containers/about/press/list/press-reducer';
import getPressById from '../containers/about/press/list/get-press-reducer';
import addPress from '../containers/about/press/create/create-press-reducer';
import updatePress from '../containers/about/press/update/update-press-reducer';
import deletePress from '../containers/about/press/delete/delete-press-reducer';
import getProduction from '../containers/about/production/list/production-reducer';
import getProductionById from '../containers/about/production/list/get-production-reducer';
import addProduction from '../containers/about/production/create/create-production-reducer';
import updateProduction from '../containers/about/production/update/update-production-reducer';
import deleteProduction from '../containers/about/production/delete/delete-production-reducer';
import getDirectorNames from '../containers/admin-helpers/director-names-reducer';
import getBrandNames from '../containers/admin-helpers/brand-names-reducer';
import getFilmNames from '../containers/admin-helpers/film-names-reducer';
import getTypeOfContents from '../containers/admin-helpers/type-of-contents-reducer';

// Root Reducer.
const reducers = combineReducers({
  login,
  getDashboard,
  getShowReel,
  getShowReelById,
  addShowReel,
  updateShowReel,
  deleteShowReel,
  getPortfolio,
  getPortfolioById,
  addPortfolio,
  updatePortfolio,
  deletePortfolio,
  getDirector,
  getDirectorById,
  addDirector,
  updateDirector,
  deleteDirector,
  getDirectorVideo,
  getDirectorVideoById,
  addDirectorVideo,
  updateDirectorVideo,
  deleteDirectorVideo,
  getContent,
  getContentVideo,
  getContentById,
  getContentVideoById,
  addContent,
  addContentVideo,
  updateContent,
  updateContentVideo,
  deleteContent,
  deleteContentVideo,
  getAward,
  addAward,
  deleteAward,
  getPress,
  getPressById,
  addPress,
  updatePress,
  deletePress,
  getProduction,
  getProductionById,
  addProduction,
  updateProduction,
  deleteProduction,
  getDirectorNames,
  getBrandNames,
  getFilmNames,
  getTypeOfContents
});

export default reducers;
