import * as Toastr from 'toastr';

import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { directorUrlWithId } from '../../../utils/urls';
import { UPDATE_DIRECTOR_REQUEST } from '../director-constants';
import {
  updateDirectorSuccess,
  updateDirectorFailure
} from './update-director-action';

const { patchRequest } = new HttpHelper();

export function* updateDirector({ data }) {
  try {
    const response = yield call(patchRequest, {
      url: directorUrlWithId(data.id),
      data: data.formData
    });

    if (response.error) {
      yield put(updateDirectorFailure());
    } else if (response.data && response.data.status) {
      yield put(updateDirectorSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.directorSuccessCallback();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchUpdateDirector() {
  yield takeLatest(UPDATE_DIRECTOR_REQUEST, updateDirector);
}
