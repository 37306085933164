import {
  DELETE_DIRECTOR_VIDEO_REQUEST,
  DELETE_DIRECTOR_VIDEO_FAILURE,
  DELETE_DIRECTOR_VIDEO_SUCCESS
} from '../director-constants';

const deleteDirectorVideoRequest = (data) => ({
  data,
  type: DELETE_DIRECTOR_VIDEO_REQUEST
});

export const deleteDirectorVideoSuccess = () => ({
  type: DELETE_DIRECTOR_VIDEO_SUCCESS
});

export const deleteDirectorVideoFailure = () => ({
  type: DELETE_DIRECTOR_VIDEO_FAILURE
});

export const deleteDirectorVideo = async (data, dispatch) => {
  dispatch(deleteDirectorVideoRequest(data));
};
