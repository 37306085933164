import {
  ADD_AWARD_REQUEST,
  ADD_AWARD_FAILURE,
  ADD_AWARD_SUCCESS
} from '../award-constants';

const addAwardRequest = (data) => ({
  data,
  type: ADD_AWARD_REQUEST
});

export const addAwardSuccess = () => ({
  type: ADD_AWARD_SUCCESS
});

export const addAwardFailure = () => ({
  type: ADD_AWARD_FAILURE
});

export const addAward = async (data, dispatch) => {
  dispatch(addAwardRequest(data));
};
