import React from 'react';
import { Navbar } from 'react-bootstrap';
import logo from '../../assets/images/logo.svg';


const Loginheader = () => {

  return (
    <div className='header login'>
      <Navbar expand='lg'>
        <div className='header-section'>
          <div className='header-img'>
          <img src={logo} alt='logo' />
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default Loginheader;
