import { call, put, takeLatest } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../../utils/http-helper';
import { directorVideoUrlWithId, sortDirectorVideoUrlWithId } from '../../../utils/urls';
import { GET_DIRECTOR_VIDEO_REQUEST, SORT_DIRECTOR_VIDEO_REQUEST } from '../director-constants';
import {
  getDirectorVideoSuccess,
  getDirectorVideoFailure,
  sortDirectorVideoSuccess,
  sortDirectorVideoFailure
} from './director-video-action';

const { getRequest, postRequest } = new HttpHelper();

export function* getDirectorVideo({ data }) {
  try {
    const response = yield call(getRequest, {
      url: directorVideoUrlWithId(data.id, {
        pageNumber: data.pageNumber,
        pageSize: data.pageSize
      })
    });

    if (response.error) {
      yield put(getDirectorVideoFailure());
    } else {
      yield put(getDirectorVideoSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* sortDirectorVideo({ data }) {
  try {
    const response = yield call(postRequest, {
      url: sortDirectorVideoUrlWithId(data.id),
      data: data.formData
    });

    if (response.error) {
      yield put(sortDirectorVideoFailure());
    } else if (response.data && response.data.status) {
      yield put(sortDirectorVideoSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.updateSuccess();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}


export function* watchGetDirectorVideo() {
  yield takeLatest(GET_DIRECTOR_VIDEO_REQUEST, getDirectorVideo);
}

export function* watchSortDirectorVideo() {
  yield takeLatest(SORT_DIRECTOR_VIDEO_REQUEST, sortDirectorVideo);
}
