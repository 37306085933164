import { all } from 'redux-saga/effects';

import { watchLogin } from '../containers/login/login-saga';
import { watchGetDashboard } from '../containers/dashboard/dashboard-saga';
import { watchGetShowReel } from '../containers/home/showreel/list/showreel-saga';
import { watchGetShowReelById } from '../containers/home/showreel/list/get-showreel-saga';
import { watchAddShowReel } from '../containers/home/showreel/create/create-showreel-saga';
import { watchUpdateShowReel } from '../containers/home/showreel/update/update-showreel-saga';
import { watchDeleteShowReel } from '../containers/home/showreel/delete/delete-showreel-saga';
import { watchGetPortfolio, watchSortPortfolio } from '../containers/home/portfolio/list/portfolio-saga';
import { watchGetPortfolioById } from '../containers/home/portfolio/list/get-portfolio-saga';
import { watchAddPortfolio } from '../containers/home/portfolio/create/create-portfolio-saga';
import { watchUpdatePortfolio } from '../containers/home/portfolio/update/update-portfolio-saga';
import { watchDeletePortfolio } from '../containers/home/portfolio/delete/delete-portfolio-saga';
import { watchGetDirector, watchSortDirector } from '../containers/director/list/director-saga';
import { watchGetDirectorById } from '../containers/director/list/get-director-saga';
import { watchAddDirector } from '../containers/director/create/create-director-saga';
import { watchUpdateDirector } from '../containers/director/update/update-director-saga';
import { watchUpdateDirectorVideo } from '../containers/director/update/update-director-video-saga';
import { watchDeleteDirector } from '../containers/director/delete/delete-director-saga';
import { watchGetDirectorVideo, watchSortDirectorVideo } from '../containers/director/list/director-video-saga';
import { watchGetDirectorVideoById } from '../containers/director/list/get-director-video-saga';
import { watchAddDirectorVideo } from '../containers/director/create/create-director-video-saga';
import { watchDeleteDirectorVideo } from '../containers/director/delete/delete-director-video-saga';
import { watchGetContent, watchSortContent } from '../containers/content/list/content-saga';
import { watchGetContentVideo } from '../containers/content/list/content-video-saga';
import { watchGetContentById } from '../containers/content/list/get-content-saga';
import { watchGetContentVideoById } from '../containers/content/list/get-content-video-saga';
import { watchAddContent } from '../containers/content/create/create-content-saga';
import { watchAddContentVideo } from '../containers/content/create/create-content-video-saga';
import { watchUpdateContent } from '../containers/content/update/update-content-saga';
import { watchUpdateContentVideo } from '../containers/content/update/update-content-video-saga';
import { watchDeleteContent } from '../containers/content/delete/delete-content-saga';
import { watchDeleteContentVideo } from '../containers/content/delete/delete-content-video-saga';
import { watchGetAward, watchSortAward } from '../containers/about/award/list/award-saga';
import { watchAddAward } from '../containers/about/award/create/create-award-saga';
import { watchDeleteAward } from '../containers/about/award/delete/delete-award-saga';
import { watchGetPress } from '../containers/about/press/list/press-saga';
import { watchGetPressById } from '../containers/about/press/list/get-press-saga';
import { watchAddPress } from '../containers/about/press/create/create-press-saga';
import { watchUpdatePress } from '../containers/about/press/update/update-press-saga';
import { watchDeletePress } from '../containers/about/press/delete/delete-press-saga';
import { watchGetProduction, watchSortProduction } from '../containers/about/production/list/production-saga';
import { watchGetProductionById } from '../containers/about/production/list/get-production-saga';
import { watchAddProduction } from '../containers/about/production/create/create-production-saga';
import { watchUpdateProduction } from '../containers/about/production/update/update-production-saga';
import { watchDeleteProduction } from '../containers/about/production/delete/delete-production-saga';
import { watchGetDirectorNames } from '../containers/admin-helpers/director-names-saga';
import { watchGetBrandNames } from '../containers/admin-helpers/brand-names-saga';
import { watchGetFilmNames } from '../containers/admin-helpers/film-names-saga';
import { watchGetTypeOfContents } from '../containers/admin-helpers/type-of-contents-saga';

const sagas = function* sagas() {
  yield all([
    watchLogin(),
    watchGetDashboard(),
    watchGetShowReel(),
    watchGetShowReelById(),
    watchAddShowReel(),
    watchUpdateShowReel(),
    watchDeleteShowReel(),
    watchGetPortfolio(),
    watchSortPortfolio(),
    watchGetPortfolioById(),
    watchAddPortfolio(),
    watchUpdatePortfolio(),
    watchDeletePortfolio(),
    watchGetDirector(),
    watchSortDirector(),
    watchGetDirectorById(),
    watchAddDirector(),
    watchUpdateDirector(),
    watchDeleteDirector(),
    watchGetDirectorVideo(),
    watchSortDirectorVideo(),
    watchGetDirectorVideoById(),
    watchAddDirectorVideo(),
    watchUpdateDirectorVideo(),
    watchDeleteDirectorVideo(),
    watchGetContent(),
    watchSortContent(),
    watchGetContentVideo(),
    watchGetContentById(),
    watchGetContentVideoById(),
    watchAddContent(),
    watchAddContentVideo(),
    watchUpdateContent(),
    watchUpdateContentVideo(),
    watchDeleteContent(),
    watchDeleteContentVideo(),
    watchGetAward(),
    watchSortAward(),
    watchAddAward(),
    watchDeleteAward(),
    watchGetPress(),
    watchGetPressById(),
    watchAddPress(),
    watchUpdatePress(),
    watchDeletePress(),
    watchGetProduction(),
    watchSortProduction(),
    watchGetProductionById(),
    watchAddProduction(),
    watchUpdateProduction(),
    watchDeleteProduction(),
    watchGetDirectorNames(),
    watchGetBrandNames(),
    watchGetFilmNames(),
    watchGetTypeOfContents()
  ]);
};

export default sagas;
