export const GET_PRODUCTION_REQUEST = 'GET_PRODUCTION_REQUEST';
export const GET_PRODUCTION_SUCCESS = 'GET_PRODUCTION_SUCCESS';
export const GET_PRODUCTION_FAILURE = 'GET_PRODUCTION_FAILURE';

export const GET_PRODUCTION_BY_ID_REQUEST = 'GET_PRODUCTION_BY_ID_REQUEST';
export const GET_PRODUCTION_BY_ID_SUCCESS = 'GET_PRODUCTION_BY_ID_SUCCESS';
export const GET_PRODUCTION_BY_ID_FAILURE = 'GET_PRODUCTION_BY_ID_FAILURE';

export const ADD_PRODUCTION_REQUEST = 'ADD_PRODUCTION_REQUEST';
export const ADD_PRODUCTION_SUCCESS = 'ADD_PRODUCTION_SUCCESS';
export const ADD_PRODUCTION_FAILURE = 'ADD_PRODUCTION_FAILURE';

export const UPDATE_PRODUCTION_REQUEST = 'UPDATE_PRODUCTION_REQUEST';
export const UPDATE_PRODUCTION_SUCCESS = 'UPDATE_PRODUCTION_SUCCESS';
export const UPDATE_PRODUCTION_FAILURE = 'UPDATE_PRODUCTION_FAILURE';

export const DELETE_PRODUCTION_REQUEST = 'DELETE_PRODUCTION_REQUEST';
export const DELETE_PRODUCTION_SUCCESS = 'DELETE_PRODUCTION_SUCCESS';
export const DELETE_PRODUCTION_FAILURE = 'DELETE_PRODUCTION_FAILURE';

export const SORT_PRODUCTION_REQUEST = 'SORT_PRODUCTION_REQUEST';
export const SORT_PRODUCTION_SUCCESS = 'SORT_PRODUCTION_SUCCESS';
export const SORT_PRODUCTION_FAILURE = 'SORT_PRODUCTION_FAILURE';
