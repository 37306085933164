import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { pressUrlWithId } from '../../../../utils/urls';
import { GET_PRESS_BY_ID_REQUEST } from '../press-constants';
import { getPressByIdSuccess, getPressByIdFailure } from './get-press-action';

const { getRequest } = new HttpHelper();

export function* getPressById({ data }) {
  try {
    const response = yield call(getRequest, {
      url: pressUrlWithId(data.id)
    });

    if (response.error) {
      yield put(getPressByIdFailure());
    } else {
      yield put(getPressByIdSuccess(response.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetPressById() {
  yield takeLatest(GET_PRESS_BY_ID_REQUEST, getPressById);
}
