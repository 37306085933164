import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSortable } from "react-sortablejs";

import { Button } from 'react-bootstrap';
import plus from '../../../../assets/images/plus.png';

import { getPortfolio, sortPortfolio } from './../list/portfolio-action';

import Layout from '../../../../components/layout';

const SortPortfolio = ({ history }) => {
  const [currentPageNo] = useState(1);
  const [pageSize] = useState(10000);

  const dispatch = useDispatch();

  const getPortfolioDetails = (currentPageNo, pageSize) => {
    getPortfolio({ pageNumber: currentPageNo, pageSize: pageSize }, dispatch);
  };

  useEffect(() => {
    getPortfolioDetails(currentPageNo, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { portfolioList, isLoading } = useSelector(
    (state) => state.getPortfolio
  );

  const updateSuccess = () => {
    history.push('/portfolio/sort');
  };

  const getOrder = (list) => {
    const ids = [];
    for (let i = 0; i < list.length; i++) {
      const elem = list[i];
      ids.push(elem.id);
    }
    return ids;
  }

  const updatePortfolioList = (list) => {
    let data = new FormData();
    data.append('order', getOrder(list));

    sortPortfolio({
      data: list,
      formData: data,
      updateSuccess: updateSuccess
    }, dispatch);
  }

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          {!isLoading ? (
            <div className="video-list-container list-container">
              <div className="video-list-head">
                <div>
                  <h2>Portfolio List Re-Order</h2>
                </div>
                <div>
                  <Button
                    className="black-btn halfwidth"
                    onClick={() => history.push('/portfolio/create')}
                  >
                    Add New
                    <span>
                      <img src={plus} alt="plus" />
                    </span>
                  </Button>
                </div>
              </div>
              <div className="director-list-section">
                <div className="director-list">
                  <p>FILM NAME</p>
                  <span>|</span>
                  <p class="m-l-20">DIRECTOR NAME</p>
                  <span>|</span>
                  <p class="m-l-20">BRAND NAME</p>
                </div>
              </div>
              <hr />
              <ReactSortable list={portfolioList} setList={(newState) => updatePortfolioList(newState)}>
                {portfolioList &&
                  portfolioList.map((portfolio) => (
                    <div className="director-list-section" key={portfolio.id}>
                      <div className="director-list">
                        <p>{portfolio.film_name}</p>
                        <span>|</span>
                        <p class="m-l-20">{portfolio.director_name}</p>
                        <span>|</span>
                        <p class="m-l-20">{portfolio.brand_name}</p>
                      </div>
                    </div>
                  ))}
              </ReactSortable>
            </div>
          ) : (
            <p style={{ color: '#000' }}>Loading...</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SortPortfolio;
