import {
  DELETE_CONTENT_VIDEO_REQUEST,
  DELETE_CONTENT_VIDEO_FAILURE,
  DELETE_CONTENT_VIDEO_SUCCESS
} from '../content-constants';

const initialState = {
  isLoading: false
};

const deleteContentVideo = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_CONTENT_VIDEO_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case DELETE_CONTENT_VIDEO_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case DELETE_CONTENT_VIDEO_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default deleteContentVideo;
