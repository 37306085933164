import {
  GET_DIRECTOR_VIDEO_REQUEST,
  GET_DIRECTOR_VIDEO_FAILURE,
  GET_DIRECTOR_VIDEO_SUCCESS,
  SORT_DIRECTOR_VIDEO_REQUEST,
  SORT_DIRECTOR_VIDEO_FAILURE,
  SORT_DIRECTOR_VIDEO_SUCCESS
} from '../director-constants';

const getDirectorVideoRequest = (data) => ({
  data,
  type: GET_DIRECTOR_VIDEO_REQUEST
});

export const getDirectorVideoSuccess = (data) => ({
  data,
  type: GET_DIRECTOR_VIDEO_SUCCESS
});

export const getDirectorVideoFailure = () => ({
  type: GET_DIRECTOR_VIDEO_FAILURE
});

export const getDirectorVideo = async (data, dispatch) => {
  dispatch(getDirectorVideoRequest(data));
};

const sortDirectorVideoRequest = (data) => ({
  data,
  type: SORT_DIRECTOR_VIDEO_REQUEST
});

export const sortDirectorVideoSuccess = (data) => ({
  data,
  type: SORT_DIRECTOR_VIDEO_SUCCESS
});

export const sortDirectorVideoFailure = () => ({
  type: SORT_DIRECTOR_VIDEO_FAILURE
});

export const sortDirectorVideo = async (data, dispatch) => {
  dispatch(sortDirectorVideoRequest(data));
};
