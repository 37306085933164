import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../../../components/layout';
import PressForm from '../press-form';
import { addPress } from './create-press-action';

const CreatePress = ({ history }) => {
  const [addPressDetails, setAddPressDetails] = useState({
    title: '',
    mediaName: '',
    link: ''
  });

  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.addPress);

  const pressSuccessCallback = () => {
    history.push('/press');
  };

  const handleCreatePress = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const { title, mediaName, link } = addPressDetails;

    if (title && mediaName && link) {
      let pressFormData = new FormData();
      pressFormData.append('title', title);
      pressFormData.append('media_name', mediaName);
      pressFormData.append('link', link);

      addPress(
        {
          formData: pressFormData,
          pressSuccessCallback
        },
        dispatch
      );
    }
  };

  const handleCancel = () => {
    history.push('/press');
  };
  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <PressForm
            pressDetails={addPressDetails}
            setPressDetails={setAddPressDetails}
            validated={validated}
            handleCancel={handleCancel}
            handleSubmitPress={handleCreatePress}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default CreatePress;
