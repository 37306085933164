import {
  DELETE_AWARD_REQUEST,
  DELETE_AWARD_FAILURE,
  DELETE_AWARD_SUCCESS
} from '../award-constants';

const deleteAwardRequest = (data) => ({
  data,
  type: DELETE_AWARD_REQUEST
});

export const deleteAwardSuccess = () => ({
  type: DELETE_AWARD_SUCCESS
});

export const deleteAwardFailure = () => ({
  type: DELETE_AWARD_FAILURE
});

export const deleteAward = async (data, dispatch) => {
  dispatch(deleteAwardRequest(data));
};
