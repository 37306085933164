import {
  GET_DIRECTOR_VIDEO_BY_ID_REQUEST,
  GET_DIRECTOR_VIDEO_BY_ID_FAILURE,
  GET_DIRECTOR_VIDEO_BY_ID_SUCCESS
} from '../director-constants';

const initialState = {
  isEditDirectorVideoLoading: false,
  directorVideoData: {}
};

const getDirectorVideoById = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIRECTOR_VIDEO_BY_ID_REQUEST:
      return {
        ...state,
        isEditDirectorVideoLoading: true,
        directorVideoData: {}
      };
    case GET_DIRECTOR_VIDEO_BY_ID_FAILURE:
      return {
        ...state,
        isEditDirectorVideoLoading: false,
        directorVideoData: {}
      };
    case GET_DIRECTOR_VIDEO_BY_ID_SUCCESS:
      return {
        ...state,
        isEditDirectorVideoLoading: false,
        directorVideoData: action.data
      };
    default:
      return state;
  }
};

export default getDirectorVideoById;
