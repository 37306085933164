import React, { Fragment } from 'react';
import { Form, Button } from 'react-bootstrap';

import FileInput from '../../../components/form-fields/file-input';
import arrow from '../../../assets/images/arrow.png';
import ButtonLoader from '../../../assets/images/button-loader.svg';

const ShowReelForm = ({
  showReelDetails,
  setShowReelDetails,
  validated,
  handleCancel,
  handleSubmitShowReel,
  isLoading,
  noData,
  isEditShowReelLoading
}) => {
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSubmitShowReel(e);
    }
  };

  const formFileds = [
    {
      type: 'file',
      label: 'Upload Thumbnail Image',
      heading: 'Thumbnail Image',
      value: showReelDetails.thumbnailImage,
      onClickFile: () =>
        setShowReelDetails({
          ...showReelDetails,
          thumbnailImage: null
        }),
      onChangeFile: (e) =>
        setShowReelDetails({
          ...showReelDetails,
          thumbnailImage: e.target.files[0]
        }),
      feedback: 'Please upload thumbnail image.',
      validated: validated
    },
    {
      type: 'fileVideo',
      label: 'Upload Thumbnail Video',
      heading: 'Thumbnail Video',
      value: showReelDetails.videoUrl,
      onClickFile: () =>
        setShowReelDetails({
          ...showReelDetails,
          videoUrl: null
        }),
      onChangeFile: (e) =>
        setShowReelDetails({
          ...showReelDetails,
          videoUrl: e.target.files[0]
        }),
      feedback: 'Please upload thumbnail video.',
      validated: validated
    }
  ];

  const renderFormFields = (input) => {
    switch (input.type) {
      case 'file':
      case 'fileVideo':
        return (
          <FileInput
            label={input.label}
            heading={input.heading}
            value={input.value}
            onClickFile={input.onClickFile}
            onChangeFile={input.onChangeFile}
            feedback={input.feedback}
            validated={input.validated}
            type={input.type}
          />
        );          
      default:
        return '';
    }
  };

  return !isEditShowReelLoading ? (
    <div className="form-container">
      <div className="back-arrow" onClick={handleCancel}>
        <img src={arrow} alt="arrow" />
      </div>
      <div className="head">
        <h4>Show Reel Form</h4>
        <p>Enter your details below</p>
      </div>
      {!noData ? (
        <>
          <Form onKeyDown={handleKeyDown} noValidate validated={validated}>
            {formFileds.map((input, index) => (
              <Fragment key={index}>{renderFormFields(input)}</Fragment>
            ))}
          </Form>

          <div className="bottom-btn">
            <Button className="black-btn white width" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="black-btn width" onClick={handleSubmitShowReel}>
              {!isLoading ? (
                'Submit'
              ) : (
                <>
                  <img src={ButtonLoader} alt="button-loader" />
                  <span className="laoder-span">Loading....</span>
                </>
              )}
            </Button>
          </div>
        </>
      ) : (
        <Form>No data found</Form>
      )}
    </div>
  ) : (
    <p style={{ color: '#000' }}>Loading...</p>
  );
};

export default ShowReelForm;
