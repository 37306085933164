import {
  GET_CONTENT_VIDEO_BY_ID_REQUEST,
  GET_CONTENT_VIDEO_BY_ID_FAILURE,
  GET_CONTENT_VIDEO_BY_ID_SUCCESS
} from '../content-constants';

const initialState = {
  isEditContentVideoLoading: false,
  contentVideoData: {}
};

const getContentVideoById = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTENT_VIDEO_BY_ID_REQUEST:
      return {
        ...state,
        isEditContentVideoLoading: true,
        contentVideoData: {}
      };
    case GET_CONTENT_VIDEO_BY_ID_FAILURE:
      return {
        ...state,
        isEditContentVideoLoading: false,
        contentVideoData: {}
      };
    case GET_CONTENT_VIDEO_BY_ID_SUCCESS:
      return {
        ...state,
        isEditContentVideoLoading: false,
        contentVideoData: action.data
      };
    default:
      return state;
  }
};

export default getContentVideoById;
