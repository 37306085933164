import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSortable } from "react-sortablejs";

import { getDirectorVideo, sortDirectorVideo } from './director-video-action';
import Layout from '../../../components/layout';

const SortDirectorVideo = ({ match, history }) => {
  const [currentPageNo] = useState(1);
  const [pageSize] = useState(10000);

  const dispatch = useDispatch();

  const getDirectorVideoDetails = (currentPageNo, pageSize) => {
    getDirectorVideo(
      { id: match.params.id, pageNumber: currentPageNo, pageSize: pageSize },
      dispatch
    );
  };

  useEffect(() => {
    getDirectorVideoDetails(currentPageNo, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { directorVideoList, isLoading } = useSelector(
    (state) => state.getDirectorVideo
  );

  const updateSuccess = () => { };

  const getOrder = (list) => {
    const ids = [];
    for (let i = 0; i < list.length; i++) {
      const elem = list[i];
      ids.push(elem.id);
    }
    return ids;
  }

  const updateDirectorVideoList = (list) => {
    let data = new FormData();
    data.append('order', getOrder(list));

    sortDirectorVideo({
      data: list,
      formData: data,
      id: match.params.id,
      updateSuccess: updateSuccess
    }, dispatch);
  }

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          {!isLoading ? (
            <div className="video-list-container list-container">
              <div className="video-list-head">
                <div>
                  <h2>Director Video List Re-Order</h2>
                </div>
                <div>
                </div>
              </div>
              <div className="director-list-section">
                <div className="director-list">
                  <p>FILM NAME</p>
                  <span>|</span>
                  <p className="m-l-20">BRAND NAME</p>
                  {/* <span>|</span>
                  <p class="m-l-20">BRAND NAME</p> */}
                </div>
              </div>
              <hr />
              <ReactSortable list={directorVideoList} setList={(newState) => updateDirectorVideoList(newState)}>
                {directorVideoList &&
                  directorVideoList.map((item) => (
                    <div className="director-list-section" key={item.id}>
                      <div className="director-list">
                        <p>{item.film_name}</p>
                        <span>|</span>
                        <p className="m-l-20">{item.brand_name}</p>
                        {/* <span>|</span>
                        <p class="m-l-20">{item.brand_name}</p> */}
                      </div>
                    </div>
                  ))}
              </ReactSortable>
            </div>
          ) : (
            <p style={{ color: '#000' }}>Loading...</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SortDirectorVideo;
