import {
  ADD_CONTENT_REQUEST,
  ADD_CONTENT_FAILURE,
  ADD_CONTENT_SUCCESS
} from '../content-constants';

const addContentRequest = (data) => ({
  data,
  type: ADD_CONTENT_REQUEST
});

export const addContentSuccess = () => ({
  type: ADD_CONTENT_SUCCESS
});

export const addContentFailure = () => ({
  type: ADD_CONTENT_FAILURE
});

export const addContent = async (data, dispatch) => {
  dispatch(addContentRequest(data));
};
