import {
  UPDATE_PRODUCTION_REQUEST,
  UPDATE_PRODUCTION_FAILURE,
  UPDATE_PRODUCTION_SUCCESS
} from '../production-constants';

const initialState = {
  isLoading: false
};

const updateProduction = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PRODUCTION_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case UPDATE_PRODUCTION_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case UPDATE_PRODUCTION_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default updateProduction;
