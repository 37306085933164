import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { ReactSortable } from "react-sortablejs";

import { getAward, sortAward } from './award-action';
import { deleteAward } from '../delete/delete-award-action';

import Layout from '../../../../components/layout';
import DeletePopup from '../../../../components/modal/delete-popup';

import plus from '../../../../assets/images/plus.png';

const Award = ({ history }) => {
  const [awardId, setAwardId] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [currentPageNo] = useState(1);
  const [pageSize] = useState(10000);

  const dispatch = useDispatch();

  const getAwardDetails = (currentPageNo, pageSize) => {
    getAward({ pageNumber: currentPageNo, pageSize: pageSize }, dispatch);
  };

  useEffect(() => {
    getAwardDetails(currentPageNo, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { awardList, isLoading } = useSelector(
    (state) => state.getAward
  );

  const deleteAwardSuccess = () => {
    setShowDeletePopup(false);
    getAwardDetails(currentPageNo, pageSize);
  };

  const handelDelete = () => {
    deleteAward({ id: awardId, deleteSuccess: deleteAwardSuccess }, dispatch);
  };

  const updateSuccess = () => {
    history.push('/awards');
  };

  const getOrder = (list) => {
    const ids = [];
    for (let i = 0; i < list.length; i++) {
      const elem = list[i];
      ids.push(elem.id);
    }
    return ids;
  }

  const updateList = (list) => {
    let data = new FormData();
    data.append('order', getOrder(list));

    sortAward({
      data: list,
      formData: data,
      updateSuccess: updateSuccess
    }, dispatch);
  }

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          {!isLoading ? (
            <div className="video-list-container awards">
              <div className="video-list-head">
                <div>
                  <h2>Awards Logo List</h2>
                </div>
                <div>
                  <Button
                    className="black-btn halfwidth"
                    onClick={() => history.push('/award/create')}
                  >
                    Add New
                    <span>
                      <img src={plus} alt="plus" />
                    </span>
                  </Button>
                </div>
              </div>
              <div className="award-logo">
                <ReactSortable list={awardList} setList={(sortedList) => updateList(sortedList)}>
                {awardList &&
                  awardList.map((award) => (
                    <div className="video-card" key={award.id}>
                      <div className="video-list-img">
                        <img src={award.image} alt="img" />
                      </div>
                      <div className="video-desc">
                        <div className="btn-section">
                          <Button
                            className="black-btn transparent red"
                            onClick={() => {
                              setShowDeletePopup(true);
                              setAwardId(award.id);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                  </ReactSortable>
              </div>
            </div>
          ) : (
            <p style={{ color: '#000' }}>Loading...</p>
          )}
        </div>
      </div>
      <DeletePopup
        show={showDeletePopup}
        handleClose={() => setShowDeletePopup(false)}
        handelDelete={handelDelete}
      />
    </Layout>
  );
};

export default Award;
