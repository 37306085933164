import {
  GET_DIRECTOR_NAMES_REQUEST,
  GET_DIRECTOR_NAMES_FAILURE,
  GET_DIRECTOR_NAMES_SUCCESS
} from './admin-helper-constants';

const getDirectorNamesRequest = () => ({
  type: GET_DIRECTOR_NAMES_REQUEST
});

export const getDirectorNamesSuccess = (data) => ({
  data,
  type: GET_DIRECTOR_NAMES_SUCCESS
});

export const getDirectorNamesFailure = () => ({
  type: GET_DIRECTOR_NAMES_FAILURE
});

export const getDirectorNames = async (dispatch) => {
  dispatch(getDirectorNamesRequest());
};
