import {
  DELETE_CONTENT_REQUEST,
  DELETE_CONTENT_FAILURE,
  DELETE_CONTENT_SUCCESS
} from '../content-constants';

const deleteContentRequest = (data) => ({
  data,
  type: DELETE_CONTENT_REQUEST
});

export const deleteContentSuccess = () => ({
  type: DELETE_CONTENT_SUCCESS
});

export const deleteContentFailure = () => ({
  type: DELETE_CONTENT_FAILURE
});

export const deleteContent = async (data, dispatch) => {
  dispatch(deleteContentRequest(data));
};
