import {
  ADD_PORTFOLIO_REQUEST,
  ADD_PORTFOLIO_FAILURE,
  ADD_PORTFOLIO_SUCCESS
} from '../portfolio-constants';

const initialState = {
  isLoading: false
};

const addPortfolio = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PORTFOLIO_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ADD_PORTFOLIO_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case ADD_PORTFOLIO_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default addPortfolio;
