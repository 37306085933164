import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { getDirectorVideoById } from '../list/get-director-video-action';

import Layout from '../../../components/layout';
import DirectorVideoForm from '../director-video-form';
import { updateDirectorVideo } from './update-director-video-action';

const UpdateDirectorVideo = ({ history, match }) => {
  const [updateDirectorVideoDetails, setUpdateDirectorVideoDetails] = useState({
    thumbnailImage: null,
    thumbnailVideo: null,
    video: '',
    filmName: '',
    brandName: '',
    videoType: '',
    agency: '',
    awards: []
  });
  const [validated, setValidated] = useState(false);
  const [noData, setnoData] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.updateDirectorVideo);

  useEffect(() => {
    if (match.params.id) {
      getDirectorVideoById({ id: match.params.id }, dispatch);
    }
  }, [match.params, dispatch]);

  const { directorVideoData, isEditDirectorVideoLoading } = useSelector(
    (state) => state.getDirectorVideoById
  );

  useEffect(() => {
    if (Object.keys(directorVideoData).length) {
      setUpdateDirectorVideoDetails({
        thumbnailImage: directorVideoData.thumbnail_image || null,
        thumbnailVideo: directorVideoData.thumbnail_video || null,
        video: directorVideoData.video || '',
        filmName: directorVideoData.film_name || '',
        brandName: directorVideoData.brand_name || '',
        videoType: directorVideoData.video_type || '',
        agency: directorVideoData.agency || '',
        awards: directorVideoData.awards ? directorVideoData.awards.split(',') : []
      });
      setnoData(false);
    } else {
      setnoData(true);
    }
  }, [directorVideoData]);

  const directorVideoSuccessCallback = () => {
    history.push(`/director/${directorVideoData.director_id}/list`);
  };

  const handleUpdateDirectorVideo = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const {
      thumbnailImage,
      thumbnailVideo,
      video,
      filmName,
      brandName,
      videoType,
      agency,
      awards
    } = updateDirectorVideoDetails;

    if (thumbnailImage && thumbnailVideo && video && filmName && videoType) {
      let directorVideoFormData = new FormData();      
      directorVideoFormData.append('video', video);
      directorVideoFormData.append('video_type', videoType);
      directorVideoFormData.append('film_name', filmName);
      directorVideoFormData.append('brand_name', brandName);
      directorVideoFormData.append('agency', agency);
      directorVideoFormData.append('awards', awards);

      if (thumbnailImage && typeof thumbnailImage === 'object') {
        directorVideoFormData.append('thumbnail_image', thumbnailImage);
      }

      if (thumbnailVideo && typeof thumbnailVideo === 'object') {
        directorVideoFormData.append('thumbnail_video', thumbnailVideo);
      }

      updateDirectorVideo(
        {
          id: match.params.id,
          formData: directorVideoFormData,
          updateSuccess: directorVideoSuccessCallback
        },
        dispatch
      );
    }
  };

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <DirectorVideoForm
            directorVideoDetails={updateDirectorVideoDetails}
            setDirectorVideoDetails={setUpdateDirectorVideoDetails}
            validated={validated}
            handleCancel={() =>
              history.push(`/director/${directorVideoData.director_id}/list`)
            }
            handleSubmitDirectorVideo={handleUpdateDirectorVideo}
            noData={noData}
            isLoading={isLoading}
            isEditDirectorVideoLoading={isEditDirectorVideoLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default UpdateDirectorVideo;
