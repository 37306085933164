import {
  UPDATE_PRODUCTION_REQUEST,
  UPDATE_PRODUCTION_FAILURE,
  UPDATE_PRODUCTION_SUCCESS
} from '../production-constants';

const updateProductionRequest = (data) => ({
  data,
  type: UPDATE_PRODUCTION_REQUEST
});

export const updateProductionSuccess = () => ({
  type: UPDATE_PRODUCTION_SUCCESS
});

export const updateProductionFailure = () => ({
  type: UPDATE_PRODUCTION_FAILURE
});

export const updateProduction = async (data, dispatch) => {
  dispatch(updateProductionRequest(data));
};
