import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Layout from '../../../../components/layout';
import ProductionForm from '../production-form';
import { addProduction } from './create-production-action';

const CreateProduction = ({ history }) => {
  const [addProductionDetails, setAddProductionDetails] = useState({
    thumbnailImage: null,
    thumbnailVideo: null,
    video: '',
    name: '',
    filmName: '',
    website: '',
    brandName: '',
    directorName: ''
  });

  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.addProduction);

  const productionSuccessCallback = () => {
    history.push('/production');
  };

  const handleCreateProduction = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true);
    const {
      thumbnailImage,
      thumbnailVideo,
      video,
      name,
      filmName,
      website,
      brandName,
      directorName
    } = addProductionDetails;

    if (
      thumbnailImage &&
      thumbnailVideo &&
      video &&
      name &&
      filmName &&
      website &&
      directorName
    ) {
      let productionFormData = new FormData();
      productionFormData.append('thumbnail_image', thumbnailImage);
      productionFormData.append('thumbnail_video', thumbnailVideo);
      productionFormData.append('video', video);
      productionFormData.append('name', name);
      productionFormData.append('type_of_content', filmName);
      productionFormData.append('website', website);
      productionFormData.append('brand_name', brandName);
      productionFormData.append('director_name', directorName);

      addProduction(
        {
          formData: productionFormData,
          productionSuccessCallback
        },
        dispatch
      );
    }
  };

  const handleCancel = () => {
    history.push('/production');
  };
  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          <ProductionForm
            productionDetails={addProductionDetails}
            setProductionDetails={setAddProductionDetails}
            validated={validated}
            handleCancel={handleCancel}
            handleSubmitProduction={handleCreateProduction}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default CreateProduction;
