import {
  UPDATE_CONTENT_REQUEST,
  UPDATE_CONTENT_FAILURE,
  UPDATE_CONTENT_SUCCESS
} from '../content-constants';

const updateContentRequest = (data) => ({
  data,
  type: UPDATE_CONTENT_REQUEST
});

export const updateContentSuccess = () => ({
  type: UPDATE_CONTENT_SUCCESS
});

export const updateContentFailure = () => ({
  type: UPDATE_CONTENT_FAILURE
});

export const updateContent = async (data, dispatch) => {
  dispatch(updateContentRequest(data));
};
