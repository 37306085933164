import {
  UPDATE_DIRECTOR_REQUEST,
  UPDATE_DIRECTOR_FAILURE,
  UPDATE_DIRECTOR_SUCCESS
} from '../director-constants';

const updateDirectorRequest = (data) => ({
  data,
  type: UPDATE_DIRECTOR_REQUEST
});

export const updateDirectorSuccess = () => ({
  type: UPDATE_DIRECTOR_SUCCESS
});

export const updateDirectorFailure = () => ({
  type: UPDATE_DIRECTOR_FAILURE
});

export const updateDirector = async (data, dispatch) => {
  dispatch(updateDirectorRequest(data));
};
