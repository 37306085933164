import {
  GET_PRESS_REQUEST,
  GET_PRESS_FAILURE,
  GET_PRESS_SUCCESS
} from '../press-constants';

const getPressRequest = (data) => ({
  data,
  type: GET_PRESS_REQUEST
});

export const getPressSuccess = (data) => ({
  data,
  type: GET_PRESS_SUCCESS
});

export const getPressFailure = () => ({
  type: GET_PRESS_FAILURE
});

export const getPress = async (data, dispatch) => {
  dispatch(getPressRequest(data));
};
