import {
  DELETE_DIRECTOR_REQUEST,
  DELETE_DIRECTOR_FAILURE,
  DELETE_DIRECTOR_SUCCESS
} from '../director-constants';

const deleteDirectorRequest = (data) => ({
  data,
  type: DELETE_DIRECTOR_REQUEST
});

export const deleteDirectorSuccess = () => ({
  type: DELETE_DIRECTOR_SUCCESS
});

export const deleteDirectorFailure = () => ({
  type: DELETE_DIRECTOR_FAILURE
});

export const deleteDirector = async (data, dispatch) => {
  dispatch(deleteDirectorRequest(data));
};
