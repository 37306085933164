import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { awardUrlWithId } from '../../../../utils/urls';
import { DELETE_AWARD_REQUEST } from '../award-constants';
import { deleteAwardSuccess, deleteAwardFailure } from './delete-award-action';

const { deleteRequest } = new HttpHelper();

export function* deleteAward({ data }) {
  try {
    const response = yield call(deleteRequest, {
      url: awardUrlWithId(data.id)
    });

    if (response.error) {
      yield put(deleteAwardFailure());
    } else {
      data.deleteSuccess();
      yield put(deleteAwardSuccess());
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchDeleteAward() {
  yield takeLatest(DELETE_AWARD_REQUEST, deleteAward);
}
