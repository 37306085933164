export const GET_DIRECTOR_NAMES_REQUEST = 'GET_DIRECTOR_NAMES_REQUEST';
export const GET_DIRECTOR_NAMES_SUCCESS = 'GET_DIRECTOR_NAMES_SUCCESS';
export const GET_DIRECTOR_NAMES_FAILURE = 'GET_DIRECTOR_NAMES_FAILURE';

export const GET_FILM_NAMES_REQUEST = 'GET_FILM_NAMES_REQUEST';
export const GET_FILM_NAMES_SUCCESS = 'GET_FILM_NAMES_SUCCESS';
export const GET_FILM_NAMES_FAILURE = 'GET_FILM_NAMES_FAILURE';

export const GET_BRAND_NAMES_REQUEST = 'GET_BRAND_NAMES_REQUEST';
export const GET_BRAND_NAMES_SUCCESS = 'GET_BRAND_NAMES_SUCCESS';
export const GET_BRAND_NAMES_FAILURE = 'GET_BRAND_NAMES_FAILURE';

export const GET_TYPE_OF_CONTENTS_REQUEST = 'GET_TYPE_OF_CONTENTS_REQUEST';
export const GET_TYPE_OF_CONTENTS_SUCCESS = 'GET_TYPE_OF_CONTENTS_SUCCESS';
export const GET_TYPE_OF_CONTENTS_FAILURE = 'GET_TYPE_OF_CONTENTS_FAILURE';
