export const GET_PRESS_REQUEST = 'GET_PRESS_REQUEST';
export const GET_PRESS_SUCCESS = 'GET_PRESS_SUCCESS';
export const GET_PRESS_FAILURE = 'GET_PRESS_FAILURE';

export const GET_PRESS_BY_ID_REQUEST = 'GET_PRESS_BY_ID_REQUEST';
export const GET_PRESS_BY_ID_SUCCESS = 'GET_PRESS_BY_ID_SUCCESS';
export const GET_PRESS_BY_ID_FAILURE = 'GET_PRESS_BY_ID_FAILURE';

export const ADD_PRESS_REQUEST = 'ADD_PRESS_REQUEST';
export const ADD_PRESS_SUCCESS = 'ADD_PRESS_SUCCESS';
export const ADD_PRESS_FAILURE = 'ADD_PRESS_FAILURE';

export const UPDATE_PRESS_REQUEST = 'UPDATE_PRESS_REQUEST';
export const UPDATE_PRESS_SUCCESS = 'UPDATE_PRESS_SUCCESS';
export const UPDATE_PRESS_FAILURE = 'UPDATE_PRESS_FAILURE';

export const DELETE_PRESS_REQUEST = 'DELETE_PRESS_REQUEST';
export const DELETE_PRESS_SUCCESS = 'DELETE_PRESS_SUCCESS';
export const DELETE_PRESS_FAILURE = 'DELETE_PRESS_FAILURE';
