import {
  ADD_DIRECTOR_VIDEO_REQUEST,
  ADD_DIRECTOR_VIDEO_FAILURE,
  ADD_DIRECTOR_VIDEO_SUCCESS
} from '../director-constants';

const addDirectorVideoRequest = (data) => ({
  data,
  type: ADD_DIRECTOR_VIDEO_REQUEST
});

export const addDirectorVideoSuccess = () => ({
  type: ADD_DIRECTOR_VIDEO_SUCCESS
});

export const addDirectorVideoFailure = () => ({
  type: ADD_DIRECTOR_VIDEO_FAILURE
});

export const addDirectorVideo = async (data, dispatch) => {
  dispatch(addDirectorVideoRequest(data));
};
