import {
  GET_PRODUCTION_REQUEST,
  GET_PRODUCTION_FAILURE,
  GET_PRODUCTION_SUCCESS,
  SORT_PRODUCTION_REQUEST,
  SORT_PRODUCTION_FAILURE,
  SORT_PRODUCTION_SUCCESS
} from '../production-constants';

const initialState = {
  isLoading: false,
  productionList: []
};

const getProduction = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        productionList: []
      };
    case GET_PRODUCTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        productionList: []
      };
    case GET_PRODUCTION_SUCCESS:
      const { data, totalRecords } = action.data;
      return {
        ...state,
        isLoading: false,
        productionList: data,
        totalRecords
      };
    case SORT_PRODUCTION_REQUEST:
      const sortedList = [...action.data.data];
      return {
        ...state,
        isSorting: true,
        productionList: sortedList
      };
    case SORT_PRODUCTION_FAILURE:
    case SORT_PRODUCTION_SUCCESS:
      return {
        ...state,
        isSorting: false
      };  
    default:
      return state;
  }
};

export default getProduction;
