import {
  GET_BRAND_NAMES_REQUEST,
  GET_BRAND_NAMES_FAILURE,
  GET_BRAND_NAMES_SUCCESS
} from './admin-helper-constants';

const initialState = {
  isLoading: false,
  brandNamesList: []
};

const getBrandNames = (state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_NAMES_REQUEST:
      return {
        ...state,
        isLoading: true,
        brandNamesList: []
      };
    case GET_BRAND_NAMES_FAILURE:
      return {
        ...state,
        isLoading: false,
        brandNamesList: []
      };
    case GET_BRAND_NAMES_SUCCESS:
      const { data } = action.data;
      return {
        ...state,
        isLoading: false,
        brandNamesList: data
      };
    default:
      return state;
  }
};

export default getBrandNames;
