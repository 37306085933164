import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import User from '../../utils/user';

const Header = () => {
  // Home Page
  // if (window.location.pathname === '/showreal') {
  //   var headText = 'Home Page';
  // }
  // if (window.location.pathname === '/portfolio') {
  //   var headText = 'Home Page';
  // }

  // if (window.location.pathname === '/showreallist') {
  //   var headText = 'Home Page';
  // }
  // if (window.location.pathname === '/portfoliolist') {
  //   var headText = 'Home Page';
  // }
  // Content page
  // if (window.location.pathname === '/cshowreal') {
  //   var headText = 'Content Page';
  // }
  // if (window.location.pathname === '/cportfolio') {
  //   var headText = 'Content Page';
  // }
  // if (window.location.pathname === '/cshowreallist') {
  //   var headText = 'Content Page';
  // }
  // if (window.location.pathname === '/cportfoliolist') {
  //   var headText = 'Content Page';
  // }
  // // Production Page
  // if (window.location.pathname === '/pportfolio') {
  //   var headText = 'Production Page';
  // }
  // if (window.location.pathname === '/pportfoliolist') {
  //   var headText = 'Production Page';
  // }

  return (
    <div className="header">
      <Navbar expand="lg">
        <div className="header-section">
          <div className="header-text">{/* <h1>{headText}</h1> */}</div>
          <div className="header-profile">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Link
                to="/"
                className={`header-nav ${
                  window.location.pathname === '/' && 'active'
                }`}
              >
                Dashboard
              </Link>
              <Button className="black-btn" onClick={new User().forceLogout}>
                Logout
              </Button>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
