import * as Toastr from 'toastr';

import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../../utils/http-helper';
import { showReelUrl } from '../../../../utils/urls';
import { ADD_SHOW_REEL_REQUEST } from '../showreel-constants';
import {
  addShowReelSuccess,
  addShowReelFailure
} from './create-showreel-action';

const { postRequest } = new HttpHelper();

export function* addShowReel({ data }) {
  try {
    const response = yield call(postRequest, {
      url: showReelUrl(),
      data: data.formData
    });

    if (response.error) {
      yield put(addShowReelFailure());
    } else if (response.data && response.data.status) {
      yield put(addShowReelSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.showReelSuccessCallback();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchAddShowReel() {
  yield takeLatest(ADD_SHOW_REEL_REQUEST, addShowReel);
}
