import { call, put, takeLatest } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../../utils/http-helper';
import { directorSortUrl, directorUrl } from '../../../utils/urls';
import { GET_DIRECTOR_REQUEST, SORT_DIRECTOR_REQUEST } from '../director-constants';
import { getDirectorSuccess, getDirectorFailure, sortDirectorFailure, sortDirectorSuccess } from './director-action';

const { getRequest, postRequest } = new HttpHelper();

export function* getDirector({ data }) {
  try {
    const response = yield call(getRequest, {
      url: directorUrl(data)
    });

    if (response.error) {
      yield put(getDirectorFailure());
    } else {
      yield put(getDirectorSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* sortDirector({ data }) {
  try {
    const response = yield call(postRequest, {
      url: directorSortUrl(),
      data: data.formData
    });

    if (response.error) {
      yield put(sortDirectorFailure());
    } else if (response.data && response.data.status) {
      yield put(sortDirectorSuccess());
      Toastr.success(response.data.message, 'Success Response');
      data.updateSuccess();
    } else {
      Toastr.error(response.data.message, 'Failure Response');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetDirector() {
  yield takeLatest(GET_DIRECTOR_REQUEST, getDirector);
}

export function* watchSortDirector() {
  yield takeLatest(SORT_DIRECTOR_REQUEST, sortDirector);
}
