import {
  ADD_SHOW_REEL_REQUEST,
  ADD_SHOW_REEL_FAILURE,
  ADD_SHOW_REEL_SUCCESS
} from '../showreel-constants';

const addShowReelRequest = (data) => ({
  data,
  type: ADD_SHOW_REEL_REQUEST
});

export const addShowReelSuccess = () => ({
  type: ADD_SHOW_REEL_SUCCESS
});

export const addShowReelFailure = () => ({
  type: ADD_SHOW_REEL_FAILURE
});

export const addShowReel = async (data, dispatch) => {
  dispatch(addShowReelRequest(data));
};
