import {
  UPDATE_PORTFOLIO_REQUEST,
  UPDATE_PORTFOLIO_FAILURE,
  UPDATE_PORTFOLIO_SUCCESS
} from '../portfolio-constants';

const initialState = {
  isLoading: false
};

const updatePortfolio = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PORTFOLIO_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case UPDATE_PORTFOLIO_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case UPDATE_PORTFOLIO_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default updatePortfolio;
