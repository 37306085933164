import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { BRAND_NAMES_URL } from '../../utils/urls';
import { GET_BRAND_NAMES_REQUEST } from './admin-helper-constants';
import {
  getBrandNamesSuccess,
  getBrandNamesFailure
} from './brand-names-action';

const { getRequest } = new HttpHelper();

export function* getBrandNames() {
  try {
    const response = yield call(getRequest, {
      url: BRAND_NAMES_URL
    });

    if (response.error) {
      yield put(getBrandNamesFailure());
    } else {
      yield put(getBrandNamesSuccess(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetBrandNames() {
  yield takeLatest(GET_BRAND_NAMES_REQUEST, getBrandNames);
}
