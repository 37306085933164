import React, { Fragment } from 'react';
import { Form, Button } from 'react-bootstrap';

import TextInput from '../../../components/form-fields/text-input';
import ButtonLoader from '../../../assets/images/button-loader.svg';
import arrow from '../../../assets/images/arrow.png';

const PressForm = ({
  pressDetails,
  setPressDetails,
  validated,
  handleCancel,
  handleSubmitPress,
  isLoading,
  noData,
  isEditpressLoading
}) => {
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSubmitPress(e);
    }
  };

  const formFileds = [
    {
      type: 'text',
      label: 'Title',
      placeholder: 'Enter press title',
      value: pressDetails.title,
      changeText: (e) =>
        setPressDetails({
          ...pressDetails,
          title: e.target.value
        }),
      feedback: 'Please enter press title.'
    },
    {
      type: 'text',
      label: 'Media Name',
      placeholder: 'Enter media name',
      value: pressDetails.mediaName,
      changeText: (e) =>
        setPressDetails({
          ...pressDetails,
          mediaName: e.target.value
        }),
      feedback: 'Please enter media name.'
    },
    {
      type: 'text',
      label: 'Press Link',
      placeholder: 'Enter press link',
      value: pressDetails.link,
      changeText: (e) =>
        setPressDetails({
          ...pressDetails,
          link: e.target.value
        }),
      feedback: 'Please enter press link.'
    }
  ];

  const renderFormFields = (input) => {
    switch (input.type) {
      case 'text':
        return (
          <TextInput
            label={input.label}
            placeholder={input.placeholder}
            value={input.value}
            changeText={input.changeText}
            feedback={input.feedback}
          />
        );

      default:
        return '';
    }
  };

  return !isEditpressLoading ? (
    <div className="common-container">
      <div className="form-container">
        <div className="back-arrow" onClick={handleCancel}>
          <img src={arrow} alt="arrow" />
        </div>
        <div className="head">
          <h4>Press Form </h4>
          <p>Enter your details below</p>
        </div>
        {!noData ? (
          <>
            <Form onKeyDown={handleKeyDown} noValidate validated={validated}>
              {formFileds.map((input, index) => (
                <Fragment key={index}>{renderFormFields(input)}</Fragment>
              ))}
            </Form>
            <div className="bottom-btn">
              <Button className="black-btn white width" onClick={handleCancel}>
                Cancel
              </Button>
              <Button className="black-btn width" onClick={handleSubmitPress}>
                {!isLoading ? (
                  'Submit'
                ) : (
                  <>
                    <img src={ButtonLoader} alt="button-loader" />
                    <span className="laoder-span">Loading....</span>
                  </>
                )}
              </Button>
            </div>
          </>
        ) : (
          <Form>No data found</Form>
        )}
      </div>
    </div>
  ) : (
    <p style={{ color: '#000' }}>Loading...</p>
  );
};

export default PressForm;
