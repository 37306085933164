import {
  GET_BRAND_NAMES_REQUEST,
  GET_BRAND_NAMES_FAILURE,
  GET_BRAND_NAMES_SUCCESS
} from './admin-helper-constants';

const getBrandNamesRequest = () => ({
  type: GET_BRAND_NAMES_REQUEST
});

export const getBrandNamesSuccess = (data) => ({
  data,
  type: GET_BRAND_NAMES_SUCCESS
});

export const getBrandNamesFailure = () => ({
  type: GET_BRAND_NAMES_FAILURE
});

export const getBrandNames = async (dispatch) => {
  dispatch(getBrandNamesRequest());
};
