import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSortable } from "react-sortablejs";

import { Button } from 'react-bootstrap';

import { getDirector, sortDirector } from './director-action';
import { deleteDirector } from '../delete/delete-director-action';

import Layout from '../../../components/layout';
import DeletePopup from '../../../components/modal/delete-popup';

import plus from '../../../assets/images/plus.png';

const Director = ({ history }) => {
  const [directorId, setDirectorId] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [currentPageNo] = useState(1);
  const [pageSize] = useState(10000);

  const dispatch = useDispatch();

  const getDirectorDetails = (currentPageNo, pageSize) => {
    getDirector({ pageNumber: currentPageNo, pageSize: pageSize }, dispatch);
  };

  useEffect(() => {
    getDirectorDetails(currentPageNo, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { directorList, isLoading } = useSelector(
    (state) => state.getDirector
  );

  const deleteDirectorSuccess = () => {
    setShowDeletePopup(false);
    getDirectorDetails(currentPageNo, pageSize);
  };

  const handelDelete = () => {
    deleteDirector(
      { id: directorId, deleteSuccess: deleteDirectorSuccess },
      dispatch
    );
  };

  const updateSuccess = () => { };

  const getOrder = (list) => {
    const ids = [];
    for (let i = 0; i < list.length; i++) {
      const elem = list[i];
      ids.push(elem.id);
    }
    return ids;
  }

  const updateDirectorList = (list) => {
    let data = new FormData();
    data.append('order', getOrder(list));

    sortDirector({
      data: list,
      formData: data,
      updateSuccess: updateSuccess
    }, dispatch);
  }

  return (
    <Layout>
      <div className="common-wrapper">
        <div className="common-container">
          {!isLoading ? (
            <div className="video-list-container list-container">
              <div className="video-list-head">
                <div>
                  <h2>Directors</h2>
                </div>
                <div>
                  <Button
                    className="black-btn width"
                    onClick={() => history.push('/director/create')}
                  >
                    Add Director
                    <span>
                      <img src={plus} alt="plus" />
                    </span>
                  </Button>
                </div>
              </div>
              <div className="director-list-section">
                <ReactSortable list={directorList} setList={(sortedList) => updateDirectorList(sortedList)}>
                {directorList &&
                  directorList.map((director) => (
                    <div className="director-list" key={director.id}>
                      <p>{director.name}</p>
                      <Button
                        className="black-btn transparent halfwidth"
                        onClick={() =>
                          history.push(`/director/${director.id}/list`)
                        }
                      >
                        View Detail
                      </Button>
                      <span>|</span>
                      <Button
                        className="black-btn transparent"
                        onClick={() => {
                          history.push(`/director/${director.id}/edit`);
                        }}
                      >
                        Edit
                      </Button>
                      <span>|</span>
                      <Button
                        className="black-btn transparent red"
                        onClick={() => {
                          setShowDeletePopup(true);
                          setDirectorId(director.id);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  ))}
                </ReactSortable>
              </div>
            </div>
          ) : (
            <p style={{ color: '#000' }}>Loading...</p>
          )}
        </div>
      </div>
      <DeletePopup
        show={showDeletePopup}
        handleClose={() => setShowDeletePopup(false)}
        handelDelete={handelDelete}
      />
    </Layout>
  );
};

export default Director;
