import { call, put, takeLatest } from 'redux-saga/effects';

import HttpHelper from '../../../utils/http-helper';
import { videoUrlByVideoId } from '../../../utils/urls';
import { GET_DIRECTOR_VIDEO_BY_ID_REQUEST } from '../director-constants';
import {
  getDirectorVideoByIdSuccess,
  getDirectorVideoByIdFailure
} from './get-director-video-action';

const { getRequest } = new HttpHelper();

export function* getDirectorVideoById({ data }) {
  try {
    const response = yield call(getRequest, {
      url: videoUrlByVideoId(data.id)
    });

    if (response.error) {
      yield put(getDirectorVideoByIdFailure());
    } else {
      yield put(getDirectorVideoByIdSuccess(response.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchGetDirectorVideoById() {
  yield takeLatest(GET_DIRECTOR_VIDEO_BY_ID_REQUEST, getDirectorVideoById);
}
